import React, { useState } from "react";
// import GameBLibCarousel from "../../components/GameBoardCard/GameBLibCarousel";
// import { UseCompany } from "../../hooks/company";
// import Gameboards from "./Gameboards";
// import GameboardCollections from "./GameboardCollections";
import GameboardPageContainer from "./GameboardPageContainer";
import GameBoardCardV2 from "./GameBoardCardV2";
import SoftBox from "../../components/SoftBox";
import PageHeader from "../../components/PageHeader";

const commonImgStyles = { objectPosition: "unset", objectFit: "unset" };

const GAMEBOARD_LIBRARY_ITEMS = [
  {
    title: "King of the Mountain",
    description: "Explore the bustling plaza of aspirations, where dreams come to life through focused action and determination",
    imageURL: "https://gmb-public.s3.us-east-2.amazonaws.com/arena/gameboard1.jpg",
    imageBlurURL: "https://gmb-public.s3.us-east-2.amazonaws.com/arena/gameboard1_small.jpg",
    imageScale: { "& img": { maxWidth: "105%", height: "200%", marginLeft: "-3%", marginTop: "-42%", width: "105%", ...commonImgStyles } }
  },
  {
    title: "Challenge Summit",
    description: "Scale the summit of challenges and conquer your fears. Every obstacle overcome is a victory earned, propelling you closer to your ultimate goal.",
    imageURL: "https://gmb-public.s3.us-east-2.amazonaws.com/arena/gameboard2.jpg",
    imageBlurURL: "https://gmb-public.s3.us-east-2.amazonaws.com/arena/gameboard2_small.jpg",
    imageScale: { "& img": { maxWidth: "200%", height: "220%", marginLeft: "-50%", marginTop: "-46%", width: "200%", ...commonImgStyles } }
  },
  {
    title: "Victory Valley",
    description: "Explore the lush valley of victories, where triumphs abound and challenges are met with courage and resilience",
    imageURL: "https://gmb-public.s3.us-east-2.amazonaws.com/arena/gameboard3.jpg",
    imageBlurURL: "https://gmb-public.s3.us-east-2.amazonaws.com/arena/gameboard3_small.jpg",
    imageScale: { "& img": { width: "100%", maxHeight: "148%", marginTop: "-11%", height: "unset", ...commonImgStyles } }
  }
]

const GameboardLibrary = () => {
  // Tabs
  /*
  const [tab, setTab] = useState(1);
  const handleChange = (event, newValue) => {
    setTab(newValue);
  }; */

  return (
    <>
      <PageHeader
        headerText="Gameboard Selection"
        subheaderText="Choose where you want to play the game."
        className="mb-8"
      />
      <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
        <GameboardPageContainer
          title="Choose your Gameboard"
          caption="Select your gameboard below to embark on a journey of growth and success. Each Gameboard offers a unique experience to inspire and motivate you on your path to greatness."
        >
          <SoftBox
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              maxWidth: "1600px",
              gap: "24px",
              margin: "auto"
            }}
          >
            {(GAMEBOARD_LIBRARY_ITEMS.map((card, index) => (
              <GameBoardCardV2
                title={card.title}
                buttonLabel={index === 0 ? "Selected" : "Locked"}
                description={card.description}
                footerLabel={`Gameboard ${index + 1}`}
                isSelected={index === 0}
                isLocked={index > 0}
                cardImageURL={card.imageURL}
                cardImageBlurURL={card.imageBlurURL}
                imageStyles={card.imageScale}
              />
            )))}
          </SoftBox>
        </GameboardPageContainer>
      </SoftBox>
    </>
  );
};

export default GameboardLibrary;
