/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import { Menu, MenuItem as ReactMenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";

// custom styles for the NotificationItem
import { menuItem, menuItemWithBackground, menuImage } from "./styles";
import SoftButton from "../SoftButton";

const NotificationItem = forwardRef(
  (
    {
      color,
      image,
      title,
      date,
      read = false,
      allNoti = false,
      notiId,
      handleRead,
      handleDelete,
      onClick,
      ...rest
    },
    ref,
  ) => (
    <MenuItem
      {...rest}
      ref={ref}
      sx={(theme) => (read ? menuItem(theme) : menuItemWithBackground(theme))}
    >
      <SoftBox mt={0.25} mr={2} mb={0.25}>
        {image}
      </SoftBox>
      <SoftBox
        onClick={onClick}
        display="flex"
        flexDirection="column"
        sx={{ width: "-webkit-fill-available" }}
      >
        <SoftTypography
          variant="button"
          textTransform="capitalize"
          fontWeight="regular"
          sx={{ whiteSpace: "pre-line" }}
        >
          {title[0]} <strong>{title[1]}</strong>
        </SoftTypography>
        <SoftTypography
          variant="caption"
          color="secondary"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <SoftTypography variant="button" color="secondary">
            <WatchLaterIcon fontSize="10" sx={{ mr: 0.5 }} />
          </SoftTypography>
          {date}
        </SoftTypography>
        {image && <SoftButton variant="button" sx={{
          background: "linear-gradient(310deg, #2152ff, #21d4fd)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent"
        }}>Approve</SoftButton>}
      </SoftBox>
      {allNoti ? (
        <SoftBox>
          <Menu
            menuButton={
              <IconButton id="basic-button">
                <MoreVertIcon style={{ color: "#1976D2" }} />
              </IconButton>
            }
            transition
          >
            <ReactMenuItem
              onClick={() => {
                handleRead(notiId);
              }}
            >
              <CheckIcon fontSize="25px" style={{ marginRight: 10 }} /> Mark as
              read
            </ReactMenuItem>
            <ReactMenuItem
              onClick={() => {
                handleDelete(notiId);
              }}
            >
              <CloseIcon fontSize="25px" style={{ marginRight: 10 }} /> Delete
              this notification
            </ReactMenuItem>
          </Menu>
        </SoftBox>
      ) : null}
    </MenuItem>
  ),
);

// Setting default values for the props of NotificationItem
NotificationItem.defaultProps = {
  color: "dark",
};

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
};

export default NotificationItem;
