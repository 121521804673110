import React from "react";

import Leaders from "./Leaders";
import ProgressBar from "./ProgressBar";
import SoftBox from "../../SoftBox";
import SoftButton from "../../SoftButton";
import { lineHeight, minWidth } from "@mui/system";

const LiveLeaderboardComponent = ({
  topList = [],
  groupKpis = null,
  allKpis = null,
  isAdmin = false,
  isViewOnly = false,
  isPointsPercent = false,
  periodIdx = 0,
}) => {
  const [activeIndex, setActiveIndex] = React.useState(-1);
  const [displayMode, setDisplayMode] = React.useState('initial'); // 'initial', 'more', 'all'

  const handleToggleDisplay = () => {
    setDisplayMode(prevMode => {
      if (prevMode === 'initial') return 'more';
      if (prevMode === 'more') return 'all';
      return 'initial';
    });
  };

  const getDisplayedList = () => {
    switch (displayMode) {
      case 'initial': return topList.slice(0, 5);
      case 'more': return topList.slice(0, 10);
      case 'all': return topList;
      default: return topList.slice(0, 5);
    }
  };

  const displayedList = getDisplayedList();

  const getButtonText = () => {
    if (displayMode === 'all') return 'Hide';
    return 'Show More';
  };

  return (
    <SoftBox sx={{ marginTop: { mini: "1rem", lg: "2rem" } }}>
      <SoftBox
        sx={{
          display: "grid",
          gridTemplateAreas: {
            mini: `"gameboard"
                  "leaderboard"`,
            lg: `"leaderboard gameboard"`,
          },
          gridTemplateColumns: { lg: "1fr 1fr" },
          gap: { mini: "1rem", md: "1.25rem" },
        }}
      >
        <SoftBox
          sx={{
            gridArea: `leaderboard`,
          }}
        >
          {topList && topList.length
            ? displayedList.map((user, index) => (
                <ProgressBar
                  key={user.userId || user.firstName}
                  userId={user.userId}
                  userFirstName={user.firstName}
                  userLastName={user.lastName}
                  // Use given workspace KPIs or a sub-set from all company KPIs
                  groupKpis={groupKpis ?? allKpis[user.userId]}
                  userKpis={user.scores}
                  isOwnId={user.ownId}
                  imgsrc={user.image}
                  top={topList[0].scores.total}
                  email={user.email}
                  position={index + 1}
                  prevPosition={user.yesterdayRank}
                  showRankMove={periodIdx >= 1}
                  isActive={index === activeIndex}
                  onSelect={(show) => setActiveIndex(show ? index : -1)}
                  isYeti={user.isYeti}
                  isViewOnly={isViewOnly}
                  isPointsPercent={isPointsPercent}
                />
              ))
            : null}
          {topList.length > 5 && (
            <SoftBox sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <SoftButton 
                color="primary" 
                sx={{ borderRadius: "8px", fontSize: "12px", lineHeight: "12px", textTransform: "capitalize", marginTop: "2rem", width: "10rem" }}
                onClick={handleToggleDisplay}
              >
                {getButtonText()}
              </SoftButton>
            </SoftBox>
          )}
        </SoftBox>
        <SoftBox sx={{ gridArea: `gameboard` }}>
          {topList && topList.length ? (
            <Leaders
              top3={topList.slice(0, 3)}
              groupKpis={groupKpis}
              showRankMove={periodIdx >= 1}
            />
          ) : null}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

export default LiveLeaderboardComponent;
