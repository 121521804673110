import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core";
import { toast } from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";

import SoftBox from "../../../../components/SoftBox";
import SoftButton from "../../../../components/SoftButton";
import SoftInput from "../../../../components/SoftInput";
import SoftTypography from "../../../../components/SoftTypography";
import { QuoteWidgetSchema } from "../../../../form-validations";
import { UseCompanyId, UseUserId } from "../../../../hooks/auth";
import { ADD_WIDGETS_TO_LIST } from "../../../../api/userWidgets";
import { refetchUserData } from "../../../../redux/auth/actions";
import { FETCH_QUOTES } from "../../../../api/quotes";

const QuoteOfTheDay = ({ widget, closeModal, setButtonLabel, setReFetch }) => {
  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState([]);
  const [currentQuote, setCurrentQuote] = useState({ q: '', a: '' });

  const theme = useTheme();
  const userId = UseUserId();
  const companyId = UseCompanyId();
  const dispatch = useDispatch();

  const [quoteList, setQuoteList] = useState([]);

  const getQuotes = async () => {
    try {
      const response = await FETCH_QUOTES();
      setQuoteList(response.data);
      if (response.data.length > 0) {
        setCurrentQuote(response.data[0]);
      }
    } catch (err) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    if (quoteList.length === 0) {
      getQuotes();
    }
  }, [quoteList]);

  const {
    primary: { main: primaryColor },
  } = theme.palette;

  const validationOpt = {
    resolver: yupResolver(QuoteWidgetSchema),
    shouldUnregister: false,
    defaultValues: {
      quote: "",
      author: "",
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(validationOpt);

  const handleRemoveClick = (index) => {
    const list = [...quotes];
    list.splice(index, 1);
    setQuotes(list);
  };

  const addTrigger = async (formData) => {
    const { quote, author } = formData;
    const newQuote = {
      quote,
      author,
    };

    setQuotes([...quotes, newQuote]);
    reset();
  };

  const triggerSave = async () => {
    // if (!quotes.length) {
    //   toast.error("Quotes are required");
    //   return;
    // }

    // if (quotes.length < 7) { 
    //   toast.error("Add quotes for 7 days");
    //   return;
    // }

    // const multilineUserInput = quotes.map(
    //   ({ quote, author }) => `${quote} - ${author}`,
    // );

    setLoading(true);
    const payload = {
      userId,
      key: widget.key,
      category: widget.category,
      title: widget.title,
      // multilineUserInput,
      companyId,
    };

    try {
      await ADD_WIDGETS_TO_LIST(payload);
      dispatch(refetchUserData(userId));
      setTimeout(() => {
        setButtonLabel("Deactivate");
        toast.success("Widget activated successfully");
        closeModal();
        setReFetch();
      }, 1000);
    } catch (error) {
      console.log(error.response);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(addTrigger)}>
      <SoftBox
        marginY="0.5rem"
        sx={{
          borderRadius: "4px",
          border: "1px solid rgba(73, 80, 87, 0.50)",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.07)",
          padding: "1rem",
          marginBottom: "10px",
        }}
      >
        <SoftBox sx={{ width: "100%" }}>
          <SoftTypography variant="t1" color="white" sx={{marginBottom: "2rem"}}>Today's Quotes</SoftTypography>
          <SoftInput
            {...register("quote")}
            readOnly
            placeholder="Add a quote"
            value={currentQuote.q}
            onChange={(e) => setCurrentQuote({ ...currentQuote, q: e.target.value })}
            error={!!errors?.quote}
            helperText={errors?.quote?.message}
            mb="30px"
            className="text-black mb-3"
            sx={{
              border: "1px solid rgba(233, 236, 239, 0.10)",
              backgroundColor: "rgba(255, 255, 255, 0.10) !important",
              color: "#ffffffb3 !important",
              "& .MuiInputBase-input::placeholder": {
                color: "white !important",
                opacity: "0.7",
              },
              "&.Mui-focused": {
                borderColor: primaryColor,
                boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
              },
            }}
          />
          <SoftInput
            {...register("author")}
            readOnly
            placeholder="Author"
            value={currentQuote.a}
            onChange={(e) => setCurrentQuote({ ...currentQuote, a: e.target.value })}
            error={!!errors?.author}
            helperText={errors?.author?.message}
            mb="30px"
            className="text-black mb-3"
            sx={{
              border: "1px solid rgba(233, 236, 239, 0.10)",
              backgroundColor: "rgba(255, 255, 255, 0.10) !important",
              color: "#ffffffb3 !important",
              "& .MuiInputBase-input::placeholder": {
                color: "white !important",
                opacity: "0.7",
              },
              "&.Mui-focused": {
                borderColor: primaryColor,
                boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
              },
            }}
          />
        </SoftBox>
        <SoftBox display="none" justifyContent="flex-end" alignItems="center">
          <SoftButton
            variant="outlined"
            color="white"
            type="submit"
            sx={{
              textTransform: "none",
              padding: "0px 30px",
              border: "none",
              borderRadius: "4px",
            }}
          >
            Add a quote
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        sx={{ marginTop: "2rem" }}
      >
        <SoftButton
          onClick={closeModal}
          disabled={loading}
          loading={loading}
          sx={{
            width: "100%",
            borderRadius: "4px",
            border: "1px solid rgba(233, 236, 239, 0.20)",
            boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
            textTransform: "none",
            background: "rgba(255, 255, 255, 0.10)",
            color: "#CED4DA",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.10)",
            },
          }}
        >
          Cancel
        </SoftButton>

        <SoftButton
          color="primary"
          sx={{
            width: "100%",
            textTransform: "none",
            borderRadius: "4px",
            color: "#E9ECEF",
          }}
          disabled={loading}
          loading={loading}
          onClick={triggerSave}
        >
          Activate
        </SoftButton>
      </SoftBox>
    </form>
  );
};

export default QuoteOfTheDay;
