import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import { UseUserDetails } from "../../hooks/auth";
import { UseGroupKpis } from "../../hooks/kpi";
import { UseSelectedWorkspace } from "../../hooks/company";
import { FETCH_GROUP_TYPE } from "../../api/group";
import {
  GET_APP_INTEGRATIONS,
  GET_KPI_INTEGRATIONS_BY_DATASET,
  GET_COMPANY_INTEGRATIONS,
  SUBMIT_REQUEST
} from "../../api/integration";
import {
  GET_ZAPS,
  GET_USER as GET_ZAPIER_USER,
} from "../../api/integrations/zapier";

import {
  updateWorkspaceKpi,
  disableWorkspaceKpi,
} from "../../redux/kpi/actions";

import { Modal, Backdrop, Fade, Box, Grid } from '@mui/material';
import SoftButton from "../SoftButton";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import { ReactComponent as NewspaperSvg } from "../../img/workspace-newspaper.svg";

import Kpi from "./Kpi";
import CreateCustomKpi from "./metrics/CreateCustomKpi";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import ConnectedApplications from "./ConnectedApplications";

import AllocationDialog from "./setup/AllocationDialog";
import SetupDisplayDialog from "./setup/SetupDisplayDialog";
import SetupSelectDialog from "./setup/SetupSelectDialog";

import SoftInput from "../SoftInput";
import SoftSelect from "../SoftSelect";
import { useForm } from "react-hook-form";

import { GMB_LOGO_URL } from "../../constants/branding";
import { useNavigate } from "react-router-dom";
import { fontSize, fontWeight, textAlign } from "@mui/system";

const DIALOG_TYPES = {
  ALLOCATION: "allocation",
  DISPLAY_METRIC: "display_metric",
  SETUP: "setup",
  NONE: null
};

const WorkspaceKpi = () => {
  const [kpis, setKpis] = useState([]);
  const [onKpis, setOnKpis] = useState([]);
  const [allKpis, setAllKpis] = useState([]);
  const groupKpis = UseGroupKpis();
  const workspace = UseSelectedWorkspace();
  const { data: workspaceData } = FETCH_GROUP_TYPE(workspace.typeId);
  const [allApps, setAllApps] = useState([]);
  const [integrationsObj, setIntegrationsObj] = useState(null);
  const [currentAppIntegrations, setCurrentAppIntegrations] = useState({});

  const [newKpi, setNewKpi] = useState(undefined);
  const [currentGroupKpis, setCurrentGroupKpis] = useState(null);
  const [activeDialog, setActiveDialog] = useState(DIALOG_TYPES.NONE);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [connectedApps, setConnecteDApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openCustomModal, setOpenCustomModal] = useState(false);

  const formRef = useRef(null);
  const { register, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      image: "",
      name: "",
    },
  });

  const [openModal, setOpenModal] = useState(false);
  const [openMaxMetric, setMaxMetric] = useState(false);
  const [confirmModal, setconfirmModal] = useState(false);

  const handleCloseModal2 = () => {
    setconfirmModal(false);
  };

  const handleCloseMaxMetric = () => {
    setMaxMetric(false);
  }

  const handleMaxMetric = () => {
    setMaxMetric(true);
  };

  const handleImageClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const { _id: userId } = UseUserDetails();
  const submitRequest = async () => {
    const titleValue = getValues("title");
    const integrationValue = getValues("integration");
    const detailsValue = getValues("details");
    if (formRef.current) {
      const body = {
        userId,
        title: titleValue,
        integration: integrationValue,
        details: detailsValue,
      };
      console.log(body);
      await SUBMIT_REQUEST(body);
    }
    setOpenModal(false);
    reset();
    setconfirmModal(true);
  };

  const MAX_WORKSPACE_KPI = 5;

  const addZapierKpis = async (appsData) => {
    try {
      setIsLoading(true);
      const [zapTemplatesResponse, zapUserResponse] = await Promise.all([
        GET_ZAPS(),
        GET_ZAPIER_USER(),
      ]);

      const zapTemplates = zapTemplatesResponse.data;
      const zapUser = zapUserResponse.data;

      zapTemplates.forEach((z) => {
        const idx = appsData.findIndex((app) => {
          return app.name === z.name;
        });
        z.isConnected = !!zapUser;

        const zaps = z.zaps.map((zap) => ({
          ...zap,
          id: z.serviceId,
          // Assign Zapier integration Id for Zapier KPIs that are merged together with other integrated KPIs
          integrationId: z._id,
          // Remove original Game My Biz text from zap since it seems redundant here
          description: zap.description.replace("Game My Biz", ""),
          isZapier: true,
        }));

        if (idx >= 0) {
          appsData[idx].zaps = zaps;
        } else {
          appsData.push({
            ...z,
            zaps,
          });
        }
      });

      setTimeout(() => setIsLoading(false), 500);
      return appsData;
    } catch (err) {
      console.log("zap templates err", err);
      return appsData;
    }
  };

  useEffect(() => {
    if (workspaceData) {
      const getIntegrationsData = async () => {
        const [appsResponse, integResponse, companyIntegrationsResponse] =
          await Promise.all([
            GET_APP_INTEGRATIONS(),
            GET_KPI_INTEGRATIONS_BY_DATASET(),
            GET_COMPANY_INTEGRATIONS(),
          ]);

        let appsData = appsResponse.data;
        const integData = integResponse.data;
        const companyIntegrations = companyIntegrationsResponse.data;

        for (let j = 0; j < appsData.length; j += 1) {
          const { code, serviceId } = appsData[j];
          const idx = companyIntegrations.findIndex((app) => {
            return serviceId ? app.serviceId === serviceId : app.code === code;
          });
          if (idx >= 0) {
            appsData[j].isConnected = true;
            appsData[j].paramKeys = { ...companyIntegrations[idx].parameters };
          } else {
            appsData[j].isConnected = false;
          }
        }
        appsData = await addZapierKpis(appsData);

        appsData.push({
          // Add Manual KPIs
          name: "Game My Biz",
          code: "manual",
          logo: GMB_LOGO_URL,
          description: "Custom-built metrics for your business",
          isConnected: true,
          isManual: true,
          models: workspaceData?.data.map((model) => {
            const { _id, ...rest } = model;
            return {
              kpiModelId: _id,
              ...rest,
            };
          }),
        });

        const appsConnected = appsData.filter(
          (item) => item.isConnected === true,
        );

        // Sort apps: connected ones first then sort alphabetically
        const sortedApps = appsData.sort((app1, app2) => {
          // First, sort by isConnected in descending order (true comes first)
          if (app1.isConnected && !app2.isConnected) {
            return -1;
          }
          if (!app1.isConnected && app2.isConnected) {
            return 1;
          }

          // If isConnected values are equal, sort by isZapier in ascending order (false comes first)
          if (!app1.isZapier && app2.isZapier) {
            return -1;
          }
          if (app1.isZapier && !app2.isZapier) {
            return 1;
          }

          // Next, sort by isManual in ascending order (false comes first)
          if (!app1.isManual && app2.isManual) {
            return -1;
          }
          if (app1.isManual && !app2.isManual) {
            return 1;
          }

          // If isConnected and isZapier values are equal, then sort by name in ascending order
          return app1.name.localeCompare(app2.name);
        });

        setConnecteDApps(appsConnected);
        setAllApps(sortedApps);
        setIntegrationsObj(integData);
        setIsLoading(false);
      };

      getIntegrationsData();
    }
  }, [workspaceData]);

  useEffect(() => {
    if (groupKpis) {
      const appIntegrations = {};
      groupKpis
        .filter((kpi) => kpi.integrationId)
        .forEach((kpi) => {
          (appIntegrations[kpi.integrationId] ??= []).push(kpi.sourceId);
        });

      setCurrentAppIntegrations(appIntegrations);

      // Find the newly-added Integrated GroupKpi
      setCurrentGroupKpis(prev => {
        const groupKpiIds = groupKpis.map(kpi => kpi._id);

        if (Array.isArray(prev)) {
          const newGroupKpiId = groupKpiIds.filter(id => !prev.includes(id))?.[0];
          const newGroupKpi = groupKpis.find(kpi => kpi._id === newGroupKpiId);

          // Full newly-saved "display" GroupKpi data from server
          if (newGroupKpi?._id && groupKpis.length > 0) {
            setNewKpi(prevKpi => {
              if (prevKpi?.isNew) {
                setActiveDialog(DIALOG_TYPES.ALLOCATION);
              }
              return { ...prevKpi, ...newGroupKpi }
             });
          }
        }

        return groupKpiIds;
      });
    }
  }, [groupKpis]);

  useEffect(() => {
    // Assign current workspace KPIs (groupKpis) as "active" KPIs; data is fetched on first load and workspace change
    if (kpis.length) {
      const ons = [];
      const all = [];
      kpis.forEach((kpi) => {
        let active = false;
        const idx = groupKpis?.findIndex((k) => k.kpiModelId === kpi._id);
        if (idx >= 0) {
          active = true;
          ons.push({ ...kpi, ...groupKpis[idx] });
          kpi = { ...kpi, ...groupKpis[idx] };
        }
        all.push({
          ...kpi,
          target: kpi.defaultTarget,
          kpiModelId: kpi._id,
          active,
        });
      });
      setOnKpis(ons);
      setAllKpis(all);
    }
  }, [kpis, groupKpis]);

  const handleSwitch = (kpiId, activating = true) => {
    const idx = allKpis.findIndex((k) => k._id === kpiId);
    if (idx >= 0) {
      if (activating) {
        dispatch(
          updateWorkspaceKpi({ ...allKpis[idx], groupId: workspace._id }),
        );
        setOnKpis([...onKpis, allKpis[idx]]); // Immediately update KPI cards ...
      } else {
        dispatch(disableWorkspaceKpi(kpiId));
        const updatedKpis = [...onKpis]; // Immediately update KPI cards
        updatedKpis.splice(idx, 1); // though this is not too necessary, because the KPI cards will auto-update a bit later
        setOnKpis(updatedKpis); // once redux KPI states updates
      }
    }
  };

  const clearSetupDialogs = (modalDialog) => {
    if (newKpi?.isZapier && newKpi?.integrationMeta) {
      navigate(`/zapier-setup?id=${newKpi?.integrationMeta.id}`);
    } else {
      setActiveDialog(modalDialog ?? DIALOG_TYPES.NONE);
      setNewKpi(null);
    }
  }

  const styleModal = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
    borderRadius: '10px',
    boxShadow: 24,
    padding: '1.75rem',
    maxWidth: { mini: "90%", xs: "90%", sm: "600px" },
    width: "100%",
    overflow: "visible",
    backdropFilter: 'blur(25px)'
  };

  return (
    <>
      <SoftBox
        sx={{ marginTop: "1rem", marginBottom: "1rem", padding: 4 }}
        borderRadius="lg"
        shadow="md"
        bgColor="white"
      >
        <Box className="flex justify-between mb-6">
          <Box className="flex flex-col px-2">
            <SoftTypography
              variant="h4"
              className="!text-[#051923] !text-[20px]"
            >
              Active Key performance Indicators in Your Workspace

              {/** Instant Metric Allocation Dialog */}
              {(activeDialog === DIALOG_TYPES.ALLOCATION) &&
                <AllocationDialog
                  isOpen
                  handleCancel={() => clearSetupDialogs(DIALOG_TYPES.DISPLAY_METRIC)}
                  handleConfirm={() => setActiveDialog(DIALOG_TYPES.SETUP)}
                />
              }
            </SoftTypography>
            <SoftTypography variant="body2" className="!text-[#6C757D]">
              Your active KPIs, offering real-time insights into your team's
              performance.
            </SoftTypography>
          </Box>
          <Box className="flex flex-col px-2">
            <Box className="flex justify-between mb-6">
              <SoftButton
                onClick={handleImageClick}
                startIcon={
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.40654 11.7398C9.27987 11.7398 9.1532 11.6932 9.0532 11.5932C8.85987 11.3998 8.85987 11.0798 9.0532 10.8865L11.0799 8.85984C11.2732 8.66651 11.5932 8.66651 11.7865 8.85984C11.9799 9.05318 11.9799 9.37318 11.7865 9.56651L9.75989 11.5932C9.66656 11.6865 9.53987 11.7398 9.40654 11.7398Z" fill="#667085"/>
                  <path d="M11.4334 9.71338H4.56006C4.28673 9.71338 4.06006 9.48671 4.06006 9.21338C4.06006 8.94005 4.28673 8.71338 4.56006 8.71338H11.4334C11.7067 8.71338 11.9334 8.94005 11.9334 9.21338C11.9334 9.48671 11.7134 9.71338 11.4334 9.71338Z" fill="#667085"/>
                  <path d="M4.56669 7.28672C4.44003 7.28672 4.31336 7.24006 4.21336 7.14006C4.02003 6.94672 4.02003 6.62672 4.21336 6.43339L6.24001 4.40672C6.43334 4.21339 6.75336 4.21339 6.9467 4.40672C7.14003 4.60005 7.14003 4.92007 6.9467 5.1134L4.92002 7.14006C4.82002 7.24006 4.69336 7.28672 4.56669 7.28672Z" fill="#667085"/>
                  <path d="M11.4334 7.28662H4.56006C4.28673 7.28662 4.06006 7.05995 4.06006 6.78662C4.06006 6.51329 4.28673 6.28662 4.56006 6.28662H11.4334C11.7067 6.28662 11.9334 6.51329 11.9334 6.78662C11.9334 7.05995 11.7134 7.28662 11.4334 7.28662Z" fill="#667085"/>
                  <path d="M8.00016 15.1668C4.04683 15.1668 0.833496 11.9535 0.833496 8.00016C0.833496 4.04683 4.04683 0.833496 8.00016 0.833496C11.9535 0.833496 15.1668 4.04683 15.1668 8.00016C15.1668 11.9535 11.9535 15.1668 8.00016 15.1668ZM8.00016 1.8335C4.60016 1.8335 1.8335 4.60016 1.8335 8.00016C1.8335 11.4002 4.60016 14.1668 8.00016 14.1668C11.4002 14.1668 14.1668 11.4002 14.1668 8.00016C14.1668 4.60016 11.4002 1.8335 8.00016 1.8335Z" fill="#667085"/>
                </svg>
                }
                sx={{
                  background: '#F9FAFB',
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "400",
                  letterSpacing: "0.095px",
                  paddingX: "1.75rem",
                  marginRight: '1.5rem',
                  color: '#667085'
                }}
              >
                Request Metric
              </SoftButton>
              <Modal
                open={confirmModal}
                onClose={handleCloseModal2}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={confirmModal}>
                  <SoftBox sx={styleModal}>
                    <SoftBox alignItems="center" sx={{padding: '30px'}}>
                      <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: "0 auto"}}>
                        <circle cx="35.5" cy="35.5" r="35.2809" fill="url(#paint0_linear_149_11933)" fillOpacity="0.2" stroke="url(#paint1_linear_149_11933)" strokeWidth="0.438272"/>
                        <circle cx="35.5001" cy="35.5001" r="28.7068" fill="url(#paint2_linear_149_11933)" fillOpacity="0.5" stroke="url(#paint3_linear_149_11933)" strokeWidth="0.438272"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M47.7467 25.9868C48.1213 26.3618 48.3316 26.8701 48.3316 27.4001C48.3316 27.9301 48.1213 28.4384 47.7467 28.8134L31.4881 45.0721C31.3004 45.2596 31.0772 45.4079 30.8317 45.5083C30.5861 45.6088 30.323 45.6594 30.0577 45.6571C29.7924 45.6549 29.5302 45.5999 29.2863 45.4953C29.0425 45.3908 28.8219 45.2387 28.6374 45.0481L22.2294 38.4241C21.864 38.0422 21.6645 37.5313 21.6747 37.0028C21.6848 36.4744 21.9038 35.9714 22.2836 35.6039C22.6635 35.2364 23.1734 35.0342 23.7019 35.0415C24.2304 35.0487 24.7345 35.2649 25.1041 35.6428L30.0987 40.8054L44.9201 25.9868C45.2951 25.6122 45.8034 25.4019 46.3334 25.4019C46.8634 25.4019 47.3717 25.6122 47.7467 25.9868Z" fill="#299E47"/>
                        <defs>
                        <linearGradient id="paint0_linear_149_11933" x1="35.5" y1="3.28704" x2="35.7772" y2="104.192" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#299E47" stopOpacity="0"/>
                        <stop offset="0.300199" stopColor="#299E47" stopOpacity="0.06"/>
                        <stop offset="1" stopColor="#299E47"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_149_11933" x1="35.5" y1="0" x2="35.5" y2="87.2161" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#98A2B3" stopOpacity="0.03"/>
                        <stop offset="1" stopColor="#41464D" stopOpacity="0.16"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_149_11933" x1="35.5001" y1="6.57422" x2="36.6752" y2="98.3835" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#299E47" stopOpacity="0.5"/>
                        <stop offset="0.356773" stopColor="#299E47" stopOpacity="0.2"/>
                        <stop offset="1" stopColor="#299E47" stopOpacity="0.7"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_149_11933" x1="35.5001" y1="6.57422" x2="35.5001" y2="64.4261" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#475467" stopOpacity="0.44"/>
                        <stop offset="1" stopColor="#8DA7CD" stopOpacity="0.06"/>
                        </linearGradient>
                        </defs>
                      </svg>
                      <SoftTypography sx={{textFillColor: "#ffffff", fontSize: '28px', textAlign: 'center', marginTop: '1rem'}} variant="h6" component="h2">
                        Metric Request Received
                      </SoftTypography>
                      <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '16px', fontWeight: '400', marginTop: '1.5rem'}} variant="p" component="p">
                        Your request will be reviewed promptly, and we'll have the metric ready for you in no time!
                      </SoftTypography>
                      <SoftBox alignItems="center" sx={{textAlign: 'center'}}>
                        <SoftButton sx={{width: '40%', marginTop: '5rem'}} onClick={handleCloseModal2}>
                          Okay 
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Fade>
              </Modal>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openModal}>
                  <SoftBox sx={styleModal}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <SoftTypography sx={{textFillColor: "#ffffff"}} variant="h6" component="h2">
                        Request Metric Creation
                      </SoftTypography>
                      <SoftButton
                        variant="outlined"
                        color="white"
                        onClick={handleCloseModal}
                        sx={{
                          "& svg": { fontSize: "1.25rem !important" },
                          borderRadius: "50%",
                          minWidth: "max-content",
                          minHeight: "max-content",
                          padding: "0.25rem",
                        }}
                      >
                        <CloseIcon />
                      </SoftButton>
                    </SoftBox>
                    <hr style={{marginTop: '1rem', marginBottom: '1rem'}}/>
                    <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '14px', fontWeight: '400'}} variant="p" component="p">
                      Not sure how to set up the right metric for your team? We're here to help! 
                    </SoftTypography>
                    <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '14px', fontWeight: '400'}} variant="p" component="p">
                      Simply fill out the details below, and our expert team will create the perfect metric
                    </SoftTypography>
                    <SoftBox sx={{marginTop: '2rem'}}>
                      <form ref={formRef}>
                        <SoftTypography sx={{textFillColor: "#ffffff", fontSize: '14.5px', fontWeight: '400'}} variant="p" component="p">
                          Name of desired metric
                        </SoftTypography>
                        <SoftInput
                          {...register("title")}
                          name="title"
                          type="text"
                          placeholder="Title of the metric"
                          sx={{
                            border: "1px solid rgba(233, 236, 239, 0.10)",
                            backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                            color: "#ffffffb3 !important",
                            "& .MuiInputBase-input::placeholder": {
                              color: "white !important",
                              opacity: "0.7",
                            },
                            marginBottom: '1rem'
                          }}
                          size="large"
                        />
                        <SoftTypography sx={{textFillColor: "#ffffff", fontSize: '14.5px', fontWeight: '400', marginTop: '1rem'}} variant="p" component="p">
                          Any integrations in mind?
                        </SoftTypography>
                        <SoftInput
                          {...register("integration")}
                          name="integration"
                          type="text"
                          placeholder="Please specify"
                          sx={{
                            border: "1px solid rgba(233, 236, 239, 0.10)",
                            backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                            color: "#ffffffb3 !important",
                            "& .MuiInputBase-input::placeholder": {
                              color: "white !important",
                              opacity: "0.7",
                            },
                            marginBottom: '1rem'
                          }}
                          size="large"
                        />
                        <SoftTypography sx={{textFillColor: "#ffffff", fontSize: '14.5px', fontWeight: '400'}} variant="p" component="p">
                          Details 
                        </SoftTypography>
                        <SoftInput
                          {...register("details")}
                          name="details"
                          type="textarea"
                          placeholder="Any specific requirements or desired outcome"
                          sx={{
                            border: "1px solid rgba(233, 236, 239, 0.10)",
                            backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                            color: "#ffffffb3 !important",
                            "& .MuiInputBase-input::placeholder": {
                              color: "white !important",
                              opacity: "0.7",
                            },
                            marginBottom: '1rem'
                          }}
                          size="large"
                        />
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" sx={{textAlign: 'center'}}>
                          <SoftButton sx={{width: '40%', marginTop: '1.5rem', background: '#F9FAFB!important', color: '#667085!important'}} onClick={handleCloseModal}>
                            Cancel
                          </SoftButton>
                          <SoftButton sx={{width: '40%', marginTop: '1.5rem'}} onClick={submitRequest}>
                            Submit
                          </SoftButton>
                        </SoftBox>
                      </form>
                    </SoftBox>
                  </SoftBox>
                </Fade>
              </Modal>
              <Modal
                open={openMaxMetric}
                onClose={handleCloseModal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openMaxMetric}>
                  <SoftBox sx={styleModal}>
                    <SoftBox display="flex" justifyContent="center" alignItems="center">
                      <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="35.5" cy="35.5" r="35.2809" fill="url(#paint0_linear_175_5395)" fillOpacity="0.2" stroke="url(#paint1_linear_175_5395)" strokeWidth="0.438272"/>
                        <circle cx="35.5001" cy="35.5001" r="28.7068" fill="url(#paint2_linear_175_5395)" fillOpacity="0.5" stroke="url(#paint3_linear_175_5395)" strokeWidth="0.438272"/>
                        <path d="M36.0001 28.333C36.2653 28.333 36.5197 28.4384 36.7072 28.6259C36.8947 28.8134 37.0001 29.0678 37.0001 29.333V35.333C37.0001 35.5982 36.8947 35.8526 36.7072 36.0401C36.5197 36.2276 36.2653 36.333 36.0001 36.333C35.7349 36.333 35.4805 36.2276 35.293 36.0401C35.1054 35.8526 35.0001 35.5982 35.0001 35.333V29.333C35.0001 29.0678 35.1054 28.8134 35.293 28.6259C35.4805 28.4384 35.7349 28.333 36.0001 28.333ZM36.0001 41.6663C36.3537 41.6663 36.6928 41.5259 36.9429 41.2758C37.1929 41.0258 37.3334 40.6866 37.3334 40.333C37.3334 39.9794 37.1929 39.6402 36.9429 39.3902C36.6928 39.1401 36.3537 38.9997 36.0001 38.9997C35.6465 38.9997 35.3073 39.1401 35.0573 39.3902C34.8072 39.6402 34.6667 39.9794 34.6667 40.333C34.6667 40.6866 34.8072 41.0258 35.0573 41.2758C35.3073 41.5259 35.6465 41.6663 36.0001 41.6663Z" fill="#981B14"/>
                        <path d="M29.7707 20.9603C29.8635 20.8674 29.9736 20.7936 30.0949 20.7433C30.2161 20.693 30.3461 20.667 30.4774 20.667H41.5227C41.7881 20.667 42.0414 20.7723 42.2294 20.9603L50.0401 28.771C50.2281 28.9577 50.3334 29.211 50.3334 29.4777V40.523C50.3334 40.6543 50.3074 40.7843 50.2571 40.9055C50.2068 41.0268 50.133 41.1369 50.0401 41.2297L42.2294 49.0403C42.1367 49.1333 42.0265 49.207 41.9053 49.2574C41.784 49.3077 41.654 49.3336 41.5227 49.3337H30.4774C30.3461 49.3336 30.2161 49.3077 30.0949 49.2574C29.9736 49.207 29.8635 49.1333 29.7707 49.0403L21.9601 41.2297C21.8671 41.1369 21.7934 41.0268 21.7431 40.9055C21.6927 40.7843 21.6668 40.6543 21.6667 40.523V29.4777C21.6667 29.2123 21.7721 28.959 21.9601 28.771L29.7707 20.9603ZM30.8907 22.667L23.6667 29.8923V40.1083L30.8921 47.3337H41.1081L48.3334 40.1097V29.891L41.1094 22.667H30.8907Z" fill="#981B14"/>
                        <defs>
                        <linearGradient id="paint0_linear_175_5395" x1="35.5" y1="3.28704" x2="35.7772" y2="104.192" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#9E2929" stopOpacity="0"/>
                        <stop offset="0.300199" stopColor="#9E2929" stopOpacity="0.06"/>
                        <stop offset="1" stopColor="#9E2929"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_175_5395" x1="35.5" y1="0" x2="35.5" y2="87.2161" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#98A2B3" stopOpacity="0.03"/>
                        <stop offset="1" stopColor="#41464D" stopOpacity="0.16"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_175_5395" x1="35.5001" y1="6.57422" x2="36.6752" y2="98.3835" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#9E2929" stopOpacity="0"/>
                        <stop offset="0.356773" stopColor="#9E2929" stopOpacity="0.2"/>
                        <stop offset="1" stopColor="#9E2929" stopOpacity="0.7"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_175_5395" x1="35.5001" y1="6.57422" x2="35.5001" y2="64.4261" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#475467" stopOpacity="0.44"/>
                        <stop offset="1" stopColor="#8DA7CD" stopOpacity="0.06"/>
                        </linearGradient>
                        </defs>
                      </svg>
                    </SoftBox>
                    <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '28px', fontWeight: '500', marginTop: '1rem', marginBottom: '1rem'}} variant="p" component="p">
                      Maximum Metrics Reached
                    </SoftTypography>
                    <SoftTypography sx={{textFillColor: "#ffffff", textAlign: 'center', fontSize: '18px', fontWeight: '400', marginBottom: '3rem'}} variant="p" component="p">
                      You have already created the maximum number of<br/> metrics allowed in this workspace. To create more,<br/> you'll need to delete an existing metric. 
                    </SoftTypography>
                    <SoftBox display="flex" justifyContent="center" alignItems="center" sx={{textAlign: 'center'}}>
                      <SoftButton sx={{width: '40%', marginTop: '1.5rem', background: '#F9FAFB!important', color: '#667085!important'}} onClick={handleCloseMaxMetric}>
                        Okay
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </Fade>
              </Modal>
              <SoftButton
                color="primary"
                onClick={() => {
                  if (groupKpis && groupKpis.length >= MAX_WORKSPACE_KPI) {
                    handleMaxMetric();
                  } else {
                    navigate("/gameboards/workspace-kpi/custom-metric-types");
                  }
                }}
                startIcon={<AddIcon sx={{ width: "1.5rem", height: "1.5rem" }} />}
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "400",
                  letterSpacing: "0.095px",
                  paddingX: "1.75rem",
                }}
              >
                Create Custom Metric
              </SoftButton>
            </Box>
          </Box>
          <CreateCustomKpi
            openCustomKpi={openCustomModal}
            handleCloseCustomKpi={() => setOpenCustomModal(false)}
          />
        </Box>
        <Box>
          <Box
            sx={{
              marginTop: { mini: "1rem", lg: "0rem" },
              marginBottom: "2rem",
              width: "100%",
            }}
          >
            {groupKpis && groupKpis?.length > 0 ? (
              <Grid container spacing={3}>
                {groupKpis?.map((kpi) => (
                  <Grid
                    item
                    mini={12}
                    md={4}
                    xxl={12 / groupKpis?.length}
                    key={kpi._id}
                  >
                    <Kpi kpi={kpi} target={kpi.target} allApps={allApps} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box
                sx={{
                  border: "2.5px dashed rgba(0, 0, 0, 0.2)",
                  minHeight: "300px",
                  borderRadius: "24px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <NewspaperSvg />
                </Box>
                <SoftTypography
                  variant="h5"
                  color="black"
                  sx={{
                    textAlign: "center",
                  }}
                >
                  No KPIs Yet!
                </SoftTypography>
                <SoftTypography
                  variant="caption"
                  sx={{
                    textAlign: "center",
                    color: `grey.${600}`,
                    display: "block",
                  }}
                >
                  Add metrics here by selecting your preferred KPIs below.
                </SoftTypography>
              </Box>
            )}
          </Box>
        </Box>
      </SoftBox>

      <ConnectedApplications
        apps={allApps}
        connectedApps={connectedApps}
        groupKpis={groupKpis}
        integrationsObj={integrationsObj}
        currentAppIntegrations={currentAppIntegrations}
        isLoading={isLoading}
        functions={{
          createCustomKpi: () => setOpenCustomModal(true),
          handleLocalKpi: (kpi) => {
            // Minimal selected Integrated Metrics data
            setNewKpi({ ...kpi, isNew: true });
            setOpenCustomModal(false)
          },
        }}
      />

      {(activeDialog === DIALOG_TYPES.DISPLAY_METRIC) &&
        <SetupDisplayDialog
          open
          handleReset={clearSetupDialogs}
        />
      }

      {(activeDialog === DIALOG_TYPES.SETUP) &&
        <SetupSelectDialog
          open
          metric={newKpi}
          handleReset={clearSetupDialogs}
        />
      }
    </>
  );
};

export default WorkspaceKpi;
