import React, { useState } from "react";
import { Grid, Tabs, Tab } from "@mui/material";
import CurrentPlugins from "../../components/Hub/PowerLinks";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import startCase from "lodash/startCase";
import { UseSelectedWorkspace } from "../../hooks/company";
import PageHeader from "../../components/PageHeader";
import HubNavBg from "../../img/hub-frame.svg";
import PropTypes from "prop-types";
import Rocket from "../../img/rocket.png";
import TabCarousel from "../../components/Hub/TabCarousel";
import Integrations from "../../components/Hub/Integrations";
import JetpackList from "./Jetpacks/List";
import Offers from "../../components/Hub/Offers";
import useMediaQuery from "@mui/material/useMediaQuery";
import { UseIsAdmin } from "../../hooks/auth";

// Imported Styles
import "./hubStyles.css";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <SoftBox
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      height="100%"
    >
      {value === index && <SoftBox sx={{ height: "100%" }}>{children}</SoftBox>}
    </SoftBox>
  );
};

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
};

const AdminHub = () => {
  const workspace = UseSelectedWorkspace();
  const [tabValue, setTabValue] = useState(0);
  const miniBp = useMediaQuery((theme) => theme.breakpoints.down("xxs"));
  const xxsBp = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isAdmin = UseIsAdmin();

  const powerlinksIcon = (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mingcute:external-link-fill" clipPath="url(#clip0_4794_3441)">
        <g id="Group">
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 3.16667C14 2.98986 13.9298 2.82029 13.8047 2.69526C13.6797 2.57024 13.5101 2.5 13.3333 2.5H10C9.82319 2.5 9.65362 2.57024 9.5286 2.69526C9.40357 2.82029 9.33333 2.98986 9.33333 3.16667C9.33333 3.34348 9.40357 3.51305 9.5286 3.63807C9.65362 3.7631 9.82319 3.83333 10 3.83333H11.724L10.3173 5.24C10.6822 5.49909 11.0011 5.8175 11.2607 6.182L12.6667 4.776V6.5C12.6667 6.67681 12.7369 6.84638 12.8619 6.9714C12.987 7.09643 13.1565 7.16667 13.3333 7.16667C13.5101 7.16667 13.6797 7.09643 13.8047 6.9714C13.9298 6.84638 14 6.67681 14 6.5V3.16667ZM10.3173 5.24C9.64099 4.75765 8.83072 4.49891 8 4.5H3.33333C2.97971 4.5 2.64057 4.64048 2.39052 4.89052C2.14048 5.14057 2 5.47971 2 5.83333V13.1667C2 13.5203 2.14048 13.8594 2.39052 14.1095C2.64057 14.3595 2.97971 14.5 3.33333 14.5H10.6667C11.0203 14.5 11.3594 14.3595 11.6095 14.1095C11.8595 13.8594 12 13.5203 12 13.1667V8.5C12 7.636 11.7267 6.836 11.26 6.182L7.138 10.3047C7.01227 10.4261 6.84386 10.4933 6.66907 10.4918C6.49427 10.4903 6.32706 10.4202 6.20345 10.2965C6.07985 10.1729 6.00974 10.0057 6.00822 9.83093C6.0067 9.65614 6.07389 9.48774 6.19533 9.362L10.318 5.23933L10.3173 5.24Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4794_3441">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const jetpacksIcon = (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="spaceship">
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6241 0.021175C11.7668 0.0138191 11.9059 0.0675629 12.0067 0.168967C12.1073 0.270232 12.1601 0.4095 12.1519 0.552021C11.7528 7.57065 5.5046 10.2048 5.44177 10.2304C5.25447 10.307 5.03951 10.2638 4.89633 10.1208L2.0525 7.27708C1.91022 7.13459 1.86671 6.92095 1.9419 6.73416C1.96704 6.67083 4.57208 0.389148 11.6241 0.021175ZM6.80579 5.91154C7.18153 6.06714 7.614 5.98107 7.90152 5.69346C8.29402 5.30086 8.29402 4.66445 7.90152 4.27184C7.614 3.98424 7.18153 3.89817 6.80579 4.05376C6.43005 4.20936 6.18506 4.57598 6.18506 4.98265C6.18506 5.38932 6.43005 5.75594 6.80579 5.91154Z"
          fill="currentColor"
        />
        <path
          id="Path"
          d="M0.712226 9.42857C1.07299 9.06535 1.60043 8.92274 2.09509 9.05468C2.58974 9.18661 2.97609 9.57295 3.10803 10.0676C3.23997 10.5622 3.09736 11.0897 2.73412 11.4504C2.17561 12.0089 0.15271 12.0205 0.15271 12.0205C0.15271 12.0205 0.15271 9.98656 0.712226 9.42857Z"
          fill="currentColor"
        />
        <path
          id="color-2"
          opacity="0.59854"
          d="M5.3593 1.13245C3.92136 0.882182 2.45165 1.34812 1.42057 2.38114C1.15172 2.65247 0.917982 2.95646 0.724822 3.28599C0.608482 3.48353 0.640533 3.73476 0.802742 3.89677L1.80263 4.89713C2.73175 3.42263 3.93992 2.1438 5.3593 1.13245Z"
          fill="currentColor"
        />
        <path
          id="color-3"
          opacity="0.59854"
          d="M11.0409 6.81409C11.2912 8.25199 10.8252 9.72165 9.79218 10.7527C9.52084 11.0215 9.21684 11.2553 8.88731 11.4484C8.68976 11.5648 8.43853 11.5327 8.27652 11.3705L7.27612 10.3707C8.75066 9.44156 10.0295 8.23342 11.0409 6.81409Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );

  const integrationsIcon = (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mdi:lightning-bolt-outline">
        <path
          id="Vector"
          d="M7.33333 6.81329V7.83329H9.84L8.66667 10.1866V9.16663H6.16L7.33333 6.81329ZM8.66667 1.16663L4 10.5H7.33333V15.8333L12 6.49996H8.66667V1.16663Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );

  const offersIcon = (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="mingcute:gift-fill" clipPath="url(#clip0_4794_3237)">
        <g id="Group">
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3333 9.16671V12.5C13.3333 13.0305 13.1226 13.5392 12.7475 13.9143C12.3725 14.2893 11.8638 14.5 11.3333 14.5H8.66667V9.16671H13.3333ZM7.33333 9.16671V14.5H4.66667C4.13623 14.5 3.62753 14.2893 3.25245 13.9143C2.87738 13.5392 2.66667 13.0305 2.66667 12.5V9.16671H7.33333ZM6 1.83338C6.37839 1.83293 6.75252 1.91321 7.09741 2.06887C7.44231 2.22453 7.75002 2.45199 8 2.73604C8.24999 2.45199 8.5577 2.22453 8.90259 2.06887C9.24748 1.91321 9.62161 1.83293 10 1.83338H10.3333C10.5986 1.83338 10.8529 1.93873 11.0404 2.12627C11.228 2.31381 11.3333 2.56816 11.3333 2.83338V3.16671C11.3333 3.65271 11.2033 4.10804 10.9767 4.50004H12.6667C13.0203 4.50004 13.3594 4.64052 13.6095 4.89057C13.8595 5.14062 14 5.47975 14 5.83338V7.16671C14 7.40938 13.9353 7.63738 13.8213 7.83338H8.66667V5.83338C8.66667 5.65656 8.59643 5.487 8.47141 5.36197C8.34638 5.23695 8.17681 5.16671 8 5.16671C7.82319 5.16671 7.65362 5.23695 7.5286 5.36197C7.40357 5.487 7.33333 5.65656 7.33333 5.83338V7.83338H2.17867C2.06134 7.63081 1.9997 7.4008 2 7.16671V5.83338C2 5.47975 2.14048 5.14062 2.39053 4.89057C2.64057 4.64052 2.97971 4.50004 3.33333 4.50004H5.02333C4.78897 4.09482 4.66592 3.63483 4.66667 3.16671V2.83338C4.66667 2.56816 4.77202 2.31381 4.95956 2.12627C5.1471 1.93873 5.40145 1.83338 5.66667 1.83338H6ZM6 3.16671C6 3.52033 6.14048 3.85947 6.39053 4.10952C6.64057 4.35957 6.97971 4.50004 7.33333 4.50004C7.33333 4.14642 7.19286 3.80728 6.94281 3.55723C6.69276 3.30718 6.35362 3.16671 6 3.16671ZM10 3.16671C9.64638 3.16671 9.30724 3.30718 9.05719 3.55723C8.80714 3.80728 8.66667 4.14642 8.66667 4.50004C9.02029 4.50004 9.35943 4.35957 9.60948 4.10952C9.85952 3.85947 10 3.52033 10 3.16671Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4794_3237">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const HubNavTabs = [
    { label: "Power Links", icon: powerlinksIcon, status: true },
    { label: "Jetpacks", icon: jetpacksIcon, status: true },
    {
      label: "Integrations",
      icon: integrationsIcon,
      status: !!isAdmin,
    },
    {
      label: "Offers",
      icon: offersIcon,
      status: false,
    },
  ];

  return (
    <>
      {/* Main Content */}
      <PageHeader
        headerText="My Hub"
        subheaderText="Manage Employee Scores and Performance"
      />
      <SoftBox sx={{ margin: { mini: "1rem", sm: "1.5rem" } }}>
        <Grid container>
          <Grid item mini={12}>
            <SoftBox>
              <SoftTypography
                // eslint-disable-next-line no-nested-ternary
                variant={miniBp ? "h6" : xxsBp ? "h5" : "h4"}
                sx={{
                  fontWeight: 500,
                  fontSize: "24px",
                  color: {
                    mini: "white !important",
                    lg: "#051923 !important",
                  },
                }}
              >
                Welcome to our Hub of Excellence
              </SoftTypography>
              <SoftBox
                mt="1rem"
                mb="4rem"
                flexGrow={1}
                alignItems="end"
                sx={{ display: { mini: "none", md: "flex" } }}
              >
                <Tabs
                  orientation="horizontal"
                  value={tabValue}
                  onChange={(event, newValue) => setTabValue(newValue)}
                  sx={{
                    background: "rgba(249, 249, 255, 0.80)",
                    borderRadius: "4px",
                    padding: "8px",
                    boxShadow: "0px 4px 7px -1px rgba(0, 0, 0, 0.12)",
                    width: "max-content",
                    "& .MuiTabs-indicator": {
                      borderRadius: "4px",
                      boxShadow: "none",
                    },
                  }}
                >
                  {HubNavTabs.map((nav, index) =>
                    nav?.status ? (
                      <Tab
                        label={nav.label}
                        icon={nav.icon}
                        iconPosition="start"
                        {...a11yProps(index)}
                        sx={{
                          color: "#343A40",
                          fontSize: "0.85rem",
                        }}
                      />
                    ) : null,
                  )}
                </Tabs>
              </SoftBox>
            </SoftBox>
          </Grid>

          <Grid item mini={12}>
            <TabPanel value={tabValue} index={0}>
              <CurrentPlugins />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <JetpackList />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <Integrations />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <Offers />
            </TabPanel>
          </Grid>
        </Grid>
      </SoftBox>
    </>
  );
};

export default AdminHub;
