import React, { useState, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Collapse, Divider, Badge } from "@mui/material";
import SoftProfile from "../ProfilePictures/SoftProfile";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import {
  UseSelectedWorkspace,
  UseCompanyWorkspaces,
  UseCompanyLogo,
  UseCompanyName,
  UseCompanySubWorkspaces,
} from "../../hooks/company";
import { UseIsAdmin } from "../../hooks/auth";

import { useDispatch } from "react-redux";
import { setSelectedWorkspace } from "../../redux/workspace/actions";
import WorkspaceDialog, { WORKSPACE_ACTION } from "./WorkspaceDialog";
import SubWorkspaceDialog, {
  SUB_WORKSPACE_ACTION,
} from "./SubWorkspace/SubWorkspaceDialog";
import SWPopover from "./SubWorkspace/SWPopover";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { GET_IMAGE } from "../../helpers/images";
import {
  ExpandLess,
  ExpandMore,
  StarBorder,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  Settings,
} from "@mui/icons-material";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import SubWorkspaceDrawer from "./SubWorkspaceDrawer";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: "0px 6px 6px -3px #10182808",
    borderRadius: "1.5px",
    padding: "0 4px",
    left: 0,
  },
}));

const WorkspaceDrawer = ({ children, onClose, viewOnly }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyWorkspaces = UseCompanyWorkspaces();
  const selectedWorkspaceHook = UseSelectedWorkspace();
  const subworkspaces = UseCompanySubWorkspaces();
  const isAdmin = UseIsAdmin();
  const logo = UseCompanyLogo();
  const company = UseCompanyName();
  const [open, setOpen] = useState(false);
  const [openSubWorkspace, setOpenSubWorspace] = useState(false);
  const [editWorkspace, setEditWorkspace] = useState(null);
  const [editSubWorkspace, setEditSubWorkspace] = useState(null);
  const [parentWorkspace, setParentWorkspace] = useState(null);
  const [showSWLength, setShowSWLength] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSW, setAnchorElSW] = React.useState(null);

  const openMenu = Boolean(isAdmin && anchorEl);
  const openMenuSW = Boolean(isAdmin && anchorElSW);

  const [openStates, setOpenStates] = useState({});

  const [openSubWorkspaceDrawer, setOpenSubWorkspaceDrawer] = useState(null);

  const handleOpenSubWorkspace = (event, parentWS) => {
    setParentWorkspace(parentWS);
    setOpenSubWorkspaceDrawer(event.currentTarget);
    event.stopPropagation();
  };
  const handleCloseSubWorkspace = () => {
    setOpenSubWorkspaceDrawer(null);
  };
  const openSubWorkspaceDrawerMenu = Boolean(openSubWorkspaceDrawer);

  const handleSWLengthBadgeOpen = (workspace) => {
    setShowSWLength(workspace._id);
  };

  const handleSWLengthBadgeClose = () => {
    setShowSWLength(null);
  };

  const toggleOpenState = (event, workspace) => {
    event.stopPropagation();
    setParentWorkspace(workspace);
    setOpenStates((prevState) => ({
      ...prevState,
      [workspace._id]: !prevState[workspace._id],
    }));
  };

  const profileStyle = {
    width: "3vh",
    height: "3vh",
    fontSize: "2.25vh",
    fontWeight: "normal",
    backgroundColor: "#ced4da !important",
    background: "unset",
    borderRadius: "4px",
    color: "#f8f9fa",
  };

  const workspaces = useMemo(() => {
    if (companyWorkspaces.length) {
      return [...companyWorkspaces];
    } else {
      return null;
    }
  }, [companyWorkspaces]);

  const handleRightClick = (event, workspace) => {
    setEditWorkspace(workspace);
    setParentWorkspace(workspace);
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };
  const handleClose = () => {
    setAnchorEl(null);
    setEditWorkspace(null);
    onClose?.();
  };

  const handleSettings = (event, workspace) => {
    event.stopPropagation();
    setEditWorkspace(workspace);
    setAnchorEl(null);
    setOpen(true);
  };

  const handleSettingsSW = (event, workspace, parentWorkspaceItem) => {
    event.stopPropagation();
    setEditSubWorkspace(workspace);
    setParentWorkspace(parentWorkspaceItem);
    setAnchorElSW(null);
    setOpenSubWorspace(true);
  };

  const handleSubWorkspace = () => {
    setAnchorElSW(null);
    setOpenSubWorspace(true);
  };

  const handleCloseSW = () => {
    setAnchorElSW(null);
    setEditSubWorkspace(null);
  };

  const handleSWRightClick = (event, workspace, parentWorkspaceItem) => {
    setEditSubWorkspace(workspace);
    setParentWorkspace(parentWorkspaceItem);
    setAnchorElSW(event.currentTarget);
    event.preventDefault();
  };

  const handleSelectWorkspace = (workspace) => {
    dispatch(setSelectedWorkspace({ selectedWorkspace: workspace }));
    try {
      if (window.location.pathname === "/company-view") {
        navigate("/home");
      }
    } catch (err) {
      console.log("Workspace redirect error", err);
    }
    onClose?.();
  };

  const handleAddWorkspace = () => {
    setEditWorkspace(null);
    setOpen(true);
  };

  return (
    <SoftBox
      sx={{
        width: "100%",
        borderRadius: "4px",
        background: "#F9FAFB",
        padding: "15px",
        boxShadow: "0px 4px 4px 0px #000040",
      }}
    >
      <SoftTypography
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          color: "#6C757D",
          marginBottom: "5px",
        }}
      >
        My Workspaces
      </SoftTypography>
      <List sx={{ width: "100%" }}>
        {workspaces?.map((workspace) => {
          const subItems = subworkspaces[workspace._id];
          return (
            <>
              <ListItem
                key={workspace._id}
                disablePadding
                sx={{ display: "block" }}
                onMouseEnter={() => handleSWLengthBadgeOpen(workspace)}
                onMouseLeave={handleSWLengthBadgeClose}
              >
                <ListItemButton
                  selected={selectedWorkspaceHook?.name === workspace.name}
                  sx={{
                    minHeight: "3.25vh",
                    justifyContent: "center",
                    px: "0.25vw",
                    borderRadius: "4px",
                    paddingY: "0.5vh",
                  }}
                  onClick={() => {
                    handleSelectWorkspace(
                      workspace,
                      workspace.isCompany ? "/company-view" : "/home",
                    );
                    if (window.location.pathname !== "/home") {
                      navigate("/home");
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minHeight: "3.25vh",
                      justifyContent: "center",
                      px: "0.25vw",
                      borderRadius: "1vh",
                      paddingY: "0.5vh",
                    }}
                  >
                    <SoftProfile
                      size="sm"
                      src={workspace?.image ? GET_IMAGE(workspace?.image) : ""}
                      name={workspace?.name[0] ?? ""}
                      sx={profileStyle}
                    >
                      {!workspace?.image && <span>{workspace?.name[0]}</span>}
                    </SoftProfile>
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      "& .MuiTypography-root": {
                        display: "flex",
                        alignItems: "center",
                        fontSize: "12px",
                        lineHeight: "normal",
                        wordBreak: "break-word",
                        color: "#344054",
                        fontWeight: 400,
                      },
                    }}
                  >
                    <div style={{ maxWidth: "80%" }}>{workspace.name}</div>

                    {showSWLength === workspace._id && (
                      <StyledBadge
                        sx={{ position: "relative", width: "30px" }}
                        badgeContent={
                          subItems && subItems.length
                            ? subItems.length + 1
                            : "0"
                        }
                        color="primary"
                      />
                    )}
                  </ListItemText>
                  <SoftBox>
                    <ListItemIcon
                      sx={{
                        justifyContent: "center",
                        paddingY: "0.5vh",
                        minWidth: "20px",
                      }}
                      onClick={(e) => toggleOpenState(e, workspace)}
                    >
                      {openStates[workspace._id] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItemIcon>
                  </SoftBox>
                </ListItemButton>
              </ListItem>
              <Collapse
                in={openStates[workspace._id]}
                timeout="auto"
                unmountOnExit
                sx={{
                  background: "#FFF",
                  borderRadius: "4px",
                  padding: "10px",
                  marginY: "10px",
                }}
              >
                <SoftTypography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#98A2B3",
                    marginBottom: "5px",
                  }}
                >
                  {parentWorkspace && parentWorkspace.name} Sub-Workspaces
                </SoftTypography>
                {!subItems ? (
                  <SoftTypography
                    sx={{
                      fontSize: "10px",
                      fontWeight: 500,
                      color: "#667085",
                      marginBottom: "5px",
                    }}
                  >
                    You have no sub-workspace. Simply click the button below to
                    add a sub-workspace.
                  </SoftTypography>
                ) : (
                  <List component="div" disablePadding>
                    {subItems?.map((subWItem) => {
                      return (
                        <ListItem
                          key={subWItem._id}
                          disablePadding
                          sx={{ display: "block" }}
                        >
                          <ListItemButton
                            selected={
                              selectedWorkspaceHook?.name === subWItem.name
                            }
                            sx={{
                              minHeight: "3.25vh",
                              justifyContent: "center",
                              px: "0.25vw",
                              borderRadius: "4px",
                              paddingY: "0.5vh",
                            }}
                            onClick={() => {
                              handleSelectWorkspace(subWItem);
                              if (window.location.pathname !== "/home") {
                                navigate("/home");
                              }
                            }}
                          >
                            <ListItemIcon
                              sx={{
                                minHeight: "3.25vh",
                                justifyContent: "center",
                                px: "0.25vw",
                                borderRadius: "1vh",
                                paddingY: "0.5vh",
                              }}
                            >
                              <SoftProfile
                                size="sm"
                                fullCover
                                src={
                                  subWItem?.image
                                    ? GET_IMAGE(subWItem?.image)
                                    : ""
                                }
                                name={subWItem?.name[0] ?? ""}
                                sx={profileStyle}
                              >
                                {!subWItem?.image && (
                                  <span>{subWItem?.name[0]}</span>
                                )}
                              </SoftProfile>
                            </ListItemIcon>
                            <ListItemText
                              primary={subWItem.name}
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "1.25vh",
                                  lineHeight: "normal",
                                  wordBreak: "break-word",
                                },
                              }}
                            />
                            <ListItemIcon sx={{ cursor: "pointer", display: "flex", justifyContent: "flex-end" }}>
                              <EditOutlinedIcon onClick={(e) => {
                                e.stopPropagation();
                                setEditSubWorkspace(subWItem);
                                setOpenSubWorspace(true);
                              }} />
                            </ListItemIcon>
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                )}
                {isAdmin && !viewOnly && (
                  <>
                    <Divider
                      sx={{
                        margin: "0.25rem 0rem",
                        backgroundColor: "#D0D5DD",
                      }}
                    />

                    <ListItem
                      disablePadding
                      sx={{
                        paddingLeft: "20px",
                      }}
                    >
                      <ListItemButton
                        alignItems="center"
                        sx={{
                          flex: "none",
                          minHeight: 32,
                          px: 2.5,
                          paddingY: "8px",
                          borderRadius: "8px",
                          width: "100%",
                          "&:hover": {
                            borderRadius: "8px",
                          },
                        }}
                        onClick={handleSubWorkspace}
                      >
                        <SoftBox
                          sx={{
                            width: "24px",
                            height: "24px",
                            mr: "0.25rem",
                            background: "#E9ECEF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              justifyContent: "center",
                              "& svg": {
                                fontSize: "1.5rem",
                              },
                            }}
                          >
                            <AddRoundedIcon sx={{ color: "#7928CA" }} />
                          </ListItemIcon>
                        </SoftBox>

                        <ListItemText
                          primary="Add sub-workspace"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "10px",
                              color: "#7928CA",
                              fontWeight: 500,
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      disablePadding
                      sx={{
                        paddingLeft: "20px",
                      }}
                    >
                      <ListItemButton
                        sx={{
                          flex: "none",
                          minHeight: 32,
                          px: 2.5,
                          paddingY: "8px",
                          borderRadius: "8px",
                          width: "100%",
                          "&:hover": {
                            borderRadius: "8px",
                          },
                        }}
                        onClick={(e) => handleSettings(e, workspace)}
                      >
                        <SoftBox
                          sx={{
                            width: "24px",
                            height: "24px",
                            mr: "0.25rem",
                            background: "#E9ECEF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              justifyContent: "center",
                              "& svg": {
                                fontSize: "1.5rem",
                              },
                            }}
                          >
                            <EditOutlinedIcon sx={{ color: "#7928CA" }} />
                          </ListItemIcon>
                        </SoftBox>

                        <ListItemText
                          primary="Edit Workspace"
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: "10px",
                              color: "#7928CA",
                              fontWeight: 500,
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </>
                )}
              </Collapse>
            </>
          );
        })}
        {isAdmin && !viewOnly && (
          <>
            <Divider
              sx={{ margin: "0.25rem 0rem", backgroundColor: "#D0D5DD" }}
            />
            <ListItem
              disablePadding
              sx={{
                display: "flex",
                justifyContent: "center !important",
                textAlign: "center",
              }}
              alignItems="center"
            >
              <ListItemButton
                alignItems="center"
                sx={{
                  flex: "none",
                  minHeight: 32,
                  justifyContent: "center",
                  px: 2.5,
                  paddingY: "8px",
                  textAlign: "center",
                  borderRadius: "8px",
                  "&:hover": {
                    borderRadius: "8px",
                  },
                }}
                onClick={handleAddWorkspace}
              >
                <SoftBox
                  sx={{
                    width: "24px",
                    height: "24px",
                    mr: "0.25rem",
                    background: "#E9ECEF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                      "& svg": {
                        fontSize: "1.5rem",
                      },
                    }}
                  >
                    <AddRoundedIcon sx={{ color: "#7928CA" }} />
                  </ListItemIcon>
                </SoftBox>

                <ListItemText
                  primary="Add new workspace"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "10px",
                      color: "#7928CA",
                      fontWeight: 500,
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
      {/* {parentWorkspace && (
        <SubWorkspaceDrawer
          handleCloseSubWorkspace={handleCloseSubWorkspace}
          openSubWorkspaceDrawer={openSubWorkspaceDrawer}
          openSubWorkspaceDrawerMenu={openSubWorkspaceDrawerMenu}
          parentWorkspace={parentWorkspace}
          handleSelectWorkspace={handleSelectWorkspace}
          handleSettingsSW={handleSettingsSW}
          viewOnly={viewOnly}
          handleSubWorkspace={handleSubWorkspace}
        />
      )} */}

      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem dense onClick={handleSettings}>
          Settings
        </MenuItem>
        <MenuItem dense onClick={handleSubWorkspace}>
          <AddRoundedIcon sx={{ color: "#909090" }} />
          &nbsp;Add sub-workspace
        </MenuItem>
      </Menu>
      <Menu
        id="sw-positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorElSW}
        open={openMenuSW}
        onClose={handleCloseSW}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem dense onClick={handleSettingsSW}>
          Settings
        </MenuItem>
      </Menu>
      <WorkspaceDialog
        open={open}
        setOpen={setOpen}
        onEnd={handleClose}
        action={
          editWorkspace ? WORKSPACE_ACTION.UPDATE : WORKSPACE_ACTION.CREATE
        }
        group={editWorkspace}
      />
      <SubWorkspaceDialog
        open={openSubWorkspace}
        setOpen={setOpenSubWorspace}
        onEnd={handleClose}
        action={
          editSubWorkspace
            ? SUB_WORKSPACE_ACTION.UPDATE
            : SUB_WORKSPACE_ACTION.CREATE
        }
        group={editSubWorkspace}
        parentWorkspace={parentWorkspace}
      />
    </SoftBox>
  );
};

export default WorkspaceDrawer;
