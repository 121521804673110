import React from "react";
import { Dialog, Divider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { useNavigate } from "react-router-dom";

import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";
import { useDispatch } from "react-redux";
import { getCompanyUsers } from "../../redux/company/actions";

const AddPlayerBillingUpdate = ({
  addPlayerBillingDialog,
  closeAddPlayerBillingDialog,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const okayHandler = () => {
    closeAddPlayerBillingDialog();
    dispatch(getCompanyUsers());
  };

  return (
    <Dialog
      open={addPlayerBillingDialog}
      onClose={closeAddPlayerBillingDialog}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "600px",
          background:
            "linear-gradient(180deg, rgba(12, 12, 13, 0.46) 0%, rgba(12, 12, 13, 0.56) 48.96%, rgba(12, 12, 13, 0.53) 100%)",
          boxShadow: "-15px 15px 40px 0px rgba(13, 13, 14, 0.30)",
          backdropFilter: "blur(18px)",
        },
      }}
    >
      <SoftBox sx={{ padding: { mini: "1rem", md: "1.5rem" } }}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftTypography variant="h4" color="white">
            Billing Update
          </SoftTypography>
          <SoftButton
            variant="outlined"
            color="white"
            onClick={closeAddPlayerBillingDialog}
            sx={{
              "& svg": { fontSize: "1.25rem !important" },
              borderRadius: "50%",
              minWidth: "max-content",
              minHeight: "max-content",
              padding: "0.25rem",
            }}
          >
            <CloseRoundedIcon />
          </SoftButton>
        </SoftBox>
        <Divider
          sx={{
            backgroundImage:
              "linear-gradient(to right, #6C757D, #6C757D, #6C757D) !important",
          }}
        />
        <SoftTypography
          component="p"
          sx={{
            fontWeight: 500,
            color: "#FFF",
            fontSize: 16,
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Player Added Successfully!
        </SoftTypography>
        {/* <SoftTypography
          component="p"
          sx={{
            fontWeight: 500,
            color: "#FFF",
            fontSize: 16,
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Great news! As you're currently on our free plan, adding players is on
          the house. Enjoy the benefits at no cost!
        </SoftTypography> */}
        <SoftBox
          sx={{
            background:
              "linear-gradient(0deg, rgba(225, 197, 197, 0.15), rgba(225, 197, 197, 0.15)), linear-gradient(0deg, rgba(242, 244, 247, 0.3), rgba(242, 244, 247, 0.3))",
            borderRadius: "8px",
            border: "1px solid #F2F4F74D",
            padding: "10px 15px",
            display: "flex",
            marginBottom: "40px",
            gap: "0.5rem",
          }}
        >
          <ReportGmailerrorredIcon color="white" fontSize="medium" />

          <SoftTypography
            component="p"
            color="white"
            sx={{ fontSize: "14px", fontWeight: 500 }}
          >
            Please note that any additional players will be charged 
            $5 each in the upcoming billing cycle. Thanks for being part of Game My Biz!
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between">
          <SoftButton
            color="primary"
            variant="contained"
            onClick={() => navigate("/settings/billing")}
            sx={{
              color: "primary.contrastText",
              textTransform: "none",
              borderRadius: "4px",
            }}
          >
            Go to Billing
          </SoftButton>
          <SoftButton
            color="outlined"
            variant="contained"
            onClick={okayHandler}
            sx={{
              mx: 2,
              textTransform: "none",
              borderRadius: "4px",
              border: "1px solid rgba(233, 236, 239, 0.20)",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              background: "rgba(255, 255, 255, 0.10)",
              color: "#CED4DA",
              "&:hover": {
                background: "rgba(255, 255, 255, 0.10)",
              },
            }}
          >
            Okay
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </Dialog>
  );
};

export default AddPlayerBillingUpdate;
