import React, { useEffect, useState } from "react";

import WidgetBox from "../WidgetBox";
import PerformBoxFlexible from "../../../components/Hero/PerformBoxFlexible";

const PersonalGoal = ({ widget = {}, reFetch, setReFetch, showPerformBox, showCharts = false, isSmallScreen = false }) => {
  const [randomNumber, setRandomNumber] = useState(0);

  useEffect(() => {
    if (widget.multilineUserInput && widget.multilineUserInput.length) {
      setRandomNumber(
        Math.floor(Math.random() * widget.multilineUserInput.length),
      );
    }
  }, []);

  return showPerformBox ? (
    <PerformBoxFlexible
      widget={widget}
      title={widget.title}
      amount={
        widget.multilineUserInput && widget.multilineUserInput.length
          ? widget.multilineUserInput[randomNumber]
          : widget.userInput || ""
      }
      showGraph={false}
      showCharts={showCharts}
      isSmallScreen={isSmallScreen}
    />
  ) : (
    <WidgetBox
      widget={widget}
      widgetTitle={widget.title}
      widgetMetrics={widget.userInput || ""}
      reFetch={reFetch}
      setReFetch={setReFetch}
    />
  );
};

export default PersonalGoal;
