import React, { useState, useMemo, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  Checkbox,
  LinearProgress,
  Stepper,
  StepLabel,
  Step,
} from "@mui/material";
import { useForm } from "react-hook-form";
import debounce from "lodash/debounce";
import { Elements } from "@stripe/react-stripe-js";

import { FreeTrialSchema } from "../../form-validations";
import PaymentForm from "../Stripe/PaymentForm";
import { CHECK_UNIQUE_CREDENTIALS } from "../../api/user";
import { VERIFY_CODE } from "../../api/payment";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftInput from "../SoftInput";
import SoftSelect from "../SoftSelect";
// import industryOptions from "../../constants/industries";
import companySizeOptions from "../../constants/companySize";
import { GET_INDUSTRIES } from '../../api/industry';

const TrialSignupForm = ({
  submitUser,
  processing,
  error,
  toggleInputs,
  showInputs,
  stripe,
  clientSecret,
  order,
  handleResult,
  trialDays,
  coupon,
  step,
}) => {
  const validationOptions = {
    resolver: yupResolver(FreeTrialSchema),
    shouldUnregister: false,
  };

  const [industryOptions, setIndustryOptions] = useState([]);

  useEffect(() => {
    const fetchIndustries = async () => {
      try {
        const industries = await GET_INDUSTRIES();
        const businessIndustries = industries.map(industry => ({
          value: industry.name,
          label: industry.name,
        }));
        setIndustryOptions(businessIndustries);
      } catch (fetchError) {
        console.error('Failed to fetch industries', fetchError);
      }
    };
  
    fetchIndustries();
  }, []);

  const companySizes = companySizeOptions.map((companySizeOption) => ({
    value: companySizeOption,
    label: companySizeOption,
  }));

  const stepsData = ["Your Details", "Company Details", "Complete Signup"];
  const MUIMdBreakpoint = "1200px";

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    setValue,
    clearErrors,
    trigger,
  } = useForm(validationOptions);

  const [isInputValid, setInputValid] = useState(false);

  const checkInputVal = async () => {
    const email = getValues("email");
    const fName = getValues("firstName");
    const lName = getValues("lastName");
    const termsAndConditions = getValues("termsAndConditions");

    const result = await trigger("email");

    if (!result) return;

    if (!fName) {
      setError("firstName", {
        type: "custom",
        message: `First Name is required`,
      });
      setInputValid(false);
      return;
    } else {
      clearErrors("firstName");
    }

    if (!lName) {
      setError("lastName", {
        type: "custom",
        message: `Last Name is required`,
      });
      setInputValid(false);
      return;
    } else {
      clearErrors("lastName");
    }

    if (!termsAndConditions) {
      setError("termsAndConditions", {
        type: "custom",
        message: `You must agree to the terms and conditions`,
      });
      setInputValid(false);
      return;
    } else {
      clearErrors("termsAndConditions");
    }

    if (email) {
      return CHECK_UNIQUE_CREDENTIALS({ email })
        .then(() => {
          clearErrors("email");
          setInputValid(true);
          return true;
        })
        .catch((err) => {
          const errorResponse = err?.response?.data;
          const restDate = errorResponse.createdAt;
          const dateObj = new Date(restDate);
          const formattedresDate = dateObj.toISOString().split("T")[0];
          const currDate = new Date();
          const formattedDate = `${currDate.getFullYear()}-${(
            "0" +
            (currDate.getMonth() + 1)
          ).slice(-2)}-${("0" + currDate.getDate()).slice(-2)}`;
          if (formattedresDate === formattedDate) {
            setError("email", {
              type: "custom",
              message: `Invalid email: We cannot proceed with this email at the moment.`,
            });
          } else {
            setError("email", {
              type: "custom",
              message: `Invalid email: Email is already taken`,
            });
          }
          setInputValid(false);
          return true;
        });
    }
  };

  const checkInput = useMemo(
    () =>
      debounce(async () => {
        await checkInputVal();
      }, 0),
    [],
  );

  // temporarily not used since promotional codes marketing isn't launched yet
  const checkCode = useMemo(
    () =>
      debounce(async () => {
        const promoCode = getValues("code")?.trim();
        if (promoCode) {
          return VERIFY_CODE(promoCode)
            .then(() => {
              clearErrors("code");
              return true;
            })
            .catch(() => {
              setError("code", {
                type: "custom",
                message: `Invalid promotional code`,
              });
              return false;
            });
        }
      }, 2000),
    [],
  );

  const handleRegister = async (formData) => {
    const isUnique = await checkInputVal();
    if (isUnique) {
      const { termsAndConditions, ...userData } = formData;
      submitUser({ ...userData, country: "US" });
    }
  };

  return (
    <>
      <SoftBox
        className="w-75 mt-3 mb-3"
        sx={{
          "@media screen and (max-width: 768px)": {
            width: "100% !important",
          },
        }}
      >
        <Stepper
          activeStep={step}
          alternativeLabel
          sx={{
            maxWidth: MUIMdBreakpoint,
            margin: "auto",
            "& .MuiStepLabel-labelContainer": {
              "& .Mui-active, .Mui-completed": {
                color: "#42307D !important",
              },
            },
            ".MuiSvgIcon-root": {
              borderRadius: "50%",
              padding: "5px",
              border: "15px solid #98A2B3",
            },
            ".MuiStepIcon-root": {
              background: "#fff",
              // boxShadow: "0rem 0rem 10px 0.125rem #98A2B3",
            },
            ".MuiSvgIcon-root:not(.Mui-completed)": {
              color: "white",
            },
            ".MuiStepIcon-text": {
              fill: "green",
              fontWeight: 500,
            },
            ".MuiSvgIcon-root.Mui-active": {
              padding: "5px",
              borderRadius: "50%",
              border: "15px solid #9E77ED",
            },
            ".MuiStepIcon-root.Mui-active": {
              background: "#FFF",
              // boxShadow: "0rem 0rem 10px 0.125rem #98A2B3",
            },
            ".MuiSvgIcon-root.Mui-completed": {
              padding: "5px",
              borderRadius: "50%",
              border: "15px solid #9E77ED",
            },
            ".MuiStepIcon-root.Mui-completed": {
              background: "#FFF",
              // boxShadow: "0rem 0rem 10px 0.125rem #98A2B3",
            },
            ".MuiStepConnector-root": {
              top: "24%",
            },
            ".MuiStepConnector-alternativeLabel.Mui-active": {
              borderColor: "#9E77ED",
            },
            ".Mui-active .MuiStepIcon-text": {
              fill: "white",
            },
          }}
        >
          {stepsData.map((stepInfo) => (
            <Step key={stepInfo}>
              <StepLabel>{stepInfo}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </SoftBox>
      <SoftBox
        borderRadius="xl"
        shadow="lg"
        className="p-5 w-75"
        sx={{
          "@media screen and (max-width: 768px)": {
            width: "100% !important",
          },
        }}
      >
        <form onSubmit={handleSubmit(handleRegister)}>
          <Grid container spacing={2}>
            {step === 0 && (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{ width: "100%", marginBottom: "30px" }}
                >
                  <h3
                    style={{
                      fontSize: "32px",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Welcome to the Future
                  </h3>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      textAlign: "center",
                      color: "#344054",
                    }}
                  >
                    Where gamification turns everyday tasks into exciting challenges
                  </p>
                </Grid>
                <Grid item xs={12} md={6} style={{ width: "100%" }}>
                  <SoftInput
                    placeholder="First Name"
                    label="First Name"
                    {...register("firstName", {
                      onChange: () => {
                        checkInput();
                      },
                    })}
                    error={!!errors?.firstName}
                    helperText={errors?.firstName?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ width: "100%" }}>
                  <SoftInput
                    placeholder="Last Name"
                    label="Last Name"
                    {...register("lastName", {
                      onChange: () => {
                        checkInput();
                      },
                    })}
                    error={!!errors?.lastName}
                    helperText={errors?.lastName?.message}
                  />
                </Grid>
                <Grid item md={12} style={{ width: "100%" }}>
                  <SoftInput
                    placeholder="Email"
                    label="Email"
                    {...register("email", {
                      onChange: () => {
                        checkInput();
                      },
                    })}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                  />
                </Grid>
                <Grid item md={12} style={{ width: "100%" }}>
                  <Checkbox
                    {...register("termsAndConditions", {
                      onChange: () => {
                        checkInput();
                      },
                    })}
                    error={!!errors?.termsAndConditions}
                    helperText={errors?.termsAndConditions?.message}
                  />
                  <span style={{ fontSize: "12px" }} className="align-middle">
                    By providing us with your information you are consenting to
                    the collection and use of your information in accordance
                    with our
                    <a
                      href="https://gamemybiz.com/terms-condition"
                      target="_blank"
                      rel="noreferrer"
                      className="my-auto px-1 text-blue-600 dark:text-blue-500 hover:underline"
                    >Terms and Conditions.</a>
                  </span>
                  {errors?.termsAndConditions?.message && (
                    <p className="text-danger text-xs font-semibold">
                      {errors.termsAndConditions.message}
                    </p>
                  )}
                </Grid>
              </>
            )}
            {step === 1 && (
              <>
                <Grid
                  item
                  md={12}
                  style={{ width: "100%", marginBottom: "30px" }}
                >
                  <h3
                    style={{
                      fontSize: "32px",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Tell Us About Your Company
                  </h3>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      textAlign: "center",
                      color: "#344054",
                    }}
                  >
                    Building Your Game Space
                  </p>
                </Grid>
                <Grid item md={12} style={{ width: "100%" }}>
                  <SoftInput
                    placeholder="Company Name"
                    label="Company Name"
                    {...register("businessName")}
                    error={!!errors?.businessName}
                    helperText={errors?.businessName?.message}
                  />
                </Grid>

                <Grid item xs={12} md={6} style={{ width: "100%" }}>
                  <SoftSelect
                    name="industry"
                    id="industry"
                    options={industryOptions}
                    placeholder="Select Industry"
                    {...register("industry", { required: true })}
                    error={!!errors?.industry}
                    onChange={(option) => setValue("industry", option.value)}
                    styles={{
                      placeholder: {
                        color: "#000 !important",
                        opacity: "0.7",
                      },
                      input: {
                        color: "#000 !important",
                        opacity: "0.7",
                      },
                      singleValue: {
                        color: "#000 !important",
                        opacity: "0.7",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ width: "100%" }}>
                  <SoftSelect
                    name="companySize"
                    id="companySize"
                    options={companySizes}
                    placeholder="Select Company Size"
                    {...register("companySize", { required: true })}
                    error={!!errors?.companySize}
                    onChange={(option) => setValue("companySize", option.value)}
                    styles={{
                      placeholder: {
                        color: "#000 !important",
                        opacity: "0.7",
                      },
                      input: {
                        color: "#000 !important",
                        opacity: "0.7",
                      },
                      singleValue: {
                        color: "#000 !important",
                        opacity: "0.7",
                      },
                    }}
                  />
                </Grid>
                <Grid item md={12} style={{ width: "100%" }}>
                  <SoftInput
                    placeholder="Address"
                    label="Address"
                    {...register("address")}
                    error={!!errors?.address}
                    helperText={errors?.address?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ width: "100%" }}>
                  <SoftInput
                    placeholder="City"
                    label="City"
                    {...register("city")}
                    error={!!errors?.city}
                    helperText={errors?.city?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ width: "100%" }}>
                  <SoftInput
                    placeholder="State"
                    label="State"
                    {...register("state")}
                    error={!!errors?.state}
                    helperText={errors?.state?.message}
                  />
                </Grid>
                <Grid item md={12} style={{ width: "100%" }}>
                  <SoftInput
                    placeholder="Zip Code"
                    label="Zip Code"
                    {...register("zipcode")}
                    error={!!errors?.zipcode}
                    helperText={errors?.zipcode?.message}
                  />
                </Grid>
                {/* <Grid item md={12} style={{ width: "100%" }}>
                <SoftInput
                  placeholder="Promotional Code"
                  label="Promotional Code"
                  {...register("code", {
                    onChange: () => {
                      checkCode();
                    },
                  })}
                  error={!!errors?.code}
                  helperText={errors?.code?.message}
                />
              </Grid> */}
              </>
            )}
            {step === 2 && clientSecret && (
              <>
                <Grid item md={12} style={{ width: "100%" }}>
                  <h3
                    style={{
                      fontSize: "32px",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Payment Information
                  </h3>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      textAlign: "center",
                      color: "#344054",
                    }}
                  >
                    Enter your payment details to activate your founders account
                  </p>
                </Grid>
                <Grid item md={12} style={{ width: "100%" }}>
                  <Elements stripe={stripe} options={{ clientSecret }}>
                    <PaymentForm
                      newUser={true}
                      subscription={order}
                      handleResult={handleResult}
                      trialDays={trialDays}
                      coupon={coupon}
                    />
                  </Elements>
                </Grid>
              </>
            )}
            {step === 0 && (
              <Grid item md={12} style={{ width: "100%" }}>
                <SoftButton
                  fullWidth
                  onClick={toggleInputs}
                  style={{
                    borderRadius: "8px",
                    background:
                      "linear-gradient(120deg, rgba(0, 36, 224, 0.65) 0%, rgba(166, 0, 224, 0.85) 100%)",
                    color: "#FFFFFF",
                  }}
                  disabled={!isInputValid}
                  className="mb-2"
                >
                  Proceed
                </SoftButton>
              </Grid>
            )}
            {step === 1 && (
              <Grid item md={12} style={{ width: "100%" }}>
                <SoftButton
                  fullWidth
                  type="submit"
                  style={{
                    borderRadius: "8px",
                    background:
                      "linear-gradient(120deg, rgba(0, 36, 224, 0.65) 0%, rgba(166, 0, 224, 0.85) 100%)",
                    color: "#FFFFFF",
                  }}
                  disabled={processing}
                  className="mb-2"
                >
                  Save and Proceed
                </SoftButton>
                <LinearProgress className="mt-2" hidden={!processing} />
                <p className="text-danger text-xs font-semibold">{error}</p>
              </Grid>
            )}
          </Grid>
        </form>
      </SoftBox>
    </>
  );
};

export default TrialSignupForm;
