/* eslint camelcase: 0 */
import { request } from "../helpers/axios.new";

export const CREATE_LEVEL = (data) => {
  return request({
    url: `/level-up`,
    method: "POST",
    data,
  });
};
export const UPDATE_LEVEL = ({ levelId, levelName, levelInfo }) => {
  return request({
    url: `/level-up/update-level`,
    method: "PATCH",
    data: { levelId, levelName, levelInfo },
  });
};

export const GET_LEVEL = (groupId, all) => {
  return request({
    url: `/level-up?groupId=${groupId}&all=${all}`,
    method: "GET",
  });
};

export const DELETE_LEVEL = (levelId) => {
  return request({
    url: `/level-up/${levelId}`,
    method: "DELETE",
  });
};
