import React, { useEffect } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useDispatch } from "react-redux";

import { useStyles } from "./loginStyles";
import { socialLogin } from "../../redux/auth/actions";
import { UserAuth } from "../../context/authContext";
import { homePropmt } from "../../hooks/addToHome";
import TawkToChat from "../../components/TawkToChat";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

// Imported Pictures and Icons
import loginLogo from "../../img/GameMyBiz.png";
import googleLogo from "../../img/googleLogo.png";
import fbLogo from "../../img/facebook-logo.png";
import loginBackground from "../../img/main_logo.png";
import loginCompetition from "../../img/login-competition.png";
import loginThirdParty from "../../img/login-thirdparty.png";
import LoginCard from "../../components/Login/LoginCard";

function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fbSignIn, googleSignIn } = UserAuth();

  const mdBp = useMediaQuery((theme) => theme.breakpoints.up("1200"));

  useEffect(() => {
    homePropmt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFacebookSignIn = async () => {
    try {
      const login = await fbSignIn();
      if (login) {
        dispatch(
          socialLogin({
            firstName: login.displayName,
            lastName: login.displayName,
            username: login.email,
            email: login.email,
            password: "12345678",
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid
      container
      className={`w-full flex justify-center ${classes.loginGridContainer}`}
    >
      <Grid
        item
        md={12}
        lg={6}
        className="flex justify-center items-center p-3 h-lvh relative"
        style={{ flexDirection: "column" }}
      >
        <LoginCard />
      </Grid>
      {mdBp && (
        <Grid item md={12} lg={6} sx={{ width: "100%" }}>
          <SoftBox className={classes.rightPane}>
            <Carousel
              sx={{ width: "100% !important" }}
              navButtonsAlwaysInvisible
              interval={5000}
            >
              <SoftBox className={classes.sliderItemOne}>
                <img src={loginCompetition} alt="" />
                <SoftBox className={classes.gameboardContainer}>
                  <SoftTypography variant="h2" className={classes.title}>
                    Gameboard Leader
                  </SoftTypography>
                  <SoftTypography variant="p" className={classes.subText}>
                    Get Back in the Game
                  </SoftTypography>
                  <SoftTypography variant="p" className={classes.subText}>
                    Sign in to Access Your Business Insights!
                  </SoftTypography>
                </SoftBox>
                <SoftBox className={classes.bottomTextContainer}>
                  <SoftTypography variant="p" className={classes.subText}>
                    Welcome Back, Player!
                  </SoftTypography>
                  <SoftTypography
                    variant="p"
                    className={classes.subText}
                    mb="20px"
                  >
                    Ready to continue your journey to success?
                  </SoftTypography>
                  <SoftTypography variant="p" className={classes.subText}>
                    Your progress is waiting for you, along with valuable
                    insights and data to help you level up your business game.
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox className={classes.sliderItemTwo}>
                <img
                  src={loginThirdParty}
                  alt="Discover Insights from Various Data Sources"
                />
                <SoftBox className={classes.bottomTextContainer}>
                  <SoftTypography
                    variant="p"
                    className={classes.subText}
                    mb="15px"
                    sx={{ fontSize: "24px !important" }}
                  >
                    Discover Insights from Various Data Sources
                  </SoftTypography>
                  <SoftTypography variant="p" className={classes.subText}>
                    "Gain comprehensive insights to drive informed decisions and
                    optimize performance."
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </Carousel>
          </SoftBox>
        </Grid>
      )}
    </Grid>
  );
}

export default Login;
