import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { GET_CONNECTION_URL } from "../../../api/integration";
import {
  Dialog,
  Divider,
  useTheme,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useDispatch } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { debounce, startCase } from "lodash";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import {
  updateWorkspaceKpi,
  disableWorkspaceKpi,
} from "../../../redux/kpi/actions";
import { CustomKpiEditMetricSchema } from "../../../form-validations";
import { UseSelectedWorkspace } from "../../../hooks/company";
import SoftBox from "../../SoftBox";
import SoftTypography from "../../SoftTypography";
import SoftButton from "../../SoftButton";
import SoftInput from "../../SoftInput";
import KpiSubmissionFormBuilder from "../../FormBuilder/KpiSubmission";
import popNotification from "../../Modals/popNotification";
import { KIND_CONFIGS } from "../form/kindOptions";
import { EFFECT_CONFIGS } from "../form/effectOptions";
import {
  FORM_OPTIONS,
  FORM_CONFIGS,
  FORM_OPTIONS_ANCHOR,
} from "../form/formOptions";
import { useHorizontalScroller } from "../../../hooks/useHorizontalScroller";
import { KPI_TYPES } from "../../../constants/kpi";

const EditKpi = ({
  kpi,
  openEditKpi,
  handleCloseEditKpi,
  handleConfirmationOpen,
}) => {
  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;
  const [processing, setProcessing] = useState(false);
  const [inputs, setInputs] = useState([]);
  const { _id: groupId } = UseSelectedWorkspace();
  const dispatch = useDispatch();

  let currentValidationSchema = CustomKpiEditMetricSchema[2];
  /* eslint-disable */
  if (kpi.metricType === KPI_TYPES[0]) {
    // progressive metrics
    currentValidationSchema = CustomKpiEditMetricSchema[0];
  } else if (kpi.metricType === KPI_TYPES[1]) {
    // anchor metrics
    currentValidationSchema = CustomKpiEditMetricSchema[1];
  }

  const validationOptions = {
    resolver: yupResolver(currentValidationSchema),
    shouldUnregister: false,
    defaultValues: {
      name: kpi.title,
      kind: kpi.kind,
      direction: kpi.direction,
      // Show (target * 100) for %-anchor metrics, since we save them as decimal percentages on server
      target:
        kpi?.metricType === KPI_TYPES[1] && kpi.kind === "%"
          ? kpi.target * 100
          : kpi.target,
      pointScale:
        kpi?.metricType === KPI_TYPES[1] // anchor metric
          ? kpi?.optional?.point
          : kpi.pointScale,
      inputs: kpi.requiredInputs,
      // Also show (unit * 100) for %-anchor metrics "units", similar to "%-anchor targets"
      unit:
        kpi.optional && kpi.optional.unit
          ? kpi.kind === "%"
            ? kpi.optional.unit * 100
            : kpi.optional.unit
          : 0,
      type: 0,
    },
  };

  const {
    selectorName,
    addScrollListener,
    removeScrollListener,
    setContainerScrollXOverflow,
  } = useHorizontalScroller();

  useEffect(() => {
    if (!selectorName && openEditKpi) {
      setTimeout(() => {
        addScrollListener(".itemlist");
      }, 500);
    }

    if (!openEditKpi) {
      removeScrollListener();
    }
  }, [selectorName, addScrollListener]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    trigger,
  } = useForm(validationOptions);

  const [kindConfig] = useState(KIND_CONFIGS[kpi.kind]);
  const [effectConfig] = useState(EFFECT_CONFIGS[kpi.direction]);
  const [formIdx, setFormIdx] = useState(0);

  useEffect(() => {
    if (kpi.requiredInputs) {
      if (kpi.requiredInputs.length === 1) {
        // Single-input form
        const singleInput = kpi.requiredInputs[0];
        if (singleInput.type === FORM_CONFIGS.image.type) {
          setFormIdx(FORM_CONFIGS.image.index);
        } else if (singleInput.type === FORM_CONFIGS.link.type) {
          setFormIdx(FORM_CONFIGS.link.index);
        } else {
          setFormIdx(FORM_CONFIGS.text.index);
        }
      } else {
        // Custom-input form (can be single or multiple inputs)
        setFormIdx(FORM_CONFIGS.custom.index);
      }
      setInputs([
        ...kpi.requiredInputs.map(({ value, type }) => ({
          type,
          value: startCase(value),
        })),
      ]);
    }
  }, [kpi?.requiredInputs]);

  const handleInputs = (values) => {
    // Update both react-hook-form inputs and state inputs;
    // Relying on react-hook-form inputs alone does not trigger re-render when values change
    if (values && values.length) {
      setValue("inputs", [...values]);
      setInputs([...values]);
    } else {
      setValue("inputs", []);
      setInputs([]);
    }
  };

  const handleFormSelect = (idx) => {
    setFormIdx(idx);
    const formInputs = [...inputs];
    if (
      // Set to new type if Form Type changes
      formInputs.length &&
      (idx === FORM_CONFIGS.image.index || idx === FORM_CONFIGS.text.index)
    ) {
      formInputs[0].type = FORM_OPTIONS[idx].type;
    }
    handleInputs(formInputs);
  };

  const handleClose = () => {
    setProcessing(false);
    handleCloseEditKpi();
    setFormIdx(0);
    clearErrors();
    reset();
    setInputs([]);
  };

  const handleRegister = async (formData) => {
    setProcessing(true);

    const { inputs: rawInputs, type, ...data } = formData;
    let requiredInputs = [];

    if (rawInputs && rawInputs.length) {
      requiredInputs = rawInputs.map((el) => {
        // Extract only valid elements; it may have extra values from Form Builder
        const { type: inputType, value, desc } = el;
        return {
          type: inputType,
          value: value.toLowerCase().replace(" ", "_"),
          desc, // unimplemented; null item for now
        };
      });

      // Cut input to just one element if it's Single-input type
      if (
        formIdx === FORM_CONFIGS.image.index ||
        formIdx === FORM_CONFIGS.text.index
      ) {
        requiredInputs = [requiredInputs[0]];
      }
    }

    // Preprocess Anchor metrics data
    let anchorData = null;

    if (kpi?.metricType === KPI_TYPES[1]) {
      anchorData = {
        target: formData?.target,
        unit: formData?.unit,
        point: formData?.pointScale,
        gap: true,
      };

      // Set the "%" target (a.k.a. "anchor") and unit value if anchorType is "percentage"
      // Divide them by 100 to send as "decimal percentage" to server (previous slider implementation)
      if (kpi?.kind === "%") {
        anchorData.target = parseFloat(anchorData.target) / 100;
        anchorData.unit = parseFloat(anchorData.unit) / 100;
      }
    }

    const kpiData = {
      ...kpi,
      ...data,
      groupId,
      ...(anchorData && { ...anchorData }),
      ...(requiredInputs.length && { requiredInputs }),
    };

    dispatch(updateWorkspaceKpi(kpiData));

    // Fake success notification that appears after a short delay (and not when data has been successfully processed)
    setTimeout(
      () =>
        popNotification({
          title: "Your Metric has been Updated",
          text: `You've successfully update your ${data.name.toUpperCase()} Metric.`,
        }),
      1500,
    );
    handleClose();
  };

  const handleDelete = () => {
    dispatch(disableWorkspaceKpi(kpi._id));
    // Fake success notification that appears after a short delay (and not when data has been successfully processed)
    setTimeout(
      () =>
        popNotification({
          title: "Metric Deleted",
          text: `You've successfully deactivated ${kpi.title.toUpperCase()} metric.`,
        }),
      1500,
    );
    handleClose();
  };

  const metricType = useMemo(() => {
    let kpiMetricType = kpi?.metricType;
    const { target, pointScale } = kpi;

    // KPI must be an older Custom Created Metric (no "metricType" yet) - mostly "Progressive" in nature
    // 20240410: It could also be an Integrated metric, which are "Progressive" type of metrics
    if (!kpiMetricType) {
      kpiMetricType =
        target === 0 && pointScale === 0
          ? KPI_TYPES[2] // Display metric
          : KPI_TYPES[0]; // Progressive metric
    }

    return kpiMetricType;
  }, [kpi]);

  const handleConnect = () => {
    const connecting = toast.loading("Initializing connection ...");
    
    GET_CONNECTION_URL("googlebusiness")
      .then(({ data: url }) => {
        if (url) {
          toast.success("Connecting...", { id: connecting });
          const windowRef = window.open("", "_self");
          windowRef.location.href = url;
        } else {
          toast.error("Unable to establish connection.", {
            id: connecting,
          });
        }
      })
      .catch((err) => {
        toast.error(err.response?.data ?? "Server error", {
          id: connecting,
        });
      });
  };

  return (
    <Dialog
      open={openEditKpi}
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          padding: { mini: "1rem", md: "1rem 1.5rem" },
          borderRadius: "8px",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(25px)",
          width: "100%",
          maxWidth: "700px",
          borderWidth: "1px",
          borderColor: "#FFF",
          "::-webkit-scrollbar": {
            display: "none",
            height: 0,
            width: 0,
          },
        },
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftBox
          sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
        >
          <SoftTypography
            variant="h4"
            sx={{
              color: "#FFF",
              fontSize: "20px",
              textTransform: "capitalize",
            }}
          >
            Edit {kpi.title} Metric ({metricType} Metric)
          </SoftTypography>
          <SoftTypography
            variant="caption"
            sx={{ color: "#FFF", fontSize: "12px" }}
          >
            Adjust your custom metric settings
          </SoftTypography>
        </SoftBox>

        <SoftButton
          variant="outlined"
          color="white"
          onClick={handleClose}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>

      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />

      <form onSubmit={handleSubmit(handleRegister)}>
        <Grid container spacing={1} className="mt-2">
          <Grid item mini={12} marginBottom={1}>
            <SoftBox
              sx={{
                marginBottom: "0.25rem",
              }}
            >
              <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                Name Your KPI Metric
              </SoftTypography>
            </SoftBox>
            <SoftInput
              size="large"
              {...register("name")}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              sx={{
                border: "1px solid rgba(233, 236, 239, 0.10)",
                backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                color: "#ffffffb3 !important",
                "& .MuiInputBase-input::placeholder": {
                  color: "white !important",
                  opacity: "0.7",
                },
                "&.Mui-focused": {
                  borderColor: primaryColor,
                  boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                },
              }}
            />
          </Grid>

          {/** EDIT: Progressive Metric */}
          {kpi.metricType === KPI_TYPES[0] && (
            <>
              <Grid item mini={6} marginBottom={3}>
                <SoftBox
                  sx={{
                    marginBottom: "0.25rem",
                  }}
                >
                  <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                    Daily Target
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  size="large"
                  {...register("target")}
                  error={!!errors?.target}
                  helperText={errors?.target?.message}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>
              <Grid item mini={6} marginBottom={3}>
                <SoftBox
                  sx={{
                    marginBottom: "0.25rem",
                  }}
                >
                  <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                    Point Value
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  size="large"
                  {...register("pointScale")}
                  error={!!errors?.pointScale}
                  helperText={errors?.pointScale?.message}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>
            </>
          )}

          {/** EDIT: Anchor Metric */}
          {kpi.metricType === KPI_TYPES[1] && (
            <>
              <Grid item mini={4} marginBottom={3}>
                <SoftBox
                  sx={{
                    marginBottom: "0.25rem",
                  }}
                >
                  <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                    Anchor Point
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  size="large"
                  {...register("target")}
                  error={!!errors?.target}
                  helperText={errors?.target?.message}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>

              <Grid item mini={4} marginBottom={3}>
                <SoftBox
                  sx={{
                    marginBottom: "0.25rem",
                  }}
                >
                  <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                    Unit
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  size="large"
                  {...register("unit")}
                  error={!!errors?.unit}
                  helperText={errors?.unit?.message}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>

              <Grid item mini={4} marginBottom={3}>
                <SoftBox
                  sx={{
                    marginBottom: "0.25rem",
                  }}
                >
                  <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                    Point Value
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  size="large"
                  {...register("pointScale")}
                  error={!!errors?.pointScale}
                  helperText={errors?.pointScale?.message}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>
            </>
          )}

          {!kpi.metricType && (
            <>
              <Grid item mini={6} marginBottom={3}>
                <SoftBox
                  sx={{
                    marginBottom: "0.25rem",
                  }}
                >
                  <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                    Set Your Target
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  size="large"
                  {...register("target")}
                  error={!!errors?.target}
                  helperText={errors?.target?.message}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>
              <Grid item mini={6} marginBottom={3}>
                <SoftBox
                  sx={{
                    marginBottom: "0.25rem",
                  }}
                >
                  <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                    Select Point Scale
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  size="large"
                  {...register("pointScale")}
                  error={!!errors?.pointScale}
                  helperText={errors?.pointScale?.message}
                  sx={{
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                    backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                    color: "#ffffffb3 !important",
                    "& .MuiInputBase-input::placeholder": {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    "&.Mui-focused": {
                      borderColor: primaryColor,
                      boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                    },
                  }}
                />
              </Grid>
            </>
          )}

          <Grid item mini={12}>
            <SoftBox
              sx={{
                marginBottom: "0.25rem",
              }}
            >
              <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                Select Submission Type
              </SoftTypography>
            </SoftBox>

            <SoftBox
              className="itemlist"
              sx={{
                width: "100%",
                overflowX: setContainerScrollXOverflow(),
                "-webkit-overflow-scrolling": "touch",
                "::-webkit-scrollbar": {
                  display: "none",
                  height: 0,
                  width: 0,
                },
              }}
            >
              <SoftBox
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  width: "max-content",
                  gap: "10px",
                  overflowX: "auto",
                  overFlowY: "hidden",
                }}
              >
                {FORM_OPTIONS_ANCHOR.map((formType, index) => (
                  <SoftButton
                    disabled={index === 2} // Disable email submission for now
                    onClick={() => handleFormSelect(index)}
                    sx={{
                      width: "250px !important",
                      background: "rgba(255, 255, 255, 0.10)",
                      border:
                        formIdx === index
                          ? `1px solid ${primaryColor}`
                          : "1px solid rgba(233, 236, 239, 0.10)",
                      borderRadius: "8px",
                      padding: "0.5rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      flex: "0 0 auto",
                      textAlign: "start",
                      margin: "4px",
                      boxShadow:
                        formIdx === index
                          ? `0rem 0rem 0rem 0.1rem ${primaryColor}`
                          : "none",
                      "&:hover": {
                        borderColor: primaryColor,
                        background: "rgba(255, 255, 255, 0.10)",
                        boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                      },

                      "&:focus:not(:hover)": {
                        borderColor: primaryColor,
                        background: "rgba(255, 255, 255, 0.10)",
                        boxShadow: `0rem 0rem 0rem 0.1rem ${primaryColor}`,
                      },
                      "&:disabled": {
                        backgroundColor: "transparent !important",
                      },
                    }}
                  >
                    <SoftBox
                      display="flex"
                      gap="0.15rem"
                      alignItems="center"
                      marginBottom="0.25rem"
                      width="100%"
                    >
                      {formType.icon}
                      <SoftTypography
                        variant="button"
                        sx={{ color: "#CED4DA" }}
                      >
                        {formType.name}
                      </SoftTypography>
                    </SoftBox>
                    <SoftTypography
                      variant="caption"
                      sx={{ color: "#CED4DA", marginLeft: "0.25rem" }}
                    >
                      {formType.desc}
                    </SoftTypography>
                  </SoftButton>
                ))}
              </SoftBox>
            </SoftBox>

            {/** KPI Submission Form Builder */}
            <SoftBox
              borderRadius="lg"
              className="w-full h-auto my-4 px-4 overflow-hidden"
              sx={{
                background:
                  "linear-gradient(0deg, #414143, #414143), linear-gradient(0deg, rgba(108, 117, 125, 0.4), rgba(108, 117, 125, 0.4))",
              }}
            >
              <SoftTypography variant="h5" color="white" className="pt-4">
                KPI Submission Form
              </SoftTypography>
              <Divider
                sx={{
                  backgroundColor: "#FFF",
                  marginBottom: 0,
                }}
              />
              {formIdx <= 3 && (
                <SoftBox className="py-4">
                  <SoftInput
                    size="large"
                    placeholder={`Enter ${
                      formIdx === 0 ? "Text Input" : "Image Upload"
                    } Label`}
                    value={inputs[0]?.value}
                    onChange={(e) => {
                      // Match input format to complex form builder so any approach can be validated in the same way
                      const formInputs = [
                        {
                          value: e.target.value,
                          // Treat "link submission" as text input for now
                          type:
                            formIdx === 1
                              ? "image"
                              : formIdx === 2
                              ? "email"
                              : "text",
                        },
                      ];
                      handleInputs(formInputs);
                    }}
                    error={!!errors?.inputs}
                    helperText={errors?.inputs?.message}
                    sx={{
                      border: "1px solid rgba(233, 236, 239, 0.10)",
                      backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                      color: "#ffffffb3 !important",
                      "& .MuiInputBase-input::placeholder": {
                        color: "white !important",
                        opacity: "0.7",
                      },
                      "&.Mui-focused": {
                        borderColor: primaryColor,
                        boxShadow: `0rem 0rem 0rem 0.125rem ${primaryColor}`,
                      },
                    }}
                  />
                </SoftBox>
              )}
              {formIdx === 4 && (
                <SoftBox className="py-4">
                  <KpiSubmissionFormBuilder
                    inputs={inputs}
                    updateInputs={(values) => handleInputs(values)}
                    errors={errors}
                  />
                </SoftBox>
              )}
            </SoftBox>
          </Grid>
        </Grid>

        <Grid item mini={12} marginBottom={2}>
          <SoftBox
            sx={{
              marginBottom: "0.25rem",
            }}
          >
            <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
              Metric Behavior
            </SoftTypography>
          </SoftBox>

          <SoftBox
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            {kindConfig && (
              <SoftBox
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <SoftTypography
                  variant="p"
                  sx={{ fontSize: "16px", color: "#D0D5DD", fontWeight: 400 }}
                >
                  Metric Effect Type
                </SoftTypography>
                <SoftTypography
                  variant="p"
                  sx={{ fontSize: "14px", color: "#9E77ED", fontWeight: 700 }}
                >
                  {kindConfig.name}
                </SoftTypography>
              </SoftBox>
            )}
            {effectConfig && (
              <SoftBox
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <SoftTypography
                  variant="p"
                  sx={{ fontSize: "16px", color: "#D0D5DD", fontWeight: 400 }}
                >
                  Metric Value Type
                </SoftTypography>
                <SoftTypography
                  variant="p"
                  sx={{ fontSize: "14px", color: "#9E77ED", fontWeight: 700 }}
                >
                  {effectConfig.name}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
        {kpi.source === "googlebusiness" && (
          <SoftButton
            variant="outlined"
            color="white"
            onClick={handleConnect}
            sx={{
              borderRadius: "4px",
              border: "1px solid rgba(233, 236, 239, 0.20)",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              textTransform: "none",
              background: "rgba(255, 255, 255, 0.10)",
              color: "#CED4DA",
              "&:hover": {
                background: "rgba(255, 255, 255, 0.10)",
              },
              "&.Mui-disabled": {
                color: "#CED4DA !important",
              },
            }}
          >
            Change Location
          </SoftButton>
        )}
        <Divider
          sx={{
            backgroundColor: "#FFF",
          }}
        />

        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "0.75rem",
            margin: "1rem",
          }}
        >
          <SoftButton
            sx={{
              width: "100%",
              borderRadius: "4px",
              border: "1px solid rgba(233, 236, 239, 0.20)",
              boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
              textTransform: "none",
              background: "rgba(255, 255, 255, 0.10)",
              color: "#CED4DA",
              "&:hover": {
                background: "rgba(255, 255, 255, 0.10)",
              },
            }}
            onClick={handleConfirmationOpen}
          >
            <DeleteOutlineIcon fontSize="medium" sx={{ marginRight: "2px" }} />{" "}
            Delete Metric
          </SoftButton>

          <SoftButton
            color="primary"
            sx={{
              width: "100%",
              textTransform: "none",
              borderRadius: "4px",
              color: "#E9ECEF",
            }}
            disabled={processing}
            onClick={() => handleSubmit(handleRegister)}
            type="submit"
          >
            Update Metric
            {processing && (
              <CircularProgress color="inherit" size="1rem" className="ml-2" />
            )}
          </SoftButton>
        </SoftBox>
      </form>
    </Dialog>
  );
};

export default EditKpi;
