import React from "react";
import { Dialog, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import KpiModal from "../../pages/ManualEntry/KpiModal";
import { UseUserId } from "../../hooks/auth";
import Icon from "../Icons/Icon";
import SoftBox from "../SoftBox";

const PopUpModal = ({ kpi, disabled, ...props }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const userId = UseUserId();

  const handleOpen = () => {
    if (disabled) return;
    props.onOpen?.();
    setOpen(true);
  };
  const handleClose = () => {
    props.onClose?.();
    setOpen(false);
  };

  const handleSave = () => {
    handleClose();
    props.onSave?.();
  };

  return (
    <>
      <SoftBox 
        onClick={kpi.integrationId ? () => { navigate(`/scorecard?kpi=${kpi._id}`); } : handleOpen} 
        sx={{ height: "100%", cursor: "pointer" }}>
        {props.children}
      </SoftBox>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
        sx={{
          // width: { xs: "100vw", sm: "60vw" },
          ".MuiPaper-root": {
            borderRadius: "10px",
            background:
              "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
            boxShadow:
              "-15.85294px -15.85294px 26.42157px 0px rgba(0, 0, 0, 0.20) inset, 15.85294px 15.85294px 31.70588px 0px rgba(0, 0, 0, 0.20)",
            backdropFilter: "blur(20px)",
            padding: { mini: "1rem", md: "1.5rem" },
          },
          ".MuiDialog-paperFullWidth": {
            width: {
              mini: "100%",
              sm: "100%"
            }
          }
        }}
      >
        <SoftBox
          sx={{
            display: "grid",
            gridTemplateColumns: !kpi.integrationId ? "1fr 1fr" : "1fr",
            gap: 2,
            alignItems: "stretch", // Change from "center" to "stretch"
            justifyContent: "center",
            "& > *": {
              gridColumn: !kpi.integrationId ? "auto" : "1",
            },
            "& div:nth-child(2)": {
              marginTop: {
                mini: !kpi.integrationId ? "16px" : 0,
                sm: "unset"
              }
            }
          }}
        >
            <KpiModal
              kpi={kpi}
              userId={userId}
              userPoints={kpi?.amount ?? 0}
              onClose={handleClose}
              onSave={handleSave}
            >
              {!kpi.integrationId && (
                <SoftBox
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center", // Add this line
                    borderRadius: 2,
                    padding: { mini: "1rem", md: "2rem" },
                    boxShadow: 2,
                    background: "rgba(255, 255, 255, 0.07)",
                    border: "1px solid rgba(233, 236, 239, 0.10)",
                  }}
                >
                  <Icon iconKey="add" size={3} transparent={true} round />
                  <Typography
                    className="mt-2"
                    variant="body2"
                    sx={{ 
                      color: "#FFF",
                      lineHeight: "normal",
                      textAlign: "center"
                    }}
                  >
                    Add {kpi.title ?? "KPI"}
                  </Typography>
                </SoftBox>
              )}
            </KpiModal>

          <SoftBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 2,
              padding: { mini: "1rem", md: "2rem" },
              boxShadow: 2,
              cursor: "pointer",
              background: "rgba(255, 255, 255, 0.07)",
              border: "1px solid rgba(233, 236, 239, 0.10)",
              marginTop: "0px !important",
            }}
            onClick={() => {
              navigate(`/scorecard?kpi=${kpi._id}`);
            }}
          >
            <Icon iconKey="list" size={3} transparent={true} round />
            <Typography variant="body2" className="mt-2" sx={{ color: "#FFF" }}>
              See Details
            </Typography>
          </SoftBox>
        </SoftBox>
      </Dialog>
    </>
  );
};

export default PopUpModal;
