/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftBadge from "../../components/SoftBadge";
import SoftProfile from "../../components/ProfilePictures/SoftProfile";
import SoftButton from "../../components/SoftButton";

import ListSelectionDialog from "../../components/ListSelectionDialog/ListSelectionDialog";
import ClaimedIcon from "../../img/Claimed.svg";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { fontSize, fontWeight, lineHeight } from "@mui/system";

function TeamProfileCard({ industry, jobOptions, handleClaim, item }) {
  const [isClaimed, setIsClaimed] = useState(item.kpi && item.kpi.user);
  const { type } = item;
  const [isSelected, setIsSelected] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setIsClaimed(item.kpi && item.kpi.user);
  }, [item.kpi, item.kpi?.user]);

  return (
    <Card sx={{}}>
      <SoftBox p={3}>
        <SoftBox
          sx={{
            display: { mini: "flex", xs: "block", lg: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
            gap: "1rem",
          }}
        >
          <SoftBox
            sx={{
              display: "flex",
              gap: "0.25rem",
              marginBottom: { xs: "0.5rem", lg: "0rem" },
            }}
          >
            <SoftBadge
              variant="contained"
              size="sm"
              badgeContent={
                <DescriptionOutlinedIcon
                  sx={{
                    fill: theme.palette.primary.main,
                    fontSize: "1rem !important",
                  }}
                />
              }
              container
              sx={{
                "& .MuiBadge-badge": {
                  color: theme.palette.black.main,
                  background: theme.palette.grey[300],
                  padding: "0.5em",
                  borderRadius: "5px",
                },
              }}
            />

            <SoftBadge
              variant="contained"
              size="sm"
              badgeContent={
                <>
                  <CalendarMonthIcon
                    sx={{ marginRight: "0.25rem", fontSize: "1rem !important" }}
                  />
                  <SoftTypography variant="caption" color="black">
                    {moment(item.createdAt).format("MMM Do, YYYY")}
                  </SoftTypography>
                </>
              }
              container
              sx={{
                "& .MuiBadge-badge": {
                  color: theme.palette.black.main,
                  background: theme.palette.grey[300],
                  padding: "0.5em",
                  borderRadius: "5px",
                },
              }}
            />
          </SoftBox>
          <SoftBox display="flex">
            {isClaimed ? (
              <SoftBox
                display="flex"
                alignItems="center"
                justifyContent="end"
              > 
                <SoftBadge
                  variant="contained"
                  size="sm"
                  badgeContent={
                    <SoftTypography variant="caption" sx={{fontWeight: "bold", fontSize: "14px", lineHeight: "20px", color: "rgba(0, 0, 0, 0.6) !important"}}>
                      &nbsp; Claimed &nbsp;
                    </SoftTypography>
                  }
                  container
                  sx={{
                    "& .MuiBadge-badge": {
                      color: theme.palette.black.main,
                      background: theme.palette.grey[300],
                      padding: "0.5em",
                      borderRadius: "5px",
                    },
                  }}
                />
              </SoftBox>
            ) : (
              <ListSelectionDialog
                title="Select Job Item"
                items={jobOptions}
                onSelect={(jobItem) => {
                  setIsClaimed(true);
                  handleClaim(item._id, jobItem.id, type);
                }}
                searchTags={item.name.split(" ")}
                yes="Claim"
              >
                <SoftButton
                  variant="outlined"
                  disabled={jobOptions.length === 0}
                  color="primary"
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    minWidth: "max-content",
                    minHeight: "28px",
                    borderRadius: "5px",
                    padding: "0.25rem 0.5rem",
                    boxShadow: "none !important",
                  }}
                >
                  Claim Now
                  <ArrowForwardRoundedIcon
                    sx={{ fontSize: "1rem !important", marginLeft: "0.25rem" }}
                  />
                </SoftButton>
              </ListSelectionDialog>
            )}
          </SoftBox>
        </SoftBox>
        <Divider />
        <SoftBox sx={isClaimed ? { 
          position: 'relative',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${ClaimedIcon})`, 
            backgroundPosition: 'center', 
            backgroundRepeat: 'no-repeat', 
            backgroundSize: '45%', 
            opacity: 0.25,
            pointerEvents: 'none'
          }
        } : {
          paddingBottom: "2.80rem !important"
        }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <SoftTypography
              variant="h5"
              fontWeight="medium"
              color="black"
              textTransform="capitalize"
            >
              {item.name}
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={2}>
            <SoftTypography
              variant="body2"
              color="#00000099"
              sx={{
                textOverflow: "ellipsis",
                overflowY: "hidden",
                maxHeight: 200,
                fontSize: "15px",
                minHeight: {mini: 20, sm: 200},
              }}
            >
              {item.content}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        {isClaimed ? (
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <SoftTypography
              variant="body2"
              color="#00000099"
              sx={{
                fontSize: "15px",
              }}
            >
              Assigned to: {item.kpi?.user?.firstName}
            </SoftTypography>
            <SoftButton
              variant="outlined"
              color="primary"
              sx={{
                fontSize: "14px",
                fontWeight: "20px",
                textTransform: "none",
                boxShadow: "none !important",
                border: "none !important"
              }}
            >
              Reassign
            </SoftButton>
          </SoftBox>
        ) : null}
      </SoftBox>
    </Card>
  );
}

export default TeamProfileCard;
