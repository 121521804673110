import * as Yup from "yup";
import {
  KPI_KINDS,
  KPI_DIRECTIONS,
  KPI_FORM_INPUT_TYPES,
  KPI_IMAGE_PARAMS,
} from "../constants/kpi";

const CustomProgressiveMetricSchema = [
  Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .max(24, "Metric name must be at most 24 characters"),
    target: Yup.number()
      .required("Target is required")
      .typeError("Not a valid number")
      .min(0.01, "Target must be 0.01"),
      // .max(10000, "Target must be 0.01 - 10,000"),
    pointScale: Yup.number()
      .required("Points is required")
      .typeError("Not a valid number")
      .min(0, "Point scale must be zero or a positive number"),
    anchorType: Yup.string().required("Required"),
  }),
  Yup.object().shape({
    kind: Yup.string()
      .oneOf(KPI_KINDS, "Invalid KPI type")
      .required("Type is required"),
    direction: Yup.number()
      .oneOf(KPI_DIRECTIONS, "Invalid direction")
      .required("Direction is required"),
  }),
  Yup.object().shape({
    advanced: Yup.boolean(),
    dataSourceOne: Yup.string().when("advanced", {
      is: true,
      then: Yup.string().required("Data source is required"),
      otherwise: Yup.string(),
    }),
    calculationMethod: Yup.string().when("advanced", {
      is: true,
      then: Yup.string().required("Method is required"),
      otherwise: Yup.string(),
    }),
    dataSourceTwo: Yup.string().when("advanced", {
      is: true,
      then: Yup.string().required("Data source is required"),
      otherwise: Yup.string(),
    }),
    inputs: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string()
            .required("Input type is required")
            .oneOf(KPI_FORM_INPUT_TYPES, "Input type is not valid"),
          value: Yup.string()
            .notOneOf(
              ["amount", "Amount"],
              "Amount is a reserved word. Please select another label.",
            )
            .required("Input label is required"),
        }),
      )
      .min(1, "Form input is required")
      .required("Form input is required")
      .test(
        "one-image-only",
        "Only one image-type input is allowed",
        (values) => {
          // Check count of image-type parameters
          const imageCount = values?.reduce((count, { type }) => {
            const isImage = KPI_IMAGE_PARAMS.some((paramKey) =>
              type.toLowerCase().includes(paramKey),
            );
            return isImage ? count + 1 : count;
          }, 0);

          // Verify if it's 1 or none; If it's more than that, raise an error
          return imageCount <= 1;
        },
      ),
  }),
];

export default CustomProgressiveMetricSchema;
