import React, { useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateUserDetails, updateProfile } from "../../redux/auth/actions";
import {
  previewPrimaryColor,
  updateCompany,
  updateLogo,
} from "../../redux/company/actions";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import chroma from "chroma-js";

import { HexColorPicker } from "react-colorful";
import toast from "react-hot-toast";
import SoftAvatar from "../SoftAvatar";
import SoftBox from "../SoftBox";
import SoftButton from "../SoftButton";
import SoftDrawer from "../SoftDrawer";
import SoftTypography from "../SoftTypography";
import Avatar from "../Avatar";

import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import FaceIcon from "@mui/icons-material/Face";
import LogoutIcon from "@mui/icons-material/Logout";
import { GlowEffect } from "../../styles/effects";

import { UseIsAdmin, UseUserDetails, UseIsDemo, UseIsPrime } from "../../hooks/auth";
import {
  UseCompanyLogo,
  UseCompanyName,
  UseCompanyColors,
  UseCompany,
} from "../../hooks/company";
import { UPDATE_USER_LOGO, UPDATE_USER_IMAGE } from "../../api/user";
import { UPLOAD_LOGO } from "../../api/company";
import { GET_IMAGE } from "../../helpers/images";
import DEFAULT_AVATAR_URL from "../../img/avatars/DefaultMaleAvatar.glb";
import { ReactComponent as TVICON } from "../../img/icon-tv.svg";
import onboarding from "../../constants/onboarding";
import { BRIGHTNESS_MIN, BRIGHTNESS_MAX } from "../../theme";

const CustomizationSettings = ({
  open,
  handleClose,
  dark: darkMode = false,
}) => {
  const [openColors, setOpenColors] = useState(open && false);
  const [resolveSettings, setResolveSettings] = useState(false);
  const {
    _id: userId,
    image,
    firstName,
    onboardingStatus: userStatus,
  } = UseUserDetails();
  const isDemo = UseIsDemo();
  const isAdmin = UseIsAdmin();
  const isPrime = UseIsPrime();

  const { _id: companyId, onboardingStatus: companyStatus } = UseCompany();
  const status = isDemo ? userStatus : companyStatus;
  const customized = useMemo(
    () =>
      // Flag that determines if the user has customized or attempted to customize their profile at this time
      resolveSettings ||
      !(isAdmin || isDemo) ||
      (status &&
        status !== onboarding.ONGOING &&
        status !== onboarding.TRIAL_SIGNUP),
    [isAdmin, status, resolveSettings, userId],
  );
  const logo = UseCompanyLogo();
  const companyName = UseCompanyName();
  const { primaryColor: companyColor } = UseCompanyColors();
  const [primary, setPrimary] = useState(companyColor);
  const [convertedColor, setConvertedColor] = useState(companyColor);

  const logoInputRef = useRef();
  const profileInputRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dashboardColors = ["primary", "secondary", "tertiary"];

  const setCompanyColor = debounce((color) => {
    let recolor = color;
    const hsv = chroma(color).hsv();
    if (hsv[2] > BRIGHTNESS_MAX) {
      recolor = chroma.hsv(hsv[0], hsv[1], BRIGHTNESS_MAX).hex();
    } else if (hsv[2] < BRIGHTNESS_MIN) {
      recolor = chroma.hsv(hsv[0], hsv[1], BRIGHTNESS_MIN).hex();
    }
    setPrimary(color);
    setConvertedColor(recolor);
    if (isDemo) {
      dispatch(updateUserDetails({ primaryColor: recolor }));
    } else {
      dispatch(previewPrimaryColor(recolor));
    }
  }, 350);

  const handleUpload = async (type, e) => {
    try {
      if (e.target.files.length === 0) {
        return;
      }
      const imgFile = e.target.files[0];
      const formData = new FormData();
      formData.append("image", imgFile);

      const upload = toast.loading("Uploading...");

      if (type === "profile") {
        const { data } = await UPDATE_USER_IMAGE(userId, formData);
        dispatch(updateUserDetails({ image: data }));

        toast.success("Profile Successfully Updated", {
          id: upload,
        });
      } else {
        setOpenColors(false);
        if (isDemo) {
          const {
            data: { companyLogo, primaryColor },
          } = await UPDATE_USER_LOGO(formData);
          setCompanyColor(primaryColor);
          dispatch(updateUserDetails({ companyLogo, primaryColor }));
        } else {
          const {
            data: { companyLogo, primaryColor },
          } = await UPLOAD_LOGO(companyId, formData);
          setCompanyColor(primaryColor);
          dispatch(updateLogo(companyLogo));
        }

        toast.success("Logo Successfully Updated", {
          id: upload,
        });
      }
    } catch (error) {
      console.log(type, "Upload error", error);
      toast.dismiss();
      toast.error("Upload Failed");
    }
  };

  const handleSave = () => {
    if (isDemo) {
      dispatch(
        // Demo users customize logo and colors for their profile only
        updateProfile(userId, {
          companyLogo: logo,
          primaryColor: convertedColor,
          ...(!!logo &&
            !!convertedColor && { onboardingStatus: onboarding.CUSTOMIZED }),
        }),
      );
    } else {
      // Regular admin users customize company-level logo and colors
      dispatch(
        updateCompany({
          logo,
          primaryColor: convertedColor,
          ...(!!convertedColor && { onboardingStatus: onboarding.CUSTOMIZED }),
        }),
      );
    }
    setResolveSettings(true);
    handleClose();
  };

  const glowUp = (children, glow) => {
    if (glow) {
      return (
        <GlowEffect borderRadius="lg" className="w-[10rem]">
          {children}
        </GlowEffect>
      );
    } else {
      return <div className="w-[10rem]">{children}</div>;
    }
  };

  return (
    <SoftDrawer
      variant="temporary"
      shadow="xxl"
      open={open || (!!companyId && !customized)} // check customized only after the company info has been loaded
      onClose={handleSave}
      dark={darkMode}
      anchor="right"
      PaperProps={{ className: "no-scrollbar" }}
    >
      <SoftBox className="flex flex-col justify-between h-full">
        <SoftBox className="flex flex-col gap-2">
          <SoftBox className="flex justify-center items-center pt-4 pb-1 px-3">
            <SoftBox>
              <SoftTypography
                variant="h4"
                color={darkMode ? "white" : "primary"}
                sx={{
                  fontSize: "1.5rem",
                  lineHeight: "1.375",
                  fontWeight: 500,
                  letterSpacing: "0.00735em",
                }}
              >
                Customization Settings
              </SoftTypography>
            </SoftBox>
            <IconButton
              color={darkMode ? "white" : "dark"}
              sx={{
                position: "absolute",
                fontWeight: "fontWeightBold",
                cursor: "pointer",
                top: "1rem",
                right: 0,
              }}
              onClick={handleSave}
            >
              <HighlightOffIcon />
            </IconButton>
          </SoftBox>

          <SoftBox pt={1.25} pb={3} px={3} className="text-center">
            {(isAdmin || isDemo) && (
              <>
                <SoftTypography
                  variant="outline"
                  color={darkMode ? "white" : "text"}
                >
                  Brand your company
                </SoftTypography>
                <Divider
                  sx={{
                    mt: 0,
                    backgroundImage: (theme) =>
                      theme.palette.dividerGradients[
                        darkMode ? "light" : "dark"
                      ],
                  }}
                />

                {/** Company Logo */}
                <SoftBox className="flex items-center gap-4">
                  <SoftBox className="min-w-[100px]">
                    <SoftAvatar
                      size="xl"
                      shadow="md"
                      src={GET_IMAGE(logo)}
                      borderRadius="lg"
                      alt={companyName || "Company Name"}
                    />
                  </SoftBox>

                  <SoftBox className="grow flex flex-col gap-2 text-left">
                    <SoftTypography
                      variant="caption"
                      color={darkMode ? "white" : "text"}
                    >
                      Edit Your Company Logo
                    </SoftTypography>
                    {glowUp(
                      <SoftButton
                        color="primary"
                        variant={darkMode ? "contained" : "outlined"}
                        onClick={() => logoInputRef.current.click()}
                        fullWidth
                      >
                        Upload Logo <CloudUploadIcon className="ms-2" />
                      </SoftButton>,
                      !customized && !logo,
                    )}
                  </SoftBox>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    ref={logoInputRef}
                    onChange={(e) => handleUpload("logo", e)}
                    hidden
                  />
                </SoftBox>

                {/** Company Dashboard Theme Color */}
                <SoftBox className="flex items-center gap-4 mt-4">
                  {/** match color palette's box min width to SoftAvatar logos */}
                  <SoftBox className="mb-1 min-w-[100px]">
                    {dashboardColors.map((color) => (
                      <Icon
                        key={color}
                        sx={({
                          borders: { borderWidth },
                          palette: { white, dark },
                          transitions,
                        }) => ({
                          width: "1.5rem",
                          height: "1.5rem",
                          padding: 0,
                          border: `${borderWidth[1]} solid ${white.main}`,
                          borderRadius: "2rem",
                          transition: transitions.create("border-color", {
                            easing: transitions.easing.sharp,
                            duration: transitions.duration.shorter,
                          }),
                          backgroundImage: ({
                            functions: { linearGradient },
                            palette: { gradients },
                          }) =>
                            linearGradient(
                              gradients[color].main,
                              gradients[color].state,
                            ),

                          "&:not(:last-child)": {
                            mr: 1,
                          },

                          "&:hover, &:focus, &:active": {
                            borderColor: dark.main,
                          },
                        })}
                      />
                    ))}
                  </SoftBox>
                  <SoftBox className="grow flex flex-col gap-2 text-left">
                    <SoftTypography
                      variant="caption"
                      color={darkMode ? "white" : "text"}
                    >
                      Edit Theme Colors
                    </SoftTypography>
                    {glowUp(
                      <SoftButton
                        color="primary"
                        variant={darkMode ? "contained" : "outlined"}
                        onClick={() => setOpenColors((prevVal) => !prevVal)}
                        fullWidth
                      >
                        Edit Colors <ColorLensIcon className="ms-2" />
                      </SoftButton>,
                      !customized && !companyColor && !openColors,
                    )}
                  </SoftBox>
                </SoftBox>
                {openColors && (
                  <SoftBox mt={2} shadow="md">
                    <HexColorPicker
                      color={primary}
                      onChange={(e) => setCompanyColor(e)}
                      style={{ width: "100%", height: "150px" }}
                    />
                  </SoftBox>
                )}
                <SoftBox height="2rem" />
              </>
            )}
            <SoftTypography
              variant="outline"
              color={darkMode ? "white" : "text"}
            >
              Personalize your profile
            </SoftTypography>
            <Divider
              sx={{
                mt: 0,
                backgroundImage: (theme) =>
                  theme.palette.dividerGradients[darkMode ? "light" : "dark"],
              }}
            />

            {/** User Profile Picture */}
            <SoftBox className="flex items-center gap-4">
              <SoftBox className="min-w-[100px]">
                <SoftAvatar
                  size="xl"
                  shadow="md"
                  src={GET_IMAGE(image)}
                  borderRadius="lg"
                  alt={firstName || "Player"}
                />
              </SoftBox>
              <SoftBox className="grow flex flex-col gap-2 text-left">
                <SoftTypography
                  variant="caption"
                  color={darkMode ? "white" : "text"}
                >
                  Edit Your Profile Picture
                </SoftTypography>
                <SoftButton
                  color="primary"
                  variant={darkMode ? "contained" : "outlined"}
                  onClick={() => profileInputRef.current.click()}
                  className="w-[10rem]"
                >
                  Upload Photo <CloudUploadIcon className="ms-2" />
                </SoftButton>
              </SoftBox>
              <input
                type="file"
                accept="image/png, image/jpeg"
                ref={profileInputRef}
                onChange={(e) => handleUpload("profile", e)}
                hidden
              />
            </SoftBox>

            {/** User 3d Avatar */}
            <SoftBox className="flex items-center gap-4 mt-4">
              {/** match color palette's box min width to SoftAvatar logos */}
              <SoftBox className="mb-1 w-[100px] h-[5rem]">
                <Avatar
                  url={DEFAULT_AVATAR_URL}
                  fixed={true}
                  autoRotate={true}
                  disableAnim={true}
                  industry={null}
                  isOwn={false}
                />
              </SoftBox>
              <SoftBox className="grow flex flex-col gap-2 text-left">
                <SoftTypography
                  variant="caption"
                  color={darkMode ? "white" : "text"}
                >
                  Customize Your 3d Avatar
                </SoftTypography>
                <SoftButton
                  color="primary"
                  variant={darkMode ? "contained" : "outlined"}
                  onClick={() => {
                    handleSave();
                    navigate("/avatar");
                  }}
                  className="w-[10rem]"
                >
                  Edit 3d Avatar <FaceIcon className="ms-2" />
                </SoftButton>
              </SoftBox>
            </SoftBox>

            {/** Leaderboard TV button */}
            {isAdmin && isPrime && (
              <SoftBox sx={{ display: { sm: "none", md: "block " } }}>
                <Divider
                  sx={{
                    mt: 4,
                    backgroundImage: (theme) =>
                      theme.palette.dividerGradients[
                        darkMode ? "light" : "dark"
                      ],
                  }}
                />

                <SoftBox my={4}>
                  <SoftButton
                    color="primary"
                    variant={darkMode ? "contained" : "outlined"}
                    className="min-w-[15rem]"
                    onClick={() => {
                      // Used window.location.replace instead of react-router-dom navigate to fully refresh the page for TV
                      window.location.replace(
                        `${window.location.origin}/live/leaderboard/tv`,
                      );
                    }}
                  >
                    Go to Leaderboard TV View{" "}
                    <TVICON
                      style={{
                        width: "2rem",
                        height: "auto",
                        marginLeft: "0.75rem",
                      }}
                    />
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            )}

            {/** Thank you section */}
            <Divider
              sx={{
                mt: 4,
                backgroundImage: (theme) =>
                  theme.palette.dividerGradients[darkMode ? "light" : "dark"],
              }}
            />
            <SoftBox mt={2} textAlign="center">
              <SoftBox mb={1}>
                <SoftTypography
                  variant="h6"
                  color={darkMode ? "white" : "primary.dark"}
                >
                  Thank you for your support!
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox textAlign="center" padding={4}>
          <SoftButton
            color="primary"
            variant={darkMode ? "contained" : "outlined"}
            onClick={() => navigate("/signout")}
            className="w-[10rem]"
            sx={{ textTransform: "capitalize!important" }}
          >
            Log Out <LogoutIcon className="ms-2" />
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftDrawer>
  );
};

export default CustomizationSettings;
