import React, { useState, useEffect } from "react";
import { UseYetiStats } from "../../hooks/company";
import { formatNumber } from "../../helpers/formatter";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ScChartIcon from "../../img/sc-chart-icon.svg";
import ScChartLine from "../../img/sc-chart-line.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

const KpiBox = ({ kpiId, kpi, amount: playerAmt, points, ...rest }, ref) => {
  const [isLeading, setIsLeading] = useState(true);
  const [amount, setAmount] = useState(0);
  const [plus, setPlus] = useState("");
  const [yetiDiff, setYetiDiff] = useState(0);
  const yeti = UseYetiStats();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (yeti) {
      const versusYeti = points - yeti.month[kpi._id]?.pts;
      setPlus(versusYeti > 0 ? "+" : "");
      setIsLeading(versusYeti >= 0);
      setYetiDiff(playerAmt - yeti.month[kpi._id]?.amt);
      setAmount(playerAmt);
    }
  }, [kpi, yeti, playerAmt, points]);

  useEffect(() => {
    if (kpi.gap) {
      // Change display value if KPI has gap calculation
      setAmount(playerAmt - kpi.target);
    }
  }, [kpi, playerAmt]);
  return (
    <SoftBox
      sx={{
        borderRadius: "10.387px",
        border: "0.649px solid rgba(255, 255, 255, 0.10)",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), rgba(28, 132, 207, 0.30)",
        backdropFilter: "blur(11.036130905151367px)",
        minHeight: "80px",
        height: { mini: "12vh", sm: "15vh" },
        overflow: "hidden",
        ...(kpiId === kpi._id && { border: "2px solid white" }),
      }}
      {...rest}
    >
      <SoftBox
        sx={{
          paddingX: { mini: 1, md: 2 },
          paddingTop: { mini: 1, md: 2 },
          paddingBottom: { mini: 0.25, md: 0.5 },
        }}
      >
        <SoftBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <SoftBox sx={{ display: "flex", alignItems: "center" }}>
            <SoftBox
              marginRight="0.25rem"
              sx={{ width: { mini: "20px", md: "25px" } }}
            >
              <img src={ScChartIcon} alt="" style={{ width: "100%" }} />
            </SoftBox>

            <SoftTypography
              variant="button"
              color="white"
              sx={{ fontSize: { mini: "0.8rem", md: "0.875rem" } }}
            >
              {kpi.title}{" "}
              {kpi.isShared && (
                <Diversity3Icon size="medium" className="mt-[-2px]" />
              )}
            </SoftTypography>
          </SoftBox>

          <SoftBox
            sx={{
              // eslint-disable-next-line no-nested-ternary
              background: kpi.gap
                ? "#FFFFFF"
                : isLeading
                ? (theme) => theme.palette.positive.lightest
                : (theme) => theme.palette.negative.lightest,
              borderWidth: "1px",
              borderStyle: "solid",
              // eslint-disable-next-line no-nested-ternary
              borderColor: kpi.gap
                ? "#FFFFFF"
                : isLeading
                ? "positive.main"
                : "negative.main",
              borderRadius: "5px",
              padding: { mini: "1px 3px", md: "2.5px 5px" },
              height: "max-content",
              display: "flex",
              fontSize: { mini: "0.7rem", md: "0.75rem" },
            }}
          >
            <SoftTypography
              variant="caption"
              fontWeight="bold"
              sx={{
                // eslint-disable-next-line no-nested-ternary
                color: kpi.gap
                  ? "#FFFFFF"
                  : isLeading
                  ? "positive.main"
                  : "negative.main",
              }}
            >
              {!kpi.gap
                ? `${plus}${formatNumber(yetiDiff, kpi.kind)}`
                : formatNumber(kpi.target, kpi.kind)}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftTypography
          variant={isSmallScreen ? "h3" : "h2"}
          fontWeight="regular"
          sx={{
            // eslint-disable-next-line no-nested-ternary
            color: !kpi.gap
              ? "#FFFFFF"
              : amount >= 0
              ? "positive.main"
              : "negative.main",
          }}
        >
          {formatNumber(amount, kpi.kind)}&nbsp;
        </SoftTypography>
      </SoftBox>
      <SoftBox
        sx={{
          width: "100%",
          position: "absolute",
        }}
      >
        <img src={ScChartLine} alt="" style={{ width: "100%" }} />
      </SoftBox>
    </SoftBox>
  );
};

export default KpiBox;
