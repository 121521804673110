/* eslint-disable */

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./levelUpStyles.css";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Tabs, Tab, useMediaQuery, Divider } from "@mui/material";
import Add from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import SoftInput from "../../components/SoftInput";
import SoftTypography from "../../components/SoftTypography";
import AddBadgePopup from "./AddBadgePopup";
import EditBadgeLevel from "./EditBadgeLevel";
import CongratsPopup from "./CongratsPopup";
import BadgeRequirementPreview from "./BadgeRequirementPreview";
// import Levels from "../../components/LevelUp/Levels";
import { GET_IMAGE } from "../../helpers/images";
import SoftAvatar from "../../components/SoftAvatar";
import { CREATE_LEVEL, GET_LEVEL } from "../../api/level-up";
import { GET_USER_BADGE } from "../../api/userBadge";
import { getMasterBadges } from "../../redux/badges/actions";
import { UseUserDetails, UseIsAdmin } from "../../hooks/auth";
import ConfettiFireworks from "../../components/ParticleEffects/ConfettiFireworks";
import { dataLevels } from "./data";
import LevelUpNavBg from "../../img/levelup-navbg.jpg";
import levelUpranking from "../../img/levelUp-ranking.png";
import levelOne from "../../img/levelOne.png";
import levelTwo from "../../img/levelTwo.png";
import levelThree from "../../img/levelThree.png";
import levelOneBG from "../../img/levelOneBG.png";
import levelTwoBG from "../../img/levelTwoBG.png";
import levelThreeBG from "../../img/levelThreeBG.png";
import BadgeBg from "../../img/badgeBG.png";
import FeedTabPanel from "../Feed/FeedTabPanel";
import { DELETE_LEVEL } from "../../api/level-up";
import { PUT_WORKSPACE_IMAGE, SUBMIT_BADGE, DELETE_BADGE } from "../../api/group";
import { useForm } from "react-hook-form";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UseGroupKpis, UseMyKpis } from "../../hooks/kpi";
import { formatNumber } from "../../helpers/formatter";
import SoftSelect from "../../components/SoftSelect";
import SearchIcon from '@mui/icons-material/Search';
import { UseSelectedWorkspace } from "../../hooks/company";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FORM_OPTIONS_ANCHOR } from "../../components/WorkspaceKpi/form/formOptions";
import toast from "react-hot-toast";

const LevelUp = () => {
  const workspace = UseSelectedWorkspace();
  const [popUp, setPopUp] = useState({ open: false, index: 0, badgesCopy: [] });
  const [saving, setSaving] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [openPreview, setOpenPreview] = useState({
    open: false,
    id: null,
    status: 0,
    template: null,
    add: null,
    onAdd: null,
  });
  const [selectedItem, setSelectedItem] = useState({});
  const [levelsData, setLevelsData] = useState(dataLevels);
  const [editPopUp, setEditPopUp] = useState({ open: false, index: 0 });
  const [board, setBoard] = useState({
    columns: [],
  });
  const dispatch = useDispatch();
  const user = UseUserDetails();
  const isAdmin = UseIsAdmin();
  const { state } = useLocation();
  const formRef = useRef(null);

  const { isLoading, masterBadges } = useSelector(
    (state) => state.masterBadgeReducer,
  );

  useEffect(() => {
    dispatch(getMasterBadges());
  }, []);

  const adder = async () => {
    if (board.columns.length < 10) {
      const newLevel = {
        id: uuidv4(),
        title: `Level ${board.columns.length + 1}`,
        levelName: dataLevels[board.columns.length].lvlName,
        levelInfo: dataLevels[board.columns.length].lvlInfo,
        cards: [],
      };
  
      try {
        const result = await CREATE_LEVEL({
          groupId: workspace._id,
          name: newLevel.title,
          levelName: newLevel.levelName,
          levelInfo: newLevel.levelInfo,
          badges: [],
        });
  
        if (result && result.data) {
          newLevel.id = result.data._id;
          setBoard(prevBoard => ({
            columns: [...prevBoard.columns, newLevel],
          }));
        } else {
          throw new Error("Failed to create new level");
        }
      } catch (error) {
        console.error("Error creating new level:", error);
      }
    }
  };

  const fetchLevels = async () => {
    setFetching(true);
    try {
      const { data } = await GET_LEVEL(workspace._id, true);
      if (isAdmin || user.isAdmin) {
        const result = data.map((el) => {
          const cards = el.badges.map((badge) => ({
            id: badge._id,
            template: { ...badge },
          }));

          const item = {
            id: el._id,
            title: el.name,
            levelName: el.levelName,
            levelInfo: el.levelInfo,
            cards,
          };

          return item;
        });
        setBoard({ columns: result });
      } else {
        let result = data.map(async (el) => {
          let badges = [];
          for (const curr of el.badges) {
            let status = 0;
            const response = await GET_USER_BADGE({
              userId: user._id,
              badgeId: curr._id,
            });

            if (response.data) {
              status = response.data.status;
            }
            badges.push({
              template: { ...curr },
              id: curr._id,
              status,
            });
          }
          const item = {
            id: el._id,
            title: el.name,
            levelName: el.levelName,
            levelInfo: el.levelInfo,
            cards: badges,
          };

          return item;
        });
        result = await Promise.all(result);
        setBoard({ columns: result });
      }
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchLevels();
  }, []);

  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const textBp = useMediaQuery((theme) => theme.breakpoints.up("xs"));
  const NavTabs = [{ label: "Levels" }, { label: "Badges" }];
  const [badgeDialog, setBadgeDialog] = useState(false);
  const [badgeMetricDialog, setBadgeMetricDialog] = useState(false);

  const openBadgeDialog = () => setBadgeDialog(true);
  const closeBadgeDialog = () => {
    setBadgeDialog(false);
    reset();
    setSelectedItem(null);
    setImageSrc("");
  };

  const openBadgeMetricDialog = () => setBadgeMetricDialog(true);
  const closeBadgeMetricDialog = () => {
    setBadgeMetricDialog(false);
    reset();
    setSelectedItem(null);
    setImageSrc("");
  };

  const handleDeleteLevel = async (levelId) => {
    try {
      await DELETE_LEVEL(levelId);
      const updatedColumns = board.columns.filter(column => column.id !== levelId);
      setBoard({ columns: updatedColumns });
    } catch (error) {
      console.error("Error deleting level:", error);
    }
  };

  const { register, handleSubmit, reset, setValue, getValues } = useForm({
    defaultValues: {
      image: "",
      name: "",
      description: "",
    },
  });

  const [isBadgeUploading, setIsBadgeUploading] = useState(false);
  const badgeImageInputRef = useRef();
  const [imageSrc, setImageSrc] = useState("");

  const handleBadgeUpload = () => {
    badgeImageInputRef.current.click();
  };

  const handleBadgeInputChange = async (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    const imgFile = e.target.files[0];
    if (imgFile) {
      const formData = new FormData();
      setIsBadgeUploading(true);
      formData.append("image", imgFile);
      PUT_WORKSPACE_IMAGE(formData)
        .then(({ data }) => {
          setImageSrc(data.key);
          setIsBadgeUploading(false);
          setOpenImageModal(false);
        })
        .catch((err) => {
          console.log("Badge image upload error", err.message);
          setIsBadgeUploading(false);
        });
    }
    badgeImageInputRef.current.value = null;
  };

  const handleEditBadge = (badge) => {
    setImageSrc(badge.image);
    setValue("name", badge.title);
    setValue("description", badge.description);
    setSelectedItem(badge);
    setBadgeDialog(true);
  };

  const [ifKpiId, setIfKpiId] = useState(false);

  const createBadge = async () => {
    const nameValue = getValues("name");
    const targetValue = getValues("target");
    const kpiId = getValues("metric");
    const descriptionValue = getValues("description");
    setIfKpiId(kpiId);
    if (formRef.current) {
      try {
        const body = {
          groupId: workspace._id,
          title: nameValue,
          description: descriptionValue,
          image: imageSrc,
          kpiId: kpiId,
          submissionType: FORM_OPTIONS_ANCHOR[formIdx]?.name,
          target: targetValue == 'custom' ? customTargetValue : targetValue,
        };
        if (selectedItem?._id) {
          body.id = selectedItem._id;
          await SUBMIT_BADGE(body);
        } else {
          await SUBMIT_BADGE(body);
        }
        closeBadgeDialog();
        closeBadgeMetricDialog();
        setActiveStep(0);
        reset();
        setSelectedItem(null);
        setImageSrc("");
        dispatch(getMasterBadges());
        if (selectedItem?._id) {
          toast.success(`Badge Updated Successfully!`);
        } else {
          setTimeout(() => setSuccessDialog(true), 500);
        }
      } catch (error) {
        console.error("Error saving badge:", error);
      }
    }
  };

  const triggerSave = async () => {
    setSaving(true);
    try {
      if (board.columns.length) {
        await Promise.all(
          board.columns.map((item) => {
            const badges = item.cards.map((card) => ({ _id: card.id }));
            const data = {
              name: item.title,
              levelName: item.levelName,
              levelInfo: item.levelInfo,
              badges,
            };
            return CREATE_LEVEL(data);
          }),
        );
      }
    } catch (err) {
      console.log(err.response);
    }
    setSaving(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateWithMetrics = () => {
    openBadgeMetricDialog();
    handleClose();
  };

  const handleCreateWithItemCover = () => {
    openBadgeDialog();
    handleClose();
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const groupKpis = UseGroupKpis();
  const userKpis = UseMyKpis();
  const [dataSourceOneOptions, setDataSourceOneOptions] = useState([]);

  useEffect(() => {
    if (groupKpis && userKpis) {
      const options = groupKpis.map((kpi) => ({
        value: kpi._id,
        label: `${kpi.title} - ${formatNumber(userKpis?.month[kpi._id]?.amt ?? 0)}`,
      }));
      setDataSourceOneOptions(options);
    }
  }, [groupKpis, userKpis]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedKpiId, setSelectedKpiId] = useState(null);
  const [isAllTabSelected, setIsAllTabSelected] = useState(true);

  const handleKpiTabChange = (event, newValue) => {
    if (newValue === 0) {
      setIsAllTabSelected(true);
      setSelectedKpiId(null);
    } else {
      setIsAllTabSelected(false);
      const kpiId = Array.from(new Set(masterBadges.filter(badge => badge.kpiId).map(badge => badge.kpiId._id)))[newValue - 1];
      setSelectedKpiId(kpiId.toString());
    }
  };

  const filteredBadges = masterBadges.filter(badge => {
    return (
      (isAllTabSelected || (badge.kpiId && badge.kpiId._id.toString() === selectedKpiId)) &&
      (!searchTerm || badge.title.toLowerCase().includes(searchTerm.toLowerCase())) &&
      badge.groupId && badge.groupId === workspace._id
    );
  });

  const [openImageModal, setOpenImageModal] = useState(false);

  const handleOpenImageModal = () => {
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleSelectMasterBadgeImage = (image) => {
    setImageSrc(image);
    handleCloseImageModal();
  };

  const [selectedMetricValue, setSelectedMetricValue] = useState(null);
  const [targetOptions, setTargetOptions] = useState([]);
  const [showCustomTargetInput, setShowCustomTargetInput] = useState(false);
  const [customTargetValue, setCustomTargetValue] = useState("");

  const handleMetricSelection = (option) => {
    const selectedKpi = groupKpis.find(kpi => kpi._id === option.value);
    if (selectedKpi) {
      const target = selectedKpi.target || 1;
      const targetOptions = [
        ...Array.from({ length: 5 }, (_, index) => {
          const multiplier = index + 1;
          const targetValue = target * multiplier;
          return {
            value: `${formatNumber(targetValue)}`,
            label: `${formatNumber(targetValue)}`,
          };
        }),
        { value: 'custom', label: 'Custom' }
      ];
      setSelectedMetricValue(target);
      setTargetOptions(targetOptions);
    }
  };

  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [badgeToDelete, setBadgeToDelete] = useState(null);

  const handleDeleteBadgeClick = (badgeId) => {
    setBadgeToDelete(badgeId);
    setTimeout(() => {
      setDeleteConfirmDialog(true);
    }, 500);
    closeBadgeDialog();
  };

  const confirmDeleteBadge = async () => {
    try {
      await DELETE_BADGE(badgeToDelete);
      dispatch(getMasterBadges());
      setDeleteConfirmDialog(false);
    } catch (error) {
      console.error("Error deleting badge:", error);
    }
  };

  const [successDialog, setSuccessDialog] = useState(false);
  useEffect(() => {
    if (!successDialog) {
      setIfKpiId(false);
    }
  }, [successDialog]);

  const [anchorEl02, setAnchorEl02] = useState(null);
  const open02 = Boolean(anchorEl02);

  const handleMoreClick = (event) => {
    setAnchorEl02(event.currentTarget);
    event.stopPropagation();
  };

  const handleMoreClose = () => {
    setAnchorEl02(null);
  };

  const [formIdx, setFormIdx] = useState(0);

  const handleFormSelect = (idx) => {
    setFormIdx(idx);
  };

  return (
    <>
      {popUp.open && (
        <AddBadgePopup
          popUp={popUp}
          setPopUp={setPopUp}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          board={board}
          setBoard={setBoard}
          isLoading={isLoading}
          masterBadges={masterBadges}
          openPreview={openPreview}
          setOpenPreview={setOpenPreview}
          triggerSave={triggerSave} // Add this line
        />
      )}
      {openPreview.open && (
        <BadgeRequirementPreview
          user={user}
          openPreview={openPreview}
          setOpenPreview={setOpenPreview}
          board={board}
          setBoard={setBoard}
          isAdmin={isAdmin}
          setPopUp={setPopUp}
          triggerSave={triggerSave}
          dispatch={dispatch}
          getMasterBadges={getMasterBadges}
        />
      )}
      {editPopUp.open && (
      <EditBadgeLevel
        editPopUp={editPopUp}
        setEditPopUp={setEditPopUp}
        dataLevels={levelsData}
        setLevelsData={setLevelsData}
        board={board}
        setBoard={setBoard}
        onDeleteLevel={handleDeleteLevel}
      />
    )}
      {state && state.showPopup && (
        <CongratsPopup item={state.item} showPopup={state.showPopup} />
      )}
      {state && state.showPopup && <ConfettiFireworks />}

      <SoftBox
        sx={{
          height: { mini: "101.25px", xs: "109.25px", md: "75px" },
        }}
      />
      <Grid item mini={12} className="p-4" sx={{marginTop: "-3rem"}}>
        <SoftBox
          sx={{
            margin: "1.5rem 0rem 1rem",
            borderRadius: "16px",
            height: { mini: "max-content", md: "202px" },
            backgroundImage: `url("${LevelUpNavBg}")`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            padding: { mini: "1rem", md: "1.5rem" },
          }}
        >
          <Grid container spacing={2} height="100%" marginTop={0}>
            <Grid
              item
              md={9}
              lg={6}
              height="100%"
              paddingTop="0 !important"
            >
              <SoftBox sx={{display: "flex", justifyContent: "flex-end", position: "absolute", right: "15%", textAlign: "end"}}>
                <img src={levelUpranking} alt="levelUp" className="w-[80%]" sx={{marginTop: "-3rem"}}/>
              </SoftBox>
              <SoftBox height="100%" display="flex" flexDirection="column">
                <SoftTypography
                  variant={textBp ? "h4" : "h5"}
                  sx={{
                    color: "#F9FAFB",
                    fontSize: "24px",
                    fontWeight: "500",
                    lineHeight: "20.6px",
                    marginTop: "1rem"
                  }}
                >
                  Level Up and Badge managment
                </SoftTypography>
                <SoftTypography
                  variant={textBp ? "button" : "caption"}
                  sx={{
                    color: "#F2F4F7",
                    fontWeight: 400,
                    fontSize: "16px",
                  }}
                >
                  Create, manage, and track achievement badges
                </SoftTypography>
                <SoftBox
                  flexGrow={1}
                  alignItems="end"
                  sx={{ display: { mini: "none", md: "flex" } }}
                >
                  <Tabs
                    orientation="horizontal"
                    value={tabValue}
                    onChange={(event, newValue) => setTabValue(newValue)}
                    sx={{
                      background: "rgba(249, 249, 255, 0.80)",
                      borderRadius: "10px",
                      padding: "8px",
                      boxShadow: "0px 4px 7px -1px rgba(0, 0, 0, 0.12)",
                      width: "max-content",
                      "& .MuiTabs-indicator": {
                        borderRadius: "0.5rem",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {NavTabs.map((nav, index) => (
                      <Tab
                        label={nav.label}
                        icon={nav.icon}
                        iconPosition="start"
                        sx={{
                          color: "#343A40",
                          fontSize: "0.85rem",
                        }}
                      />
                    ))}
                  </Tabs>
                </SoftBox>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
      </Grid>
      <Grid item mini={12} className="p-4" sx={{marginTop: "-2rem"}}>
        <FeedTabPanel value={tabValue} index={0}>
          <SoftBox 
              className="p-4"
              sx={{
                  background: "#fff",
                  borderRadius: "12px",
                }}>
            <div className="mb-2 pb-4 flex justify-between" style={{borderBottom: "1px solid #EAECF0"}}>
              <SoftBox>
                <SoftTypography variant="h3" className="text-black" style={{fontSize: "20px", lineHeight: "20.6px", fontWeight: "bold", marginTop: "0.25rem"}}>
                  Gameboard Levels
                </SoftTypography>
              </SoftBox>
              {isAdmin ? (
                <SoftBox>
                  <SoftButton
                    color="primary"
                    variant="gradient"
                    onClick={() => {
                      adder();
                    }}
                    disabled={saving}
                    loading={saving}
                    sx={{
                      textTransform: "capitalize",
                      borderRadius: "4px",
                      width: "150px"
                    }}
                  >
                    Create a level
                  </SoftButton>
                </SoftBox>
              ) : null}
            </div>
            <Grid container spacing={2} style={{marginTop: "2rem"}}>
              {board.columns.map((column, index) => (
                <Grid item xs={12} sm={6} lg={3} key={column.id}>
                  <SoftBox
                    sx={{
                      backgroundColor: "#FCFCFD",
                      border: "1px solid #EAECF0",
                      borderRadius: "8px",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* Column Header */}
                    <SoftBox>
                      <SoftBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center">
                        <img 
                          src={index === 0 ? levelOneBG : index === 1 ? levelTwoBG : levelThreeBG} 
                          alt="card-header" 
                          style={{ width: '100%' }}
                        />
                      </SoftBox>
                      <SoftBox display="flex" flexDirection="column" sx={{padding: "20px"}}>
                        <SoftBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          sx={{marginTop: "-4.2rem"}}
                          >
                          <img 
                            src={index === 0 ? levelOne : index === 1 ? levelTwo : levelThree} 
                            alt={`level ${index + 1}`} 
                            style={{ width: '20%' }}
                            onClick={() => {
                              if (isAdmin || user.isAdmin) {
                                setEditPopUp({
                                  open: true,
                                  index,
                                });
                              }
                            }}
                          />
                        </SoftBox>
                        <SoftBox sx={{marginTop: "1rem", marginBottom: "1rem", textAlign: "center"}}>
                          <SoftTypography variant="h6" sx={{fontSize: "20px", color: "#344054", fontWeight: "bold"}}>{column.levelName}</SoftTypography>
                          <SoftTypography variant="p" sx={{fontSize: "14px", color: "#667085", lineHeight: "18px", fontWeight: "400"}}>
                            {isAdmin ? (
                              <>Give your players goals they'll chase: <br/>Add Custom Badges!</>
                            ) : (
                              <>Complete these badge challenges to <br/>Earn Rewards!</>
                            )}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                      {isAdmin ? (
                        <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          mb={3}
                          sx={{background: "#F2F4F7", padding: "10px 20px"}}
                        >
                          <SoftTypography variant="h6" sx={{fontSize: "12px", color: "#1D2939 !important"}}>
                            TASK / BADGES LIST
                          </SoftTypography>
                          <SoftButton
                            size="small"
                            sx={{boxShadow: "none !important"}}
                            onClick={() => {
                              let badgesCopy = [...masterBadges];
                              badgesCopy = badgesCopy.filter(
                                (item) =>
                                  !column.cards.find(
                                    (card) => card.id === item._id,
                                  ),
                              );
                              setPopUp({
                                index,
                                open: true,
                                badgesCopy,
                              });
                            }}
                          >
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="12" height="12" rx="6" fill="url(#paint0_linear_226_6718)"/>
                                <path d="M8.625 6.37427H6.375V8.62427H5.625V6.37427H3.375V5.62427H5.625V3.37427H6.375V5.62427H8.625V6.37427Z" fill="#FCFCFD"/>
                                <defs>
                                <linearGradient id="paint0_linear_226_6718" x1="9.74417" y1="47.5817" x2="-2.96553" y2="46.7759" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#7928CA"/>
                                <stop offset="1" stopColor="#FF0080"/>
                                </linearGradient>
                                </defs>
                              </svg>&nbsp;&nbsp;
                              <SoftTypography variant="p" sx={{fontSize: "12px", color: "#667085"}}>
                                Add a Badge
                              </SoftTypography>
                          </SoftButton>
                        </SoftBox>
                      ) : (
                        <SoftBox mb={3} sx={{background: "#F2F4F7", padding: "10px 20px"}}>
                          <SoftTypography variant="h6" sx={{fontSize: "12px", color: "#1D2939 !important"}}>
                            TASK / BADGES LIST
                          </SoftTypography>
                        </SoftBox>
                      )}
                    </SoftBox>

                    {/* Column Cards */}
                    <SoftBox sx={{ flexGrow: 1, overflowY: 'auto', padding: "0 20px", marginTop: "-.5rem", maxHeight: "150px" }}>
                      {column.cards.map((card) => (
                        <SoftBox
                          key={card.id}
                          sx={{
                            marginBottom: "10px",
                            backgroundColor: "#F2F4F7",
                            borderRadius: "md",
                            padding: "15px",
                          }}
                        >
                          <div
                            className="flex items-center justify-between cursor-pointer"
                            onClick={() =>
                              setOpenPreview({
                                open: true,
                                id: card.id,
                                template: card.template,
                                status: card.status,
                                add: false,
                                onAdd: null,
                              })
                            }
                          >
                            <div className="flex items-center">
                              <SoftAvatar
                                src={GET_IMAGE(card.template.image)}
                                className={card.status < 1 ? "grayscale-image" : ""}
                                alt={card.template.title}
                                size="xs"
                                sx={{
                                  ml: -1,
                                }}
                              />
                              <div className="ml-2" />
                              <SoftTypography
                                component="p"
                                className="capitalize"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  margin: "0px !important",
                                  padding: "0px !important",
                                  color: "#172B4D",
                                }}
                              >
                                {card.template.title}
                              </SoftTypography>
                            </div>
                            {!isAdmin && !card.template.submissionType && (
                              <SoftBox sx={{width: '50%'}}>
                                <LinearProgress
                                  variant="determinate"
                                  sx={{
                                    width: '100%',
                                    height: '10px',
                                    borderRadius: '15px',
                                    backgroundColor: '#38374B',
                                    '& .MuiLinearProgress-bar': {
                                      borderRadius: '15px',
                                      marginTop: '1.8px',
                                      backgroundColor: '#8A69FF',
                                      height: '10px',
                                      width: `${card.status * 100}%`,
                                    },
                                  }}
                                />
                              </SoftBox>
                            )}
                            {!isAdmin && card.template.submissionType && (
                              <SoftBox sx={{width: '50%', display: 'flex', justifyContent: 'flex-end'}}>
                                <SoftTypography variant="p" sx={{fontSize: "12px", color: "#667085"}}>
                                  {card.status === 1 ? "Completed" : card.status === 2 ? "Proof Sent" : "Pending"}
                                </SoftTypography>
                              </SoftBox>
                            )}
                          </div>
                        </SoftBox>
                      ))}
                    </SoftBox>
                  </SoftBox>
                </Grid>
              ))}
            </Grid>
          </SoftBox>
        </FeedTabPanel>
        <FeedTabPanel value={tabValue} index={1}>
          <SoftBox 
              className="p-4"
              sx={{
                  background: "#fff",
                  borderRadius: "12px",
                }}>
            <div className="mb-2 pb-4 flex justify-between" style={{borderBottom: "1px solid #EAECF0"}}>
              <SoftBox>
                <SoftTypography variant="h3" className="text-black" style={{fontSize: "20px", lineHeight: "20.6px", fontWeight: "bold", marginTop: "0.25rem"}}>
                  All Badges available for your team
                </SoftTypography>
                <SoftBox
                  mt="1rem"
                  flexGrow={1}
                  alignItems="end"
                  sx={{ display: { mini: "none", md: "flex" } }}
                >
                  <Tabs
                    orientation="horizontal"
                    value={isAllTabSelected ? 0 : Array.from(new Set(masterBadges.filter(badge => badge.kpiId).map(badge => badge.kpiId._id))).findIndex(id => id.toString() === selectedKpiId) + 1}
                    onChange={handleKpiTabChange}
                    sx={{
                      background: "rgba(249, 249, 255, 0.80)",
                      borderRadius: "4px",
                      padding: "8px",
                      boxShadow: "0px 4px 7px -1px rgba(0, 0, 0, 0.12)",
                      width: "max-content",
                      "& .MuiTabs-indicator": {
                        borderRadius: "4px",
                        boxShadow: "none",
                      },
                    }}
                  >
                    <Tab
                      label="All"
                      sx={{
                        color: "#343A40",
                        fontSize: "0.85rem",
                      }}
                    />
                    {Array.from(new Set(masterBadges.filter(badge => 
                      badge.kpiId && 
                      badge.groupId && 
                      badge.groupId === workspace._id
                    ).map(badge => badge.kpiId._id)))
                      .map((id) => {
                        const badge = masterBadges.find(badge => 
                          badge.kpiId && 
                          badge.kpiId._id === id && 
                          badge.groupId && 
                          badge.groupId === workspace._id
                        );
                        return (
                          <Tab
                            key={id.toString()}
                            label={badge.kpiId.name}
                            sx={{
                              color: "#343A40", 
                              fontSize: "0.85rem",
                              textTransform: "capitalize",
                            }}
                          />
                        );
                      })}
                  </Tabs>
                </SoftBox>
              </SoftBox>
              {isAdmin ? (
                <SoftBox display="flex" alignItems="center">
                  <SoftInput
                    placeholder="Search badges..."
                    icon={{
                      component: <SearchIcon />,
                      direction: "left",
                    }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{
                      width: "400px",
                      "& .MuiInputBase-root": {
                        height: "40px",
                      },
                    }}
                  />
                  <SoftButton
                    onClick={handleClick}
                    color="primary"
                    variant="gradient"
                    sx={{
                      marginLeft: "1rem",
                      textTransform: "capitalize",
                      borderRadius: "4px",
                      width: "150px"
                    }}
                  >
                    Create a Badge
                  </SoftButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleCreateWithMetrics}>Create with Metrics</MenuItem>
                    <MenuItem onClick={handleCreateWithItemCover}>Create Merit Badge</MenuItem>
                  </Menu>
                </SoftBox>
              ) : null}
              <Dialog
                open={badgeDialog}
                onClose={closeBadgeDialog}
                sx={{
                  ".MuiPaper-root": {
                    borderRadius: "8px",
                    background:
                      "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
                    boxShadow:
                      "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
                    backdropFilter: "blur(25px)",
                    width: "100%",
                    maxWidth: "700px",
                    borderWidth: "1px",
                    borderColor: "#FFF",
                  },
                }}
              >
                <form ref={formRef}>
                  <DialogTitle sx={{ color: "#FFF", padding: { mini: "1rem", md: "1.5rem 1.7rem" } }}>
                    <SoftTypography
                      variant="h4"
                      sx={{ color: "#FFF", fontSize: "20px", marginBottom: "-2rem !important" }}
                    >
                      {selectedItem?._id ? "Update Badge" : "Create Merit Badge"}
                    </SoftTypography>
                  </DialogTitle>
                  <Divider
                    sx={{
                      backgroundColor: "#FFF",
                    }}
                  />
                  <DialogContent sx={{padding: { mini: "1rem", md: "1.5rem 1.7rem" }, marginTop: "-1rem !important"}}>
                      <>
                      <SoftBox>
                        <Grid item mini={12} marginBottom={2}>
                          <SoftBox
                            sx={{
                              marginBottom: "0.25rem",
                            }}
                          >
                            <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                              Badge Name
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            {...register("name")}
                            name="name"
                            type="text"
                            size="large"
                            sx={{
                              border: "1px solid rgba(233, 236, 239, 0.10)",
                              backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                              color: "#ffffffb3 !important",
                              "& .MuiInputBase-input::placeholder": {
                                color: "white !important",
                                opacity: "0.7",
                              },
                              "&.Mui-focused": {
                                borderColor: "#E9ECEF",
                                boxShadow: `0rem 0rem 0rem 0.125rem #E9ECEF`,
                              },
                            }}
                          />
                        </Grid>
                        <Grid item mini={12} marginBottom={2}>
                          <SoftBox
                            sx={{
                              marginBottom: "0.25rem",
                            }}
                          > 
                            <SoftBox
                              sx={{
                                marginBottom: "-0.25rem",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <SoftTypography variant="button" sx={{ color: "#E9ECEF", marginRight: "0.5rem" }}>
                                Description 
                              </SoftTypography>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.99992 1.33333C11.6819 1.33333 14.6666 4.31799 14.6666 7.99999C14.6666 11.682 11.6819 14.6667 7.99992 14.6667C4.31792 14.6667 1.33325 11.682 1.33325 7.99999C1.33325 4.31799 4.31792 1.33333 7.99992 1.33333ZM7.99992 2.66666C6.58543 2.66666 5.22888 3.22856 4.22868 4.22876C3.22849 5.22895 2.66659 6.58551 2.66659 7.99999C2.66659 9.41448 3.22849 10.771 4.22868 11.7712C5.22888 12.7714 6.58543 13.3333 7.99992 13.3333C9.41441 13.3333 10.771 12.7714 11.7712 11.7712C12.7713 10.771 13.3333 9.41448 13.3333 7.99999C13.3333 6.58551 12.7713 5.22895 11.7712 4.22876C10.771 3.22856 9.41441 2.66666 7.99992 2.66666ZM7.99325 6.66666C8.36525 6.66666 8.66658 6.96799 8.66658 7.33999V10.756C8.79367 10.8294 8.89299 10.9426 8.94915 11.0782C9.0053 11.2138 9.01515 11.3641 8.97717 11.5059C8.93919 11.6476 8.8555 11.7729 8.73907 11.8622C8.62265 11.9516 8.48 12 8.33325 12H8.00659C7.91816 12 7.8306 11.9826 7.74891 11.9487C7.66722 11.9149 7.59299 11.8653 7.53047 11.8028C7.46794 11.7403 7.41834 11.666 7.38451 11.5843C7.35067 11.5026 7.33325 11.4151 7.33325 11.3267V7.99999C7.15644 7.99999 6.98687 7.92976 6.86185 7.80473C6.73682 7.67971 6.66658 7.51014 6.66658 7.33333C6.66658 7.15652 6.73682 6.98695 6.86185 6.86192C6.98687 6.7369 7.15644 6.66666 7.33325 6.66666H7.99325ZM7.99992 4.66666C8.17673 4.66666 8.3463 4.7369 8.47132 4.86192C8.59635 4.98695 8.66658 5.15652 8.66658 5.33333C8.66658 5.51014 8.59635 5.67971 8.47132 5.80473C8.3463 5.92976 8.17673 5.99999 7.99992 5.99999C7.82311 5.99999 7.65354 5.92976 7.52851 5.80473C7.40349 5.67971 7.33325 5.51014 7.33325 5.33333C7.33325 5.15652 7.40349 4.98695 7.52851 4.86192C7.65354 4.7369 7.82311 4.66666 7.99992 4.66666Z" fill="#667085"/>
                              </svg>
                            </SoftBox>
                            <SoftTypography variant="button" sx={{ color: "#E9ECEF !important", fontSize: "11px", fontWeight: "400" }}>
                              A description for players to understand what they are to do 
                            </SoftTypography>
                          </SoftBox>
                          <SoftInput
                            {...register("description")}
                            name="description"
                            type="textarea"
                            size="large"
                            sx={{
                              border: "1px solid rgba(233, 236, 239, 0.10)",
                              backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                              color: "#ffffffb3 !important",
                              "& .MuiInputBase-input::placeholder": {
                                color: "white !important",
                                opacity: "0.7",
                              },
                              "&.Mui-focused": {
                                borderColor: "#E9ECEF",
                                boxShadow: `0rem 0rem 0rem 0.125rem #E9ECEF`,
                              },
                            }}
                          />
                        </Grid>
                        {!selectedItem?.kpiId ? (
                        <Grid item mini={12} marginBottom={2}>
                          <SoftBox
                            sx={{
                              marginBottom: "0.25rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <SoftTypography variant="button" sx={{ color: "#E9ECEF", marginRight: "0.5rem" }}>
                              Submission Type 
                            </SoftTypography>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.99992 1.33333C11.6819 1.33333 14.6666 4.31799 14.6666 7.99999C14.6666 11.682 11.6819 14.6667 7.99992 14.6667C4.31792 14.6667 1.33325 11.682 1.33325 7.99999C1.33325 4.31799 4.31792 1.33333 7.99992 1.33333ZM7.99992 2.66666C6.58543 2.66666 5.22888 3.22856 4.22868 4.22876C3.22849 5.22895 2.66659 6.58551 2.66659 7.99999C2.66659 9.41448 3.22849 10.771 4.22868 11.7712C5.22888 12.7714 6.58543 13.3333 7.99992 13.3333C9.41441 13.3333 10.771 12.7714 11.7712 11.7712C12.7713 10.771 13.3333 9.41448 13.3333 7.99999C13.3333 6.58551 12.7713 5.22895 11.7712 4.22876C10.771 3.22856 9.41441 2.66666 7.99992 2.66666ZM7.99325 6.66666C8.36525 6.66666 8.66658 6.96799 8.66658 7.33999V10.756C8.79367 10.8294 8.89299 10.9426 8.94915 11.0782C9.0053 11.2138 9.01515 11.3641 8.97717 11.5059C8.93919 11.6476 8.8555 11.7729 8.73907 11.8622C8.62265 11.9516 8.48 12 8.33325 12H8.00659C7.91816 12 7.8306 11.9826 7.74891 11.9487C7.66722 11.9149 7.59299 11.8653 7.53047 11.8028C7.46794 11.7403 7.41834 11.666 7.38451 11.5843C7.35067 11.5026 7.33325 11.4151 7.33325 11.3267V7.99999C7.15644 7.99999 6.98687 7.92976 6.86185 7.80473C6.73682 7.67971 6.66658 7.51014 6.66658 7.33333C6.66658 7.15652 6.73682 6.98695 6.86185 6.86192C6.98687 6.7369 7.15644 6.66666 7.33325 6.66666H7.99325ZM7.99992 4.66666C8.17673 4.66666 8.3463 4.7369 8.47132 4.86192C8.59635 4.98695 8.66658 5.15652 8.66658 5.33333C8.66658 5.51014 8.59635 5.67971 8.47132 5.80473C8.3463 5.92976 8.17673 5.99999 7.99992 5.99999C7.82311 5.99999 7.65354 5.92976 7.52851 5.80473C7.40349 5.67971 7.33325 5.51014 7.33325 5.33333C7.33325 5.15652 7.40349 4.98695 7.52851 4.86192C7.65354 4.7369 7.82311 4.66666 7.99992 4.66666Z" fill="#667085"/>
                            </svg>
                          </SoftBox>
                          <SoftBox display="flex" justifyContent="space-between" gap="0.5rem">
                            {[FORM_OPTIONS_ANCHOR[0], FORM_OPTIONS_ANCHOR[1], FORM_OPTIONS_ANCHOR[3]].map((formType, index) => (
                              <SoftButton
                                key={index}
                                onClick={() => handleFormSelect(index)}
                                sx={{
                                  width: "33%",
                                  background: "rgba(255, 255, 255, 0.10)",
                                  border: formIdx === index
                                    ? `1px solid #E9ECEF`
                                    : "1px solid rgba(233, 236, 239, 0.10)",
                                  borderRadius: "8px",
                                  padding: "0.5rem",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  textAlign: "start",
                                  boxShadow: formIdx === index
                                    ? `0rem 0rem 0rem 0.1rem #E9ECEF`
                                    : "none",
                                  "&:hover": {
                                    borderColor: '#E9ECEF',
                                    background: "rgba(255, 255, 255, 0.10)",
                                    boxShadow: `0rem 0rem 0rem 0.1rem #E9ECEF`,
                                  },
                                  "&:focus:not(:hover)": {
                                    borderColor: '#E9ECEF',
                                    background: "rgba(255, 255, 255, 0.10)",
                                    boxShadow: `0rem 0rem 0rem 0.1rem #E9ECEF`,
                                  },
                                }}
                              >
                                <SoftBox
                                  display="flex"
                                  gap="0.15rem"
                                  alignItems="center"
                                  marginBottom="0.25rem"
                                  width="100%"
                                >
                                  {formType.icon}
                                  <SoftTypography variant="button" sx={{ color: "#CED4DA" }}>
                                    {formType.name}
                                  </SoftTypography>
                                </SoftBox>
                                <SoftTypography
                                  variant="caption"
                                  sx={{ color: "#CED4DA", marginLeft: "0.25rem" }}
                                >
                                  {formType.desc}
                                </SoftTypography>
                              </SoftButton>
                            ))}
                          </SoftBox>
                        </Grid>
                        ) : null}
                        <Grid item mini={12} marginBottom={2}>
                          <SoftBox
                            sx={{
                              marginBottom: "0.25rem",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <SoftTypography variant="button" sx={{ color: "#E9ECEF", marginRight: "0.5rem" }}>
                              Badge Icon 
                            </SoftTypography>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M7.99992 1.33333C11.6819 1.33333 14.6666 4.31799 14.6666 7.99999C14.6666 11.682 11.6819 14.6667 7.99992 14.6667C4.31792 14.6667 1.33325 11.682 1.33325 7.99999C1.33325 4.31799 4.31792 1.33333 7.99992 1.33333ZM7.99992 2.66666C6.58543 2.66666 5.22888 3.22856 4.22868 4.22876C3.22849 5.22895 2.66659 6.58551 2.66659 7.99999C2.66659 9.41448 3.22849 10.771 4.22868 11.7712C5.22888 12.7714 6.58543 13.3333 7.99992 13.3333C9.41441 13.3333 10.771 12.7714 11.7712 11.7712C12.7713 10.771 13.3333 9.41448 13.3333 7.99999C13.3333 6.58551 12.7713 5.22895 11.7712 4.22876C10.771 3.22856 9.41441 2.66666 7.99992 2.66666ZM7.99325 6.66666C8.36525 6.66666 8.66658 6.96799 8.66658 7.33999V10.756C8.79367 10.8294 8.89299 10.9426 8.94915 11.0782C9.0053 11.2138 9.01515 11.3641 8.97717 11.5059C8.93919 11.6476 8.8555 11.7729 8.73907 11.8622C8.62265 11.9516 8.48 12 8.33325 12H8.00659C7.91816 12 7.8306 11.9826 7.74891 11.9487C7.66722 11.9149 7.59299 11.8653 7.53047 11.8028C7.46794 11.7403 7.41834 11.666 7.38451 11.5843C7.35067 11.5026 7.33325 11.4151 7.33325 11.3267V7.99999C7.15644 7.99999 6.98687 7.92976 6.86185 7.80473C6.73682 7.67971 6.66658 7.51014 6.66658 7.33333C6.66658 7.15652 6.73682 6.98695 6.86185 6.86192C6.98687 6.7369 7.15644 6.66666 7.33325 6.66666H7.99325ZM7.99992 4.66666C8.17673 4.66666 8.3463 4.7369 8.47132 4.86192C8.59635 4.98695 8.66658 5.15652 8.66658 5.33333C8.66658 5.51014 8.59635 5.67971 8.47132 5.80473C8.3463 5.92976 8.17673 5.99999 7.99992 5.99999C7.82311 5.99999 7.65354 5.92976 7.52851 5.80473C7.40349 5.67971 7.33325 5.51014 7.33325 5.33333C7.33325 5.15652 7.40349 4.98695 7.52851 4.86192C7.65354 4.7369 7.82311 4.66666 7.99992 4.66666Z" fill="#667085"/>
                            </svg>
                          </SoftBox>
                          <SoftBox display="flex" justifyContent="space-between">
                            <SoftButton
                              color="outlined"
                              variant="contained"
                              onClick={handleOpenImageModal}
                              sx={{
                                textTransform: "none",
                                borderRadius: "4px",
                                border: "1px solid rgba(233, 236, 239, 0.20)",
                                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                                background: "rgba(255, 255, 255, 0.10)",
                                color: "#CED4DA",
                                width: "100%",
                                justifyContent: "flex-start",
                                fontWeight: "normal",
                                "&:hover": {
                                  background: "rgba(255, 255, 255, 0.10)",
                                },
                              }}
                            >
                              {imageSrc ? "Image selected" : "Choose from existing"}
                            </SoftButton>
                            <input
                              ref={badgeImageInputRef}
                              onChange={handleBadgeInputChange}
                              accept="image/*"
                              type="file"
                              hidden
                            />
                          </SoftBox>
                        </Grid>
                      </SoftBox>
                      </>
                  </DialogContent>
                  <DialogActions style={{background: "#2E2E2F"}}>
                    <SoftButton 
                      onClick={closeBadgeDialog}
                      color="primary"
                    >
                      Cancel
                    </SoftButton>
                    <SoftButton 
                      onClick={createBadge} 
                      color="white"
                      disabled={!imageSrc}
                    >
                      {selectedItem?._id ? "Update Badge" : "Create Badge"}
                    </SoftButton>
                  </DialogActions>
                </form>
              </Dialog>
              <Dialog
                open={badgeMetricDialog}
                onClose={closeBadgeMetricDialog}
                sx={{
                  ".MuiPaper-root": {
                    borderRadius: "8px",
                    background:
                      "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
                    boxShadow:
                      "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
                    backdropFilter: "blur(25px)",
                    width: "100%",
                    maxWidth: "700px",
                    borderWidth: "1px",
                    borderColor: "#FFF",
                  },
                }}
              >
                <form ref={formRef}>
                  <DialogTitle sx={{ color: "#FFF", padding: { mini: "1rem", md: "1.5rem 1.7rem" } }}>
                    <SoftTypography
                      variant="h4"
                      sx={{ color: "#FFF", fontSize: "20px", marginBottom: "-2rem !important" }}
                    >
                      {selectedItem?._id ? "Update Badge with Metric" : "Create a Badge with Metric"}
                    </SoftTypography>
                  </DialogTitle>
                  <Divider
                    sx={{
                      backgroundColor: "#FFF",
                    }}
                  />
                  <DialogContent sx={{padding: { mini: "1rem", md: "1.5rem 1.7rem" }, marginTop: "-1rem !important"}}>
                    <SoftBox>
                      {activeStep === 0 && (
                        <>
                          <Grid item mini={12} marginBottom={2}>
                            <SoftBox
                              sx={{
                                marginBottom: "0.25rem",
                              }}
                            >
                              <SoftTypography variant="button" sx={{ color: "#E9ECEF" }}>
                                Badge Name
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput
                              {...register("name")}
                              name="name"
                              type="text"
                              size="large"
                              sx={{
                                border: "1px solid rgba(233, 236, 239, 0.10)",
                                backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                                color: "#ffffffb3 !important",
                                "& .MuiInputBase-input::placeholder": {
                                  color: "white !important",
                                  opacity: "0.7",
                                },
                                "&.Mui-focused": {
                                  borderColor: "#E9ECEF",
                                  boxShadow: `0rem 0rem 0rem 0.125rem #E9ECEF`,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item mini={12} marginBottom={2}>
                            <SoftBox
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <SoftTypography variant="button" sx={{ color: "#E9ECEF", marginRight: "0.5rem" }}>
                                  Choose Metric for Badge 
                                </SoftTypography>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.99992 1.33333C11.6819 1.33333 14.6666 4.31799 14.6666 7.99999C14.6666 11.682 11.6819 14.6667 7.99992 14.6667C4.31792 14.6667 1.33325 11.682 1.33325 7.99999C1.33325 4.31799 4.31792 1.33333 7.99992 1.33333ZM7.99992 2.66666C6.58543 2.66666 5.22888 3.22856 4.22868 4.22876C3.22849 5.22895 2.66659 6.58551 2.66659 7.99999C2.66659 9.41448 3.22849 10.771 4.22868 11.7712C5.22888 12.7714 6.58543 13.3333 7.99992 13.3333C9.41441 13.3333 10.771 12.7714 11.7712 11.7712C12.7713 10.771 13.3333 9.41448 13.3333 7.99999C13.3333 6.58551 12.7713 5.22895 11.7712 4.22876C10.771 3.22856 9.41441 2.66666 7.99992 2.66666ZM7.99325 6.66666C8.36525 6.66666 8.66658 6.96799 8.66658 7.33999V10.756C8.79367 10.8294 8.89299 10.9426 8.94915 11.0782C9.0053 11.2138 9.01515 11.3641 8.97717 11.5059C8.93919 11.6476 8.8555 11.7729 8.73907 11.8622C8.62265 11.9516 8.48 12 8.33325 12H8.00659C7.91816 12 7.8306 11.9826 7.74891 11.9487C7.66722 11.9149 7.59299 11.8653 7.53047 11.8028C7.46794 11.7403 7.41834 11.666 7.38451 11.5843C7.35067 11.5026 7.33325 11.4151 7.33325 11.3267V7.99999C7.15644 7.99999 6.98687 7.92976 6.86185 7.80473C6.73682 7.67971 6.66658 7.51014 6.66658 7.33333C6.66658 7.15652 6.73682 6.98695 6.86185 6.86192C6.98687 6.7369 7.15644 6.66666 7.33325 6.66666H7.99325ZM7.99992 4.66666C8.17673 4.66666 8.3463 4.7369 8.47132 4.86192C8.59635 4.98695 8.66658 5.15652 8.66658 5.33333C8.66658 5.51014 8.59635 5.67971 8.47132 5.80473C8.3463 5.92976 8.17673 5.99999 7.99992 5.99999C7.82311 5.99999 7.65354 5.92976 7.52851 5.80473C7.40349 5.67971 7.33325 5.51014 7.33325 5.33333C7.33325 5.15652 7.40349 4.98695 7.52851 4.86192C7.65354 4.7369 7.82311 4.66666 7.99992 4.66666Z" fill="#667085"/>
                                </svg>
                              </SoftBox>
                              <SoftSelect
                                {...register("metric")}
                                name="metric"
                                options={dataSourceOneOptions}
                                onChange={(option) => {
                                  handleMetricSelection(option);
                                  setValue("metric", option.value);
                                }}
                                placeholder="Select a metric"
                                styles={{
                                  color: "white !important",
                                  width: "50%",
                                  height: "fit-content !important",
                                  placeholder: {
                                    color: "white !important",
                                    opacity: "0.7",
                                  },
                                  input: {
                                    color: "white !important",
                                    opacity: "0.7",
                                  },
                                  singleValue: {
                                    color: "white !important",
                                    opacity: "0.7",
                                  },
                                }}
                              />
                          </Grid>
                        </>
                      )}
                      {activeStep === 1 && (
                        <>
                          <Grid item mini={12} marginBottom={2}>
                            <SoftBox
                              sx={{
                                marginBottom: "0.25rem",
                              }}
                            > 
                              <SoftTypography variant="button" sx={{ color: "#E9ECEF", marginRight: "0.5rem" }}>
                                Target for the selected metrics 
                              </SoftTypography>
                            </SoftBox>
                            <SoftSelect
                              {...register("target")}
                              name="target"
                              options={targetOptions}
                              onChange={(option) => {
                                if (option.value === 'custom') {
                                  setShowCustomTargetInput(true);
                                } else {
                                  setValue("target", option.value)
                                  setShowCustomTargetInput(false);
                                  setCustomTargetValue("");
                                }
                              }}
                              placeholder="Select target value"
                              styles={{
                                color: "white !important",
                                height: "fit-content !important",	
                                placeholder: {
                                  color: "white !important",
                                  opacity: "0.7",
                                },
                                input: {
                                  color: "white !important",
                                  opacity: "0.7",
                                },
                                singleValue: {
                                  color: "white !important",
                                  opacity: "0.7",
                                },
                              }}
                            />
                            {showCustomTargetInput && (
                              <SoftInput
                                type="number"
                                value={customTargetValue}
                                onChange={(e) => setCustomTargetValue(e.target.value)}
                                placeholder="Enter custom target value"
                                sx={{
                                  marginTop: "1rem",
                                  border: "1px solid rgba(233, 236, 239, 0.10)",
                                  backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                                  color: "#ffffffb3 !important",
                                  "& .MuiInputBase-input::placeholder": {
                                    color: "white !important",
                                    opacity: "0.7",
                                  },
                                  "&.Mui-focused": {
                                    borderColor: "#E9ECEF",
                                    boxShadow: `0rem 0rem 0rem 0.125rem #E9ECEF`,
                                  },
                                }}
                              />
                            )}
                          </Grid>
                          <Grid item mini={12} marginBottom={2}>
                            <SoftBox
                              sx={{
                                marginBottom: "0.25rem",
                              }}
                            > 
                              <SoftBox
                                sx={{
                                  marginBottom: "-0.25rem",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <SoftTypography variant="button" sx={{ color: "#E9ECEF", marginRight: "0.5rem" }}>
                                  Description 
                                </SoftTypography>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.99992 1.33333C11.6819 1.33333 14.6666 4.31799 14.6666 7.99999C14.6666 11.682 11.6819 14.6667 7.99992 14.6667C4.31792 14.6667 1.33325 11.682 1.33325 7.99999C1.33325 4.31799 4.31792 1.33333 7.99992 1.33333ZM7.99992 2.66666C6.58543 2.66666 5.22888 3.22856 4.22868 4.22876C3.22849 5.22895 2.66659 6.58551 2.66659 7.99999C2.66659 9.41448 3.22849 10.771 4.22868 11.7712C5.22888 12.7714 6.58543 13.3333 7.99992 13.3333C9.41441 13.3333 10.771 12.7714 11.7712 11.7712C12.7713 10.771 13.3333 9.41448 13.3333 7.99999C13.3333 6.58551 12.7713 5.22895 11.7712 4.22876C10.771 3.22856 9.41441 2.66666 7.99992 2.66666ZM7.99325 6.66666C8.36525 6.66666 8.66658 6.96799 8.66658 7.33999V10.756C8.79367 10.8294 8.89299 10.9426 8.94915 11.0782C9.0053 11.2138 9.01515 11.3641 8.97717 11.5059C8.93919 11.6476 8.8555 11.7729 8.73907 11.8622C8.62265 11.9516 8.48 12 8.33325 12H8.00659C7.91816 12 7.8306 11.9826 7.74891 11.9487C7.66722 11.9149 7.59299 11.8653 7.53047 11.8028C7.46794 11.7403 7.41834 11.666 7.38451 11.5843C7.35067 11.5026 7.33325 11.4151 7.33325 11.3267V7.99999C7.15644 7.99999 6.98687 7.92976 6.86185 7.80473C6.73682 7.67971 6.66658 7.51014 6.66658 7.33333C6.66658 7.15652 6.73682 6.98695 6.86185 6.86192C6.98687 6.7369 7.15644 6.66666 7.33325 6.66666H7.99325ZM7.99992 4.66666C8.17673 4.66666 8.3463 4.7369 8.47132 4.86192C8.59635 4.98695 8.66658 5.15652 8.66658 5.33333C8.66658 5.51014 8.59635 5.67971 8.47132 5.80473C8.3463 5.92976 8.17673 5.99999 7.99992 5.99999C7.82311 5.99999 7.65354 5.92976 7.52851 5.80473C7.40349 5.67971 7.33325 5.51014 7.33325 5.33333C7.33325 5.15652 7.40349 4.98695 7.52851 4.86192C7.65354 4.7369 7.82311 4.66666 7.99992 4.66666Z" fill="#667085"/>
                                </svg>
                              </SoftBox>
                              <SoftTypography variant="button" sx={{ color: "#E9ECEF !important", fontSize: "11px", fontWeight: "400" }}>
                                A description for players to understand what they are to do 
                              </SoftTypography>
                            </SoftBox>
                            <SoftInput
                              {...register("description")}
                              name="description"
                              type="textarea"
                              size="large"
                              sx={{
                                border: "1px solid rgba(233, 236, 239, 0.10)",
                                backgroundColor: "rgba(255, 255, 255, 0.10) !important",
                                color: "#ffffffb3 !important",
                                "& .MuiInputBase-input::placeholder": {
                                  color: "white !important",
                                  opacity: "0.7",
                                },
                                "&.Mui-focused": {
                                  borderColor: "#E9ECEF",
                                  boxShadow: `0rem 0rem 0rem 0.125rem #E9ECEF`,
                                },
                              }}
                            />
                          </Grid>
                          <Grid item mini={12} marginBottom={2}>
                            <SoftBox
                              sx={{
                                marginBottom: "0.25rem",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <SoftTypography variant="button" sx={{ color: "#E9ECEF", marginRight: "0.5rem" }}>
                                Badge Icon 
                              </SoftTypography>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.99992 1.33333C11.6819 1.33333 14.6666 4.31799 14.6666 7.99999C14.6666 11.682 11.6819 14.6667 7.99992 14.6667C4.31792 14.6667 1.33325 11.682 1.33325 7.99999C1.33325 4.31799 4.31792 1.33333 7.99992 1.33333ZM7.99992 2.66666C6.58543 2.66666 5.22888 3.22856 4.22868 4.22876C3.22849 5.22895 2.66659 6.58551 2.66659 7.99999C2.66659 9.41448 3.22849 10.771 4.22868 11.7712C5.22888 12.7714 6.58543 13.3333 7.99992 13.3333C9.41441 13.3333 10.771 12.7714 11.7712 11.7712C12.7713 10.771 13.3333 9.41448 13.3333 7.99999C13.3333 6.58551 12.7713 5.22895 11.7712 4.22876C10.771 3.22856 9.41441 2.66666 7.99992 2.66666ZM7.99325 6.66666C8.36525 6.66666 8.66658 6.96799 8.66658 7.33999V10.756C8.79367 10.8294 8.89299 10.9426 8.94915 11.0782C9.0053 11.2138 9.01515 11.3641 8.97717 11.5059C8.93919 11.6476 8.8555 11.7729 8.73907 11.8622C8.62265 11.9516 8.48 12 8.33325 12H8.00659C7.91816 12 7.8306 11.9826 7.74891 11.9487C7.66722 11.9149 7.59299 11.8653 7.53047 11.8028C7.46794 11.7403 7.41834 11.666 7.38451 11.5843C7.35067 11.5026 7.33325 11.4151 7.33325 11.3267V7.99999C7.15644 7.99999 6.98687 7.92976 6.86185 7.80473C6.73682 7.67971 6.66658 7.51014 6.66658 7.33333C6.66658 7.15652 6.73682 6.98695 6.86185 6.86192C6.98687 6.7369 7.15644 6.66666 7.33325 6.66666H7.99325ZM7.99992 4.66666C8.17673 4.66666 8.3463 4.7369 8.47132 4.86192C8.59635 4.98695 8.66658 5.15652 8.66658 5.33333C8.66658 5.51014 8.59635 5.67971 8.47132 5.80473C8.3463 5.92976 8.17673 5.99999 7.99992 5.99999C7.82311 5.99999 7.65354 5.92976 7.52851 5.80473C7.40349 5.67971 7.33325 5.51014 7.33325 5.33333C7.33325 5.15652 7.40349 4.98695 7.52851 4.86192C7.65354 4.7369 7.82311 4.66666 7.99992 4.66666Z" fill="#667085"/>
                              </svg>
                            </SoftBox>
                            <SoftBox display="flex" justifyContent="space-between">
                              <SoftButton
                                color="outlined"
                                variant="contained"
                                onClick={handleOpenImageModal}
                                sx={{
                                  textTransform: "none",
                                  borderRadius: "4px",
                                  border: "1px solid rgba(233, 236, 239, 0.20)",
                                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                                  background: "rgba(255, 255, 255, 0.10)",
                                  color: "#CED4DA",
                                  width: "100%",
                                  justifyContent: "flex-start",
                                  fontWeight: "normal",
                                  "&:hover": {
                                    background: "rgba(255, 255, 255, 0.10)",
                                  },
                                }}
                              >
                                {imageSrc ? "Image selected" : "Choose from existing"}
                              </SoftButton>
                              <input
                                ref={badgeImageInputRef}
                                onChange={handleBadgeInputChange}
                                accept="image/*"
                                type="file"
                                hidden
                              />
                            </SoftBox>
                          </Grid>
                        </>
                      )}
                    </SoftBox>
                  </DialogContent>
                  <DialogActions style={{background: "#2E2E2F"}}>
                    {activeStep === 0 ? (
                      <SoftButton onClick={closeBadgeMetricDialog} 
                        sx={{
                          color: "#FFF", 
                          background: "rgba(255, 255, 255, 0.1)", 
                          border: "1px solid rgba(233, 236, 239, 0.1)", 
                          boxShadow: "0px 1 1.4px rgba(0, 0, 0, 0.05)", 
                          borderRadius: "3px",
                          "&:hover": {
                            background: "rgba(255, 255, 255, 0.1)",
                          },
                        }}
                      >
                        Cancel
                      </SoftButton>
                    ) : (
                      <SoftButton onClick={handleBack}
                        sx={{
                          color: "#FFF", 
                          background: "rgba(255, 255, 255, 0.1)", 
                          border: "1px solid rgba(233, 236, 239, 0.1)", 
                          boxShadow: "0px 1 1.4px rgba(0, 0, 0, 0.05)", 
                          borderRadius: "3px",
                          "&:hover": {
                            background: "rgba(255, 255, 255, 0.1)",
                          },
                        }}
                      >
                        Back
                      </SoftButton>
                    )}
                    {activeStep === 0 ? (
                      <SoftButton onClick={handleNext} 
                          sx={{
                            color: "#344054",
                            background: "#F9FAFB",
                            boxShadow: "0px 1 1.4px rgba(0, 0, 0, 0.05)",
                            borderRadius: "3px"
                          }}                      
                        >
                        Next
                      </SoftButton>
                    ) : (
                      <SoftButton 
                        onClick={createBadge} 
                        sx={{
                          color: "#344054",
                          background: "#F9FAFB",
                          boxShadow: "0px 1 1.4px rgba(0, 0, 0, 0.05)",
                          borderRadius: "3px"
                        }}  
                        disabled={!imageSrc}
                      >
                        Create Badge
                      </SoftButton>
                    )}
                  </DialogActions>
                </form>
              </Dialog>
              <Dialog 
                open={openImageModal} 
                onClose={handleCloseImageModal} 
                maxWidth="md" 
                fullWidth
                sx={{
                  ".MuiPaper-root": {
                    borderRadius: "8px",
                    background:
                      "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
                    boxShadow:
                      "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
                    backdropFilter: "blur(25px)",
                    width: "100%",
                    maxWidth: "700px",
                    borderWidth: "1px",
                    borderColor: "#FFF",
                  },
                }}>
                <DialogTitle sx={{ color: "#FFF", padding: { mini: "1rem", md: "1.5rem 1.7rem" } }}>
                    <SoftTypography
                      variant="h4"
                      sx={{ color: "#FFF", fontSize: "20px", marginBottom: "-2rem !important" }}
                    >
                      Select Badge Icon
                    </SoftTypography>
                  </DialogTitle>
                <Divider
                    sx={{
                      backgroundColor: "#FFF",
                    }}
                  />
                <DialogContent sx={{padding: { mini: "1rem", md: "1.5rem 1.7rem" }, marginTop: "-1.7rem"}}>
                  <Grid container spacing={2}>
                    {masterBadges.filter((badge, index, self) => {
                      const isUnique = index === self.findIndex(b => b.image === badge.image);
                      return isUnique;
                    }).map((badge) => (
                      (!badge.groupId || badge.groupId === workspace._id) && (
                        <Grid item xs={2} key={badge._id}>
                          <SoftBox
                            component="img"
                            src={GET_IMAGE(badge.image)}
                            alt={badge.title}
                            sx={{
                              width: "100%",
                              height: "auto",
                              cursor: "pointer",
                              border: imageSrc === badge.image ? "2px solid #1976d2" : "none",
                              borderRadius: "4px",
                              backgroundColor: "#FFF",
                            }}
                            onClick={() => handleSelectMasterBadgeImage(badge.image)}
                          />
                        </Grid>
                      )
                    ))}
                    <Grid item xs={2}>
                      <SoftBox
                        onClick={handleBadgeUpload}
                        sx={{
                          backgroundColor: "#2E2E2F",
                          borderRadius: "4px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Add 
                          sx={{
                            width: "100%",
                            height: "auto",
                            color: "#FFFFFF",
                          }}
                        />
                      </SoftBox>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <SoftButton onClick={handleCloseImageModal}
                    sx={{
                      color: "#FFF", 
                      background: "rgba(255, 255, 255, 0.1)", 
                      border: "1px solid rgba(233, 236, 239, 0.1)", 
                      boxShadow: "0px 1 1.4px rgba(0, 0, 0, 0.05)", 
                      borderRadius: "3px",
                      "&:hover": {
                        background: "rgba(255, 255, 255, 0.1)",
                      },
                    }}
                  >
                    Cancel
                  </SoftButton>
                </DialogActions>
              </Dialog>
            </div>
            <Grid container spacing={2} height="100%" marginTop={0} className="p-2">
              {filteredBadges.map((badge, index) => (
                <Grid
                  item
                  sm={6}
                  lg={3}
                  className="p-2"
                  key={badge._id}
                >
                  <SoftBox 
                    className="p-4"
                    style={{
                      backgroundImage: `url("${BadgeBg}")`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat", 
                      backgroundSize: "100%",
                      border: "1.5px solid #F2F4F7",
                      borderRadius: "8px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                      position: "relative", // Add this
                    }}
                  >
                    {isAdmin && (
                      <>
                        <SoftButton
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            cursor: "pointer",
                            boxShadow: "none",
                            minWidth: "max-content",
                            minHeight: "max-content",
                            padding: "0.25rem",
                            background: "transparent",
                            "& svg": {
                              fill: "gray",
                            },
                            "&:hover": {
                              background: "transparent",
                            },
                            "&:focus:not(:hover)": {
                              background: "transparent",
                            },
                          }}
                          onClick={(e) => {
                            setSelectedItem(badge);
                            handleMoreClick(e);
                          }}
                        >
                          <MoreVertIcon sx={{ fontSize: "1.5rem !important" }} />
                        </SoftButton>
                        <Popover
                          open={open02}
                          anchorEl={anchorEl02}
                          onClose={handleMoreClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          PaperProps={{
                            style: {
                              boxShadow: "none",
                              padding: "0rem",
                              background: "white",
                              border: "1px solid #00000066",
                            },
                          }}
                        >
                          <SoftBox>
                            <List>
                              <ListItem>
                                <ListItemButton sx={{ gap: "4px" }} 
                                onClick={(e) => {
                                  handleMoreClose();
                                  e.stopPropagation();
                                  handleEditBadge(selectedItem);
                                }}>
                                  <ListItemIcon sx={{ minWidth: "max-content" }}>
                                    <EditOutlinedIcon sx={{ color: "black !important" }} />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary="Edit"
                                    primaryTypographyProps={{
                                      color: "black !important",
                                      fontWeight: "medium",
                                      variant: "caption",
                                    }}
                                  />
                                </ListItemButton>
                              </ListItem>
                              <ListItem>
                                <ListItemButton sx={{ gap: "4px" }} onClick={() => {
                                  handleMoreClose();
                                  handleDeleteBadgeClick(selectedItem._id);
                                }}>
                                  <ListItemIcon sx={{ minWidth: "max-content" }}>
                                    <DeleteOutlineIcon sx={{ color: "black !important" }} />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary="Delete"
                                    primaryTypographyProps={{
                                      color: "black !important",
                                      fontWeight: "medium",
                                      variant: "caption",
                                    }}
                                  />
                                </ListItemButton>
                              </ListItem>
                            </List>
                          </SoftBox>
                        </Popover>
                      </>
                    )}
                    <img
                      onClick={() =>
                        setOpenPreview({
                          open: true,
                          id: badge._id,
                          template: badge,
                          status: 0,
                          add: false,
                          onAdd: null,
                          fromSoftBox: true
                        })
                      }
                      src={GET_IMAGE(badge.image)}
                      alt={badge.title}
                      style={{
                        borderRadius: "none !important", 
                        width: "75px",
                        height: "auto",
                        maxHeight: "75px",
                        cursor: "pointer"
                      }}
                    />
                    <SoftTypography 
                      variant="h3" 
                      className="text-dark"
                      style={{
                        fontSize: "18px",
                        lineHeight: "25px",
                        fontWeight: "500",
                        marginTop: "0.5rem",
                        color: "#667085",
                        textTransform: "capitalize"
                      }}
                    >
                      {badge.title}
                    </SoftTypography>
                  </SoftBox>
                </Grid>
              ))}
            </Grid>      
          </SoftBox>
        </FeedTabPanel>
      </Grid>
      {successDialog && (
        <Dialog
          open={successDialog}
          onClose={() => setSuccessDialog(false)}
          maxWidth="sm"
          fullWidth
          sx={{
            ".MuiPaper-root": {
              borderRadius: "8px",
              background:
                "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
              boxShadow:
                "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
              backdropFilter: "blur(25px)",
              width: "100%",
              maxWidth: "450px",
              borderWidth: "1px",
              borderColor: "#FFF",
            },
          }}
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            <SoftBox display="flex" justifyContent="center" sx={{ marginTop: "1rem" }}>
              <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35.5" cy="35.5" r="35.2809" fill="url(#paint0_linear_520_6143)" fill-opacity="0.2" stroke="url(#paint1_linear_520_6143)" stroke-width="0.438272"/>
                <circle cx="35.5001" cy="35.4997" r="28.7068" fill="url(#paint2_linear_520_6143)" fill-opacity="0.5" stroke="url(#paint3_linear_520_6143)" stroke-width="0.438272"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.7462 25.9868C48.1208 26.3618 48.3311 26.8701 48.3311 27.4001C48.3311 27.9301 48.1208 28.4384 47.7462 28.8134L31.4876 45.0721C31.2999 45.2596 31.0767 45.4079 30.8312 45.5083C30.5856 45.6088 30.3225 45.6594 30.0572 45.6571C29.7919 45.6549 29.5297 45.5999 29.2859 45.4953C29.042 45.3908 28.8214 45.2387 28.6369 45.0481L22.2289 38.4241C21.8635 38.0422 21.664 37.5313 21.6742 37.0028C21.6844 36.4744 21.9033 35.9714 22.2831 35.6039C22.663 35.2364 23.1729 35.0342 23.7014 35.0415C24.2299 35.0487 24.734 35.2649 25.1036 35.6428L30.0982 40.8054L44.9196 25.9868C45.2946 25.6122 45.8029 25.4019 46.3329 25.4019C46.8629 25.4019 47.3712 25.6122 47.7462 25.9868Z" fill="#299E47"/>
                <defs>
                <linearGradient id="paint0_linear_520_6143" x1="35.5" y1="3.28704" x2="35.7772" y2="104.192" gradientUnits="userSpaceOnUse">
                <stop stop-color="#299E47" stop-opacity="0"/>
                <stop offset="0.300199" stop-color="#299E47" stop-opacity="0.06"/>
                <stop offset="1" stop-color="#299E47"/>
                </linearGradient>
                <linearGradient id="paint1_linear_520_6143" x1="35.5" y1="0" x2="35.5" y2="87.2161" gradientUnits="userSpaceOnUse">
                <stop stop-color="#98A2B3" stop-opacity="0.03"/>
                <stop offset="1" stop-color="#41464D" stop-opacity="0.16"/>
                </linearGradient>
                <linearGradient id="paint2_linear_520_6143" x1="35.5001" y1="6.57373" x2="36.6752" y2="98.383" gradientUnits="userSpaceOnUse">
                <stop stop-color="#299E47" stop-opacity="0.5"/>
                <stop offset="0.356773" stop-color="#299E47" stop-opacity="0.2"/>
                <stop offset="1" stop-color="#299E47" stop-opacity="0.7"/>
                </linearGradient>
                <linearGradient id="paint3_linear_520_6143" x1="35.5001" y1="6.57373" x2="35.5001" y2="64.4256" gradientUnits="userSpaceOnUse">
                <stop stop-color="#475467" stop-opacity="0.44"/>
                <stop offset="1" stop-color="#8DA7CD" stop-opacity="0.06"/>
                </linearGradient>
                </defs>
              </svg>
            </SoftBox>
            <SoftTypography variant="h6" 
              sx={{ 
                color: "#FFF",
                fontSize: "12px",
                marginTop: "0.5rem",
                }}
              >
              Successful
            </SoftTypography>
            <SoftTypography variant="h6" 
              sx={{ 
                color: "#FFF",
                fontSize: "24px",
                fontWeight: "600",
                marginTop: "1.5rem",
                }}
              >
              {ifKpiId ? "Metric Badge Created" : "Merit Badge Created"}
            </SoftTypography>
          </DialogTitle>
          <DialogContent>
            <SoftBox display="flex" justifyContent="center" mb="35px">
              <SoftTypography
                variant="p"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#FFF",
                  textAlign: "center",
                }}
              >
                You can access you badge and add to any level of<br/> your choice
              </SoftTypography>
            </SoftBox>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", pb: 5 }}>
            <SoftButton
              size="small"
              color="white"
              onClick={() => setSuccessDialog(false)}
              sx={{
                width: "min-content",
                borderRadius: "4px",
                textTransform: "none",
                color: "#344054",
              }}
            >
              Done
            </SoftButton>
          </DialogActions>
        </Dialog>
      )}
      {deleteConfirmDialog && (
        <Dialog
          open={deleteConfirmDialog}
          onClose={() => setDeleteConfirmDialog(false)}
          maxWidth="sm"
          fullWidth
          sx={{
            ".MuiPaper-root": {
              borderRadius: "8px",
              background:
                "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
              boxShadow:
                "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
              backdropFilter: "blur(25px)",
              width: "100%",
              maxWidth: "450px",
              borderWidth: "1px",
              borderColor: "#FFF",
            },
          }}
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            <SoftBox display="flex" justifyContent="center" sx={{ marginTop: "1rem" }}>
              <svg width="71" height="71" viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35.5" cy="35.5" r="35.2809" fill="url(#paint0_linear_525_6144)" fill-opacity="0.2" stroke="url(#paint1_linear_525_6144)" stroke-width="0.438272"/>
                <circle cx="35.5001" cy="35.4997" r="28.7068" fill="url(#paint2_linear_525_6144)" fill-opacity="0.5" stroke="url(#paint3_linear_525_6144)" stroke-width="0.438272"/>
                <path d="M35.9997 22.3335L21.333 47.6668H50.6663L35.9997 22.3335Z" stroke="#981B14" stroke-width="2" stroke-linejoin="round"/>
                <path d="M36 42.3337V43.0003M36 31.667L36.0053 38.3337" stroke="#981B14" stroke-width="2" stroke-linecap="round"/>
                <defs>
                <linearGradient id="paint0_linear_525_6144" x1="35.5" y1="3.28704" x2="35.7772" y2="104.192" gradientUnits="userSpaceOnUse">
                <stop stop-color="#9E2929" stop-opacity="0"/>
                <stop offset="0.300199" stop-color="#9E2929" stop-opacity="0.06"/>
                <stop offset="1" stop-color="#9E2929"/>
                </linearGradient>
                <linearGradient id="paint1_linear_525_6144" x1="35.5" y1="0" x2="35.5" y2="87.2161" gradientUnits="userSpaceOnUse">
                <stop stop-color="#98A2B3" stop-opacity="0.03"/>
                <stop offset="1" stop-color="#41464D" stop-opacity="0.16"/>
                </linearGradient>
                <linearGradient id="paint2_linear_525_6144" x1="35.5001" y1="6.57373" x2="36.6752" y2="98.383" gradientUnits="userSpaceOnUse">
                <stop stop-color="#9E2929" stop-opacity="0"/>
                <stop offset="0.356773" stop-color="#9E2929" stop-opacity="0.2"/>
                <stop offset="1" stop-color="#9E2929" stop-opacity="0.7"/>
                </linearGradient>
                <linearGradient id="paint3_linear_525_6144" x1="35.5001" y1="6.57373" x2="35.5001" y2="64.4256" gradientUnits="userSpaceOnUse">
                <stop stop-color="#475467" stop-opacity="0.44"/>
                <stop offset="1" stop-color="#8DA7CD" stop-opacity="0.06"/>
                </linearGradient>
                </defs>
              </svg>
            </SoftBox>
            <SoftTypography variant="h6" 
              sx={{ 
                color: "#FFF",
                fontSize: "24px",
                fontWeight: "600",
                marginTop: "1.5rem",
              }}
            >
              Delete Badge
            </SoftTypography>
          </DialogTitle>
          <DialogContent>
            <SoftBox display="flex" justifyContent="center" mb="35px">
              <SoftTypography
                variant="p"
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#FFF",
                  textAlign: "center",
                }}
              >
                Are you sure you want to delete this badge?<br/>
                This action cannot be undone.
              </SoftTypography>
            </SoftBox>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center", pb: 5, gap: 2 }}>
            <SoftButton
              size="small"
              color="white"
              onClick={() => setDeleteConfirmDialog(false)}
              sx={{
                width: "120px",
                borderRadius: "4px",
                textTransform: "none",
                color: "#FFF!important",
                background: "rgba(255, 255, 255, 0.1)",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              Cancel
            </SoftButton>
            <SoftButton
              size="small"
              color="white"
              onClick={confirmDeleteBadge}
              sx={{
                width: "120px",
                borderRadius: "4px",
                textTransform: "none",
                color: "#344054",
              }}
            >
              Proceed
            </SoftButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default LevelUp;
