import React, { useState } from "react";
import { Modal, Box } from "@material-ui/core";
import Swal from "sweetalert2";

import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import SoftBox from "../../components/SoftBox";
import SoftInput from "../../components/SoftInput";
import "../../components/Modals/swal-globals.css";
import { UPDATE_LEVEL } from "../../api/level-up";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  boxShadow: "0px 20px 27px rgba(0, 0, 0, 0.05)",
  paddingTop: "30px",
  paddingBottom: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  borderRadius: "10px",
  backgroundColor: "#FFF",
};

const EditBadgeLevel = ({
  editPopUp,
  setEditPopUp,
  dataLevels,
  setLevelsData,
  board,
  setBoard,
  onDeleteLevel,
  triggerSave,
}) => {
  const [values, setValues] = useState({
    levelName: board.columns[editPopUp.index].levelName,
    levelInfo: board.columns[editPopUp.index].levelInfo,
  });

  const cancelTrigger = () => {
    setEditPopUp({ open: false, index: 0 });
  };

  const saveTrigger = async () => {
    try {
      const updatedLevel = {
        name: board.columns[editPopUp.index].title,
        levelName: values.levelName,
        levelInfo: values.levelInfo,
      };
      await UPDATE_LEVEL({
        levelId: board.columns[editPopUp.index].id,
        levelName: values.levelName,
        levelInfo: values.levelInfo
      });
      const copy = [...board.columns];
      copy[editPopUp.index].levelName = values.levelName;
      copy[editPopUp.index].levelInfo = values.levelInfo;
      setBoard({
        columns: [...copy],
      });
      if (triggerSave) {
        await triggerSave();
      }
      cancelTrigger();
    } catch (error) {
      console.error("Error saving level:", error);
    }
  };

  const onChangeTrigger = (e) => {
    const { name, value } = e.target;
    const copy = { ...values };
    copy[name] = value;
    setValues(copy);
  };

  const triggerRemoveLevel = () => {
    const levelId = board.columns[editPopUp.index].id; // Get the level ID
    Swal.fire({
      title: board.columns[editPopUp.index].cards.length
        ? "Are you really sure?"
        : "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: { container: "swal-container" },
    }).then((result) => {
      if (result.isConfirmed) {
        onDeleteLevel(levelId); 
        cancelTrigger();
      }
    });
  };

  return (
    <Modal
      open={editPopUp.open}
      onClose={() => {}}
      aria-labelledby="badge-title"
      aria-describedby="badge-description"
    >
      <div style={modalStyle}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <SoftTypography
            variant="h6"
            sx={{
              fontSize: "18px",
              color: "#000",
              margin: 0,
              padding: 0,
              fontWeight: "500",
            }}
          >
            Level {editPopUp.index + 1} Details
          </SoftTypography>
          <SoftButton size="small" onClick={triggerRemoveLevel}>
            Remove Level
          </SoftButton>
        </SoftBox>
        <hr />
        <SoftInput
          type="text"
          name="levelName"
          value={values.levelName}
          sx={{
            marginBottom: "20px",
          }}
          onChange={onChangeTrigger}
        />
        <textarea
          className="form-control"
          name="levelInfo"
          id="exampleFormControlTextarea1"
          rows="4"
          value={values.levelInfo}
          onChange={onChangeTrigger}
        />
        <div className="my-3" style={{ display: "flex" }}>
          <SoftButton
            size="small"
            variant="gradient"
            onClick={saveTrigger}
            className="addBtn"
          >
            Save Changes
          </SoftButton>
          <SoftButton
            size="small"
            variant="gradient"
            onClick={cancelTrigger}
            className="cancelBtn"
          >
            Cancel
          </SoftButton>
        </div>
      </div>
    </Modal>
  );
};

export default EditBadgeLevel;
