export const WINDOW_CLEANING = "Window Cleaning";
export const EXTERIOR_CLEANING = "Exterior Cleaning";
export const FLOOR_COATING = "Floor Coating";
export const CARPET_CLEANING = "Carpet Cleaning";
export const AUTOMOTIVE = "Automotive";
export const HVAC = "HVAC";
export const ELECTRICAL = "Electrical Services";
export const MAID_SERVICE = "Maid Service";
export const PRESSURE_WASHING = "Pressure Washing";
export const OTHERS = "Others";

export default [
  WINDOW_CLEANING,
  EXTERIOR_CLEANING,
  FLOOR_COATING,
  CARPET_CLEANING,
  AUTOMOTIVE,
  HVAC,
  ELECTRICAL,
  MAID_SERVICE,
  PRESSURE_WASHING,
  OTHERS
];
