import React, { useEffect, useState } from "react";
import { Divider, Grid } from "@mui/material";
import moment from "moment";

import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import { GET_COMPANY } from "../../api/company";
import { UseUserDetails } from "../../hooks/auth";

const CurrentPlanSummary = () => {
  const user = UseUserDetails();
  const [billingDate, setBillingDate] = useState(null);

  const fetchNextBillingDate = async () => {
    try {
      const { data } = await GET_COMPANY(user.companyId);
      const stripeSubscriptionEnd = data && data.subscription?.stripeSubscriptionEnd ? data.subscription.stripeSubscriptionEnd : null;
      setBillingDate(stripeSubscriptionEnd);
    } catch (error) {
      console.error("Error fetching company data:", error);
      setBillingDate(null);
    }
  };

  useEffect(() => {
    fetchNextBillingDate();
  }, []);

  return (
    <SoftBox sx={{ background: "white", height: "100%", borderRadius: "16px" }}>
      <SoftBox sx={{ padding: "10px 20px" }}>
        <SoftTypography>Current Plan Summary</SoftTypography>
      </SoftBox>
      <Divider
        sx={{
          margin: 0,
          backgroundImage:
            "linear-gradient(to right, #6C757D, #6C757D, #6C757D) !important",
        }}
      />
      <SoftBox sx={{ padding: "30px 20px" }}>
        <SoftBox display="flex" justifyContent="space-between" mb="20px">
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              sx={{ color: "#696969", fontWeight: 500, fontSize: "12px" }}
            >
              Plan Name
            </SoftTypography>
            <SoftTypography sx={{ color: "#2C2C2C", fontWeight: 700 }}>
              Starter Plan
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              sx={{ color: "#696969", fontWeight: 500, fontSize: "12px" }}
            >
              Billing Cycle
            </SoftTypography>
            <SoftTypography sx={{ color: "#2C2C2C", fontWeight: 700 }}>
              Monthly
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              sx={{ color: "#696969", fontWeight: 500, fontSize: "12px" }}
            >
              Plan Cost
            </SoftTypography>
            <SoftTypography sx={{ color: "#2C2C2C", fontWeight: 700 }}>
              $80
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox
          sx={{
            background:
              "linear-gradient(0deg, #F2F4F7, #F2F4F7), linear-gradient(0deg, #EAECF0, #EAECF0)",
            border: "1px solid #EAECF0",
            padding: "10px 20px",
            borderRadius: "8px",
          }}
        >
          <Grid container>
            <Grid item md={6}>
              <SoftTypography
                sx={{ color: "#696969", fontWeight: 500, fontSize: "12px" }}
              >
                Number of player seat in usage
              </SoftTypography>
              <SoftTypography
                sx={{ color: "#2C2C2C", fontWeight: 700, fontSize: "18px" }}
              >
                8 Seats
              </SoftTypography>
            </Grid>
            <Grid item md={6}>
              <SoftTypography
                sx={{ color: "#696969", fontWeight: 500, fontSize: "12px" }}
              >
                Players seat is billed at what price
              </SoftTypography>
              <SoftTypography
                sx={{ color: "#2C2C2C", fontWeight: 700, fontSize: "18px" }}
              >
                $5.00 per seat
              </SoftTypography>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Divider
        sx={{
          margin: 0,
          backgroundImage:
            "linear-gradient(to right, #6C757D, #6C757D, #6C757D) !important",
        }}
      />
      <SoftBox
        display="flex"
        justifyContent="space-between"
        sx={{ padding: "15px 20px" }}
      >
        <SoftTypography
          sx={{ color: "#000", fontSize: "18px", fontWeight: 700 }}
        >
          Total
        </SoftTypography>
        <SoftBox display="flex">
          <SoftTypography
            sx={{ color: "#475467", fontSize: "12px", fontWeight: 500 }}
          >
            Your Next Payments {billingDate ? moment(billingDate).format("MM-DD-YYYY") : "N/A"}
          </SoftTypography>
          {/**
          <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            sx={{ marginLeft: "15px" }}
          >
            <SoftTypography
              sx={{ color: "#000", fontSize: "22px", fontWeight: 700 }}
            >
              USD $80.00
            </SoftTypography>
            <SoftTypography
              sx={{ color: "#696969", fontSize: "12px", fontWeight: 500 }}
            >
              Including VAT
            </SoftTypography>
          </SoftBox>
           */}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

export default CurrentPlanSummary;
