/* eslint-disable */

import React, { useState } from "react";
import { Modal, TextField } from "@material-ui/core";
import Search from "@mui/icons-material/Search";

import SoftTypography from "../../components/SoftTypography";
import { GET_IMAGE } from "../../helpers/images";
import SoftButton from "../../components/SoftButton";
import LoadingScreen from "../../components/LoadingScreen/index";
import "./levelUpStyles.css";
import { Grid, Divider } from "@mui/material";
import { UseUserDetails } from "../../hooks/auth";
import { CREATE_USER_BADGE } from "../../api/group";
import { UseSelectedWorkspace } from "../../hooks/company";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
  borderRadius: '8px',
  boxShadow: 24,
  padding: '1.75rem',
  width: "100%",
  maxWidth: "700px",
  overflow: "visible",
  borderWidth: "1px",
  borderColor: "#98A2B3",
  backdropFilter: 'blur(28px)'
};

const AddBadgePopup = ({
  popUp,
  setPopUp,
  selectedItem,
  setSelectedItem,
  board,
  setBoard,
  isLoading,
  openPreview,
  setOpenPreview,
  masterBadges = [],
  triggerSave,
}) => {
  const user = UseUserDetails();
  const workspace = UseSelectedWorkspace();
  
  const [badges, setBadges] = useState(popUp.badgesCopy);

  const handleSelectItem = async (item) => {
    try {
      await CREATE_USER_BADGE({
        badgeId: item._id,
        groupId: workspace._id
      });
      setOpenPreview({
        open: true,
        id: item.id,
        template: { ...item, index: popUp.index },
        add: true,
        onAdd: (el) => onAddTrigger(el),
        triggerSave,
      });
    } catch (error) {
      console.error("Error creating user badge:", error);
    }
  };

  const onAddTrigger = (el) => {
    const duplicate = { ...board };
    duplicate.columns[popUp.index].cards = [
      ...board.columns[popUp.index].cards,
      ...el.badges,
    ];
    setBoard(duplicate);
    setOpenPreview({
      open: false,
      id: null,
      template: null,
      add: null,
      onAdd: null,
    });
  };

  const cancelTrigger = () => {
    setPopUp({
      open: false,
      index: 0,
      badgesCopy: popUp.index,
    });
    setSelectedItem({});
  };

  const onChangeTrigger = (e) => {
    const filterList = popUp.badgesCopy.filter(
      (item) =>
        item.title.toLowerCase().search(e.target.value.toLowerCase()) !== -1,
    );

    setBadges(filterList);
  };

  return (
    <Modal
      open={popUp.open}
      onClose={() => {}}
      aria-labelledby="badge-title"
      aria-describedby="badge-description"
    >
      <div style={modalStyle}>
        <SoftTypography
          component="h6"
          className="text-white mb-3"
          sx={{ fontSize: "18px", fontWeight: "400" }}
        >
          Choose Badges
        </SoftTypography>
        <Divider
          sx={{
            backgroundColor: "#D5EBFF",
          }}
        />
        <div className="mb-3 input-radius d-none">
          <TextField
            type="text"
            variant="outlined"
            onChange={onChangeTrigger}
            placeholder="Search Badge here..."
            InputProps={{
              startAdornment: (
                <Search style={{ marginRight: "0px", color: "#67748E" }} />
              ),
            }}
            style={{ width: "100%" }}
          />
        </div>
        {isLoading ? (
          <LoadingScreen height="200px" logoHeight="200px" />
        ) : !badges.length ? (
          <>
            <SoftTypography
              component="h6"
              className="text-black mb-3"
              sx={{ fontSize: "16px", fontWeight: "600" }}
            >
              No badges found!
            </SoftTypography>
            <div className="my-3">
              <SoftButton
                size="small"
                variant="outlined"
                onClick={cancelTrigger}
                className="cancelBtn"
              >
                Choose Badges
              </SoftButton>
            </div>
          </>
        ) : (
          <Grid container spacing={2} height="100%" marginTop={0}>
            {badges.filter(badge => badge.groupId && badge.groupId === workspace._id).map((item, i) => (
              <Grid
                item
                md={badges.length <= 4 ? 4 : 2}
              >
              <div
                key={item._id}
                onClick={() => handleSelectItem(item, i)}
                className="d-flex align-items-center justify-content-center py-2 px-3 cursor-pointer mb-2"
                style={{
                  backgroundColor: "#FFF",
                  height: "125px", // Fixed height for all divs
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <img
                    src={GET_IMAGE(item.image)}
                    alt={item.title}
                    style={{borderRadius: "none !important", width: "75px", height: "auto", maxHeight: "75px"}}
                  />
                  <SoftTypography
                    component="p"
                    className="text-black"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      margin: "8px 0 0 0 !important",
                      padding: "0px !important",
                      textAlign: "center"
                    }}
                  >
                    {item.title}
                  </SoftTypography>
                </div>
              </div>
              </Grid>
            ))}
            <Grid
                item
                md={12}
              >
              <div
                className="my-3"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <SoftButton
                  size="small"
                  color="white"
                  onClick={cancelTrigger}
                  className="addBtn"
                >
                  Cancel
                </SoftButton>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </Modal>
  );
};

export default AddBadgePopup;
