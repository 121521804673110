import React, { useState, useEffect } from "react";
import { useTheme, Dialog, Divider, Modal, Backdrop, Fade, CircularProgress, Box } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { toast } from "react-hot-toast";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { GET_CONNECTION_URL, GET_GOOGLE_KPI } from "../../../api/integration";
import { GET_ZAPIER_AUTH_URL } from "../../../api/integrations/zapier";
import { UseCompanyId } from "../../../hooks/auth";
import SoftBox from "../../SoftBox";
import SoftButton from "../../SoftButton";
import SoftTypography from "../../SoftTypography";
import SoftProfile from "../../ProfilePictures/SoftProfile";
import CloseIcon from '@mui/icons-material/Close';

import { GlowEffect } from "../../../styles/effects";

import MetricItem from "./MetricItem";
import AppSettings from "../apps/AppSettings";
import FbCampaignDialog from "./FbCampaignDialog";
import { MAX_WORKSPACE_KPIS } from "../../../constants/kpi";
import { UseSelectedWorkspace } from "../../../hooks/company";
import "../configs/styles.css";
import { display } from "@mui/system";

const MetricsModal = ({
  app,
  isOpen,
  handleClose,
  functions,
  workspaceKpiCount,
  groupKpis,
}) => {
  const companyId = UseCompanyId();
  const [showSettings, setShowSettings] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const style = {
    position: 'absolute',
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
    borderRadius: '10px',
    boxShadow: 24,
    padding: '1.75rem',
    maxWidth: { mini: "90%", xs: "90%", sm: "600px" },
    width: "100%",
    overflow: "visible",
    borderWidth: "1px",
    borderColor: "#FFF",
    backdropFilter: 'blur(25px)'
  };
  
  const { _id: groupId } = UseSelectedWorkspace();
  const [googleData, setGoogleData] = useState(null);
  
  useEffect(() => {
    if (app.name === "Google") {
      const fetchData = async () => {
        try {
          const response = await GET_GOOGLE_KPI(groupId);
          setGoogleData(response.data);  
        } catch (error) {
          console.error("Error fetching the google data:", error);
        }
      };
  
      fetchData();
    }
  }, [groupId, app.name]);

  // Campaign settings states: isCampaignActive determines if a campaign has been configured
  const [hasCampaigns] = useState(app.code === "facebookads");
  const [isCampaignActive] = useState(app.paramKeys?.campaignId);

  const theme = useTheme();
  const {
    primary: { main: primaryColor },
  } = theme.palette;

  useEffect(() => {
    setShowSettings(false);
  }, [isOpen]);

  const handleConnect = () => {
    if (app.isOauth || app.isApideck) {
      const connecting = toast.loading("Initializing connection ...");
      const params = app.isApideck
        ? { unifiedApi: app.type, serviceId: app.serviceId }
        : null;

      GET_CONNECTION_URL(app.code, params)
        .then(({ data: url }) => {
          if (url) {
            toast.success("Connecting...", { id: connecting });
            const windowRef = window.open("", "_self");
            windowRef.location.href = url;
          } else {
            toast.error("Unable to establish connection.", {
              id: connecting,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response?.data ?? "Server error", {
            id: connecting,
          });
        });
    } else if (app.isZapier) {
      window.location = GET_ZAPIER_AUTH_URL(companyId);
    } else {
      setShowSettings(true);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        ".MuiPaper-root": {
          padding: { mini: "1rem", md: "1.5rem 1.7rem" },
          borderRadius: "8px",
          background:
            "linear-gradient(156deg, rgba(12, 12, 13, 0.50) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)",
          boxShadow:
            "-12px -12px 20px 0px rgba(0, 0, 0, 0.20) inset, 12px 12px 24px 0px rgba(0, 0, 0, 0.20)",
          backdropFilter: "blur(25px)",
          width: "100%",
          maxWidth: "972px",
          minHeight: "700px",
          borderWidth: "1px",
          borderColor: "#FFF",
        },
      }}
    >
      {/** Header section */}

      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SoftBox sx={{ display: "flex", gap: "0.5rem" }}>
          <SoftTypography variant="h4" sx={{ color: "#FFF" }}>
            Instant Metrics
          </SoftTypography>
        </SoftBox>

        <SoftButton
          variant="outlined"
          color="white"
          onClick={handleClose}
          sx={{
            "& svg": { fontSize: "1.25rem !important" },
            borderRadius: "50%",
            minWidth: "max-content",
            minHeight: "max-content",
            padding: "0.25rem",
          }}
        >
          <CloseRoundedIcon />
        </SoftButton>
      </SoftBox>

      <Divider
        sx={{
          backgroundColor: "#FFF",
        }}
      />

      <SoftBox className="w-full flex">
        <SoftBox className="w-1/4 !p-2 !pl-0 flex flex-col gap-2">
          <SoftTypography
            variant="body2"
            color="white"
            className="!text-sm !font-semibold"
          >
            Didn't see what you were looking for?
          </SoftTypography>
          <SoftBox
            className="cursor-pointer"
            onClick={() => {
              handleClose();
              setTimeout(() => functions.createCustomKpi(), 350);
            }}
          >
            <SoftTypography
              variant="body2"
              className="!text-left !text-base !font-bold mb-2 text-gradient"
            >
              Create Custom Metric
            </SoftTypography>
          </SoftBox>

          {/** Connection status */}
          {app.isConnected ? (
            <div>
              <div className="flex gap-2 items-center">
                <SoftProfile
                  src={app.logo}
                  name={app.name}
                  size="xs"
                  borderRadius="sm"
                  sx={{ background: "#FFF", padding: "2px" }}
                />
                <SoftTypography
                  variant="body2"
                  className="!text-sm !font-bold text-gradient"
                >
                  App: Connected
                </SoftTypography>
              </div>
              {app.name === "Google" && googleData && (
                <div>
                  <SoftTypography 
                    variant="body2"
                    className="!text-sm !font-bold text-gradient mt-1">
                    Business Name: <br/>{googleData.businessName}
                  </SoftTypography>
                  <SoftTypography 
                    variant="body2"
                    className="!text-sm !font-bold text-gradient mt-1">
                    Address: <br/>{googleData.Address}
                  </SoftTypography>
                </div>
              )}
              {app.name !== "Game My Biz" && (
                <div>
                  <SoftButton
                    variant="outlined"
                    color="white"
                    onClick={handleOpenModal}
                    sx={{
                      borderRadius: "4px",
                      border: "1px solid rgba(233, 236, 239, 0.20)",
                      boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
                      textTransform: "none",
                      background: "rgba(255, 255, 255, 0.10)",
                      color: "#CED4DA",
                      marginTop: "1.5rem",
                      "&:hover": {
                        background: "rgba(255, 255, 255, 0.10)",
                      },
                      "&.Mui-disabled": {
                        color: "#CED4DA !important",
                      },
                    }}
                  >
                    Disconnect Account
                  </SoftButton>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={openModal}>
                      <SoftBox sx={style}>
                      <SoftBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <SoftTypography sx={{textFillColor: "#ffffff"}} variant="h6" component="h2">
                            Disconnect Account?
                          </SoftTypography>
                          <SoftButton
                            variant="outlined"
                            color="white"
                            onClick={handleCloseModal}
                            sx={{
                              "& svg": { fontSize: "1.25rem !important" },
                              borderRadius: "50%",
                              minWidth: "max-content",
                              minHeight: "max-content",
                              padding: "0.25rem",
                            }}
                          >
                            <CloseIcon />
                          </SoftButton>
                        </SoftBox>
                        <SoftBox sx={{textAlign: "center", display: "flex", justifyContent: "center", marginTop: "2rem"}}>
                          <svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="25.598" cy="25.9798" r="24.9994" fill="url(#paint0_linear_148_5363)" fillOpacity="0.2" stroke="url(#paint1_linear_148_5363)" strokeWidth="0.310551"/>
                            <circle cx="25.5979" cy="25.9798" r="20.3411" fill="url(#paint2_linear_148_5363)" fillOpacity="0.5" stroke="url(#paint3_linear_148_5363)" strokeWidth="0.310551"/>
                            <g clipPath="url(#clip0_148_5363)">
                            <path d="M25.9516 16.6504L15.5591 34.6011H36.3441L25.9516 16.6504Z" stroke="#981B14" strokeWidth="1.41716" strokeLinejoin="round"/>
                            <path d="M25.9521 30.8219V31.2943M25.9521 23.2637L25.9559 27.9875" stroke="#981B14" strokeWidth="1.41716" strokeLinecap="round"/>
                            </g>
                            <defs>
                            <linearGradient id="paint0_linear_148_5363" x1="25.598" y1="3.15433" x2="25.7944" y2="74.6535" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#9E2929" stopOpacity="0"/>
                            <stop offset="0.300199" stopColor="#9E2929" stopOpacity="0.06"/>
                            <stop offset="1" stopColor="#9E2929"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_148_5363" x1="25.598" y1="0.825195" x2="25.598" y2="62.6249" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#98A2B3" stopOpacity="0.03"/>
                            <stop offset="1" stopColor="#41464D" stopOpacity="0.16"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_148_5363" x1="25.5979" y1="5.4834" x2="26.4305" y2="70.5377" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#9E2929" stopOpacity="0"/>
                            <stop offset="0.356773" stopColor="#9E2929" stopOpacity="0.2"/>
                            <stop offset="1" stopColor="#9E2929" stopOpacity="0.7"/>
                            </linearGradient>
                            <linearGradient id="paint3_linear_148_5363" x1="25.5979" y1="5.4834" x2="25.5979" y2="46.4761" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#475467" stopOpacity="0.44"/>
                            <stop offset="1" stopColor="#8DA7CD" stopOpacity="0.06"/>
                            </linearGradient>
                            <clipPath id="clip0_148_5363">
                            <rect width="22.6746" height="22.6746" fill="white" transform="translate(14.6152 14.2881)"/>
                            </clipPath>
                            </defs>
                          </svg>
                        </SoftBox>
                        <SoftTypography sx={{ mt: 4, mb: 4, textFillColor: "white", textAlign: 'center', fontSize: '16px' }}>
                          Associated metrics will stop updating. To resume<br/> updates, you will need to re-autheticate the account
                        </SoftTypography>
                        <SoftBox 
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{marginTop: "3rem"}}>
                          <SoftButton
                            variant="outlined"
                            color="white"
                            sx={{
                              "& svg": { fontSize: "1.25rem !important" },
                              minWidth: "48%",
                              padding: ".25rem",
                              borderRadius: "4px"
                            }}
                          onClick={handleCloseModal}
                          >
                            Cancel
                          </SoftButton>
                          <SoftButton
                            variant="outlined"
                            color="white"
                            sx={{
                              "& svg": { fontSize: "1.25rem !important" },
                              minWidth: "48%",
                              padding: ".25rem",
                              borderRadius: "4px",
                              background: "#FFFFFF",
                              color: "#000000"
                            }}
                            onClick={handleConnect}
                          >
                            Disconnect
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </Fade>
                  </Modal>
                </div>
              )}
            </div>
          ) : (
            <>
              <GlowEffect borderRadius="sm" disabled={showSettings}>
                <SoftBox
                  className="flex justify-center cursor-pointer"
                  sx={{
                    borderRadius: "4px",
                    borderWidth: "2.5px",
                    borderStyle: "solid",
                    borderColor: "primary.main",
                  }}
                  onClick={handleConnect}
                >
                  <SoftTypography
                    variant="body2"
                    className="!text-sm !font-bold mt-1 text-gradient"
                  >
                    Connect Account
                  </SoftTypography>
                </SoftBox>
              </GlowEffect>
              <SoftTypography
                variant="body2"
                color="white"
                className="!text-xs mt-1 w-full text-center"
              >
                Connect your account to Gamemybiz
              </SoftTypography>
            </>
          )}

          {/** Campaigns Section (Only for Facebook Ads integration) */}
          {hasCampaigns && isCampaignActive && (
            <div className="flex gap-2 items-center mt-2">
              <SoftProfile
                src={app.logo}
                name={app.name}
                size="xs"
                borderRadius="sm"
                sx={{ background: "#FFF", padding: "2px" }}
              />
              <SoftTypography
                variant="body2"
                className="!text-sm !font-bold text-gradient"
              >
                Campaign: Selected
              </SoftTypography>
            </div>
          )}
          {hasCampaigns && (
            <GlowEffect
              borderRadius="sm"
              disabled={showCampaigns || isCampaignActive}
            >
              <SoftBox
                className="flex justify-center cursor-pointer"
                sx={{
                  borderRadius: "4px",
                  borderWidth: "2.5px",
                  borderStyle: "solid",
                  borderColor: "primary.main",
                }}
                onClick={() => setShowCampaigns(true)}
              >
                <SoftTypography
                  variant="body2"
                  className="!text-sm !font-bold mt-1 text-gradient"
                >
                  {isCampaignActive ? "Change" : "Select"} Campaign
                </SoftTypography>
              </SoftBox>
            </GlowEffect>
          )}
        </SoftBox>

        {/** Content section */}
        <SoftBox className="grow p-0 ml-4">
          <SoftBox className="flex items-center gap-2">
            <SoftProfile
              src={app.logo}
              name={app.name}
              size="sm"
              borderRadius="md"
              sx={{ background: "#FFF", padding: "4px" }}
            />
            <SoftTypography variant="h5" color="white">
              {app.name}
            </SoftTypography>
            <SoftTypography
              variant="body2"
              className="text-gradient !font-semibold !mx-4"
            >
              {app.metrics?.length} Metric{app.metrics?.length > 1 ? "s" : ""}
            </SoftTypography>
          </SoftBox>
          <SoftTypography
            variant="body2"
            color="white"
            className="!text-sm !font-semibold mt-3"
          >
            {app.description ??
              `Missing description for ${app.name} app integration.`}
          </SoftTypography>

          {showSettings && (
            <SoftBox className="mt-4 grow content-box">
              <SoftTypography variant="h6" color="white">
                Connect your account
              </SoftTypography>
              <AppSettings
                integrationId={app._id}
                logo={app.logo}
                name={app.name}
                code={app.code}
                parameters={app.parameters}
                handleClose={() => setShowSettings(false)}
              />
            </SoftBox>
          )}

          {/** Campaigns selection section */}
          {showCampaigns && (
            <SoftBox className="mt-4 grow content-box">
              <FbCampaignDialog
                closeCampaignModal={() => setShowCampaigns(false)}
              />
            </SoftBox>
          )}
          {/** Metrics section */}
          {!showSettings && !showCampaigns && (
            <SoftBox className="mt-4 flex flex-col gap-3 no-scrollbar max-h-[460px] overflow-y-scroll">
              {app.metrics?.map((metric, idx) => {
                const isMetricExists = groupKpis.find((item) => {
                  // Use kpiModelId if Game My Biz Data Cube metric
                  if (metric.app === "Game My Biz") {
                    return item.sourceId === metric.sourceId &&
                      item.kpiModelId === metric.kpiModelId;
                  } else {
                    // Regular Integrated metrics
                    return item.sourceId === metric.sourceId &&
                      item.source === metric.code;
                  }
                });

                return (
                  <MetricItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={`metric-${idx}`}
                    metric={metric}
                    disabled={
                      !app.isConnected ||
                      workspaceKpiCount >= MAX_WORKSPACE_KPIS ||
                      (hasCampaigns && !isCampaignActive)
                    }
                    isMetricExists={isMetricExists}
                    onAddMetric={(kpi) => {
                      handleClose();
                      functions.handleLocalKpi(kpi);
                    }}
                  />
                );
              })}
            </SoftBox>
          )}
        </SoftBox>
      </SoftBox>
    </Dialog>
  );
};

export default MetricsModal;
