import React, { useEffect, useState, useRef } from "react";
import { Modal } from "@material-ui/core";
import toast from "react-hot-toast";
import { PUT_WORKSPACE_IMAGE, SUBMIT_PROOF } from "../../api/group";

import SoftBox from "../../components/SoftBox";
import SoftButton from "../../components/SoftButton";
import SoftTypography from "../../components/SoftTypography";
import { GET_IMAGE } from "../../helpers/images";
// import { dataLevels } from "./data/index";
import CloseIcon from '@mui/icons-material/Close';
// import { border, margin, width } from "@mui/system";
import { Grid, Divider } from "@mui/material";
import SoftSelect from "../../components/SoftSelect";
import SoftInput from "../../components/SoftInput";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: 'linear-gradient(156.05deg, rgba(12, 12, 13, 0.5) 3.85%, rgba(12, 12, 13, 0.65) 50.21%, rgba(12, 12, 13, 0.51) 98.53%)',
  borderRadius: '8px',
  boxShadow: 24,
  height: 700,
  width: 620,
  overflow: "visible",
  borderWidth: "1px",
  borderColor: "#98A2B3",
  backdropFilter: 'blur(28px)'
};

const BadgeRequirementPreview = ({
  openPreview,
  setOpenPreview,
  board,
  setBoard,
  isAdmin,
  setPopUp,
  triggerSave,
  user,
  dispatch,
  getMasterBadges,
}) => {
  const [levelIndex, setLevelIndex] = useState(null);
  const [assignLevel, setAssignLevel] = useState(null); // State for selected level
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [submissionText, setSubmissionText] = useState("");
  const [submissionUrl, setSubmissionUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const imageInputRef = useRef(null);

  useEffect(() => {
    board.columns.map((item, index) => {
      item.cards.map((card) => {
        if (card.id === openPreview.id) {
          setLevelIndex(index);
        }
      });
    });
  }, [board, openPreview.id]);

  const actionTrigger = async () => {
    const duplicate = { ...board };
    if (openPreview.add) {
      const element = {
        index: openPreview.template.index,
        badges: [
          { id: openPreview.template._id, template: openPreview.template },
        ],
      };

      openPreview.onAdd(element);
      setPopUp({ open: false, index: 0, badgesCopy: [] });

      if (openPreview.triggerSave) {
        await openPreview.triggerSave();
      }
    } else {
      const filteredCards = duplicate.columns[levelIndex].cards.filter(
        (item) => item.id !== openPreview.id,
      );
      duplicate.columns[levelIndex].cards = filteredCards;

      setBoard(duplicate);
      setOpenPreview({ open: false, id: null, template: null, add: null });
      triggerSave();
    }
  };

  const handleAssignLevel = () => {
    if (assignLevel !== null) {
      const updatedBoard = { ...board };
      updatedBoard.columns[assignLevel].cards.push({
        id: openPreview.id,
        template: openPreview.template,
      });
      setBoard(updatedBoard);
      setOpenPreview({ open: false, id: null, template: null, add: null });
      triggerSave();
    }
  };

  const availableLevels = board.columns
    .map((column, index) => ({
      value: index,
      label: column.title,
    }))
    .filter((level) => !board.columns[level.value].cards.some(card => card.id === openPreview.id));

  const handleRemoveBadge = () => {
    setConfirmModal(true);
  };

  const handleConfirmRemove = () => {
    if (confirmText.toLowerCase() === 'confirm') {
      actionTrigger();
      setConfirmModal(false);
      setConfirmText("");
    }
  };

  const handleImageUpload = async (e) => {
    if (e.target.files.length === 0) return;
    
    const imgFile = e.target.files[0];
    if (imgFile) {
      const formData = new FormData();
      setIsSubmitting(true);
      formData.append("image", imgFile);
      
      try {
        const { data } = await PUT_WORKSPACE_IMAGE(formData);
        setImageSrc(data.key);
        setIsSubmitting(false);
      } catch (err) {
        console.log("Image upload error", err.message);
        toast.error("Failed to upload image");
        setIsSubmitting(false);
      }
    }
    e.target.value = null;
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;
    
    setIsSubmitting(true);
    try {
      const submissionData = {
        userId: user._id,
        badgeId: openPreview.id,
        type: openPreview.template.submissionType,
        proof: (() => {
          switch (openPreview.template.submissionType) {
            case "Image Submission":
              return imageSrc;
            case "Text Submission":
              return submissionText;
            default:
              return submissionUrl;
          }
        })()
      };
      await SUBMIT_PROOF(submissionData);
      toast.success("Proof submitted successfully");
      setOpenPreview({ open: false, id: null, template: null, add: null });
      dispatch(getMasterBadges());
    } catch (err) {
      console.error("Submission error:", err);
      toast.error("Failed to submit proof");
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Modal
        open={openPreview.open}
        onClose={() => {}}
        aria-labelledby="badge-title"
        aria-describedby="badge-description"
      >
        <div style={modalStyle}>
          <SoftBox display="flex" alignItems="center" sx={{ padding: "1.75rem 1.75rem 0 1.75rem" }}>
            <SoftBox display="flex" alignItems="center">
              <SoftBox display="flex" flexDirection="column">
                <SoftTypography
                  component="h3"
                  sx={{
                    fontSize: "18px",
                    color: "#FFF",
                    margin: 0,
                    padding: 0,
                    fontWeight: "500",
                  }}
                >
                  Badge Requirement
                </SoftTypography>
                <SoftTypography
                  component="p"
                  sx={{
                    color: "#FFF",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Learn more about badge
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <div className="ml-auto">
              <SoftButton
                variant="outlined"
                color="white"
                sx={{
                  "& svg": { fontSize: "1rem !important" },
                  borderRadius: "50%",
                  minWidth: "max-content",
                  minHeight: "max-content",
                  padding: "0.25rem",
                }}
                onClick={() =>
                  setOpenPreview({
                    open: false,
                    id: null,
                    template: null,
                    status: 0,
                    add: null,
                    onAdd: null,
                  })
                }
              >
                <CloseIcon />
              </SoftButton>
            </div>
          </SoftBox>
          <Divider
            sx={{
              backgroundColor: "#D5EBFF",
            }}
          />
          {(isAdmin || openPreview.template.kpiId) && (
            <>
            <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="h1"
              className="mb-2 mt-2"
              sx={{
                fontSize: "18px",
                fontWeight: 500,
                color: "#FFF",
                textAlign: "center",
                textTransform: "capitalize !important",
              }}
            >
              {openPreview.template.title}
            </SoftTypography>
            <div
              style={{
                height: 150,
                width: 150,
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
              }}
              className="my-2"
            >
              <img
                src={GET_IMAGE(openPreview.template.image)}
                alt=""
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <SoftTypography
              component="p"
              sx={{
                fontSize: "14px",
                fontWeight: 300,
                color: "#FFF",
                textAlign: "center",
                width: "420px",
                margin: "10px auto 20px auto",
              }}
            >
              Description:
              <br /><br />
              {openPreview.template.description}
            </SoftTypography>
            <SoftTypography
              component="p"
              sx={{
                fontSize: "14px",
                fontWeight: 300,
                color: "#FFF",
                textAlign: "center",
              }}
            >
              {openPreview.template.kpiId ? `Target: ${openPreview.template.target}` : null}
            </SoftTypography>
            {openPreview.fromSoftBox && isAdmin ? (
              <SoftBox sx={{ width: "50%", margin: "0 auto" }} className="mt-3">
                <SoftSelect
                  options={availableLevels}
                  onChange={(option) => setAssignLevel(option.value)}
                  placeholder="Assign to a level"
                  styles={{
                    color: "white !important",
                    height: "fit-content !important",
                    placeholder: {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    input: {
                      color: "white !important",
                      opacity: "0.7",
                    },
                    singleValue: {
                      color: "white !important",
                      opacity: "0.7",
                    },
                  }}
                />
              </SoftBox>
            ) : (
              !openPreview.add && !openPreview.fromSoftBox && isAdmin && (
                <SoftButton
                  className="mt-4"
                  onClick={handleRemoveBadge}
                  variant="gradient"
                  sx={{
                    color: "#344054",
                    backgroundColor: "#F9FAFB",
                    width: "150px",
                    margin: "0 auto",
                    borderRadius: "4px",
                  }}
                >
                  Remove badge
                </SoftButton>
              )
            )}
            </SoftBox>
            {(openPreview.add || (openPreview.fromSoftBox && isAdmin)) && (
            <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
              <Grid container marginTop={0}>
                <Grid style={{ background: "#2E2E2F", width: "100%", borderRadius: "0 0 8px 8px" }} item md={12}>
                  <div className="my-3 pr-5" style={{ display: "flex", justifyContent: "end" }}>
                    <SoftButton
                      size="small"
                      variant="gradient"
                      sx={{
                        color: "#CED4DA",
                        background: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        marginRight: "1rem",
                      }}
                      onClick={() =>
                        setOpenPreview({
                          open: false,
                          id: null,
                          template: null,
                          status: 0,
                          add: null,
                          onAdd: null,
                        })
                      }
                    >
                      Cancel
                    </SoftButton>
                    <SoftButton
                      sx={{
                        color: "#344054",
                        backgroundColor: "#F9FAFB",
                        borderRadius: "4px",
                      }}
                      size="small"
                      variant="gradient"
                      onClick={() => {
                        if (openPreview.fromSoftBox) {
                          handleAssignLevel();
                        } else if (openPreview.add) {
                          actionTrigger();
                        }
                      }}
                    >
                      Add badge
                    </SoftButton>
                  </div>
                </Grid>
              </Grid>
              </div>
            )}
            </>
          )}
          {openPreview.template.submissionType && !isAdmin && (
            <SoftBox display="flex" flexDirection="column" justifyContent="center" sx={{ padding: "2rem" }}>
              <SoftTypography
                component="p"
                className="mb-2 mt-2"
                sx={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#FFF", 
                  textAlign: "center",
                }}
              >
                Submit your proof to earn the {openPreview.template.title} badge
              </SoftTypography>
              {openPreview.template.submissionType === "Image Submission" && (
                <SoftBox className="flex flex-col items-center w-full gap-2">
                  <SoftBox
                    sx={{
                      width: "100%",
                      height: "200px",
                      border: "1px dashed #E9ECEF",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      "&:hover": {
                        borderColor: "primary.main",
                      },
                    }}
                    onClick={() => imageInputRef.current?.click()}
                  >
                    {imageSrc ? (
                      <img 
                        src={GET_IMAGE(imageSrc)}
                        alt="Submission"
                        style={{ 
                          maxWidth: "100%", 
                          maxHeight: "100%", 
                          objectFit: "contain" 
                        }}
                      />
                    ) : (
                      <SoftTypography
                        variant="button"
                        sx={{
                          color: "#E9ECEF",
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        Click to upload image
                      </SoftTypography>
                    )}
                  </SoftBox>
                  <input
                    ref={imageInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                </SoftBox>
              )}
              {openPreview.template.submissionType === "Text Submission" && (
                <SoftBox display="flex" flexDirection="column" gap="0.75rem" justifyContent="center">
                  <SoftInput
                    multiline
                    rows={4}
                    value={submissionText}
                    onChange={(e) => setSubmissionText(e.target.value)}
                    placeholder="Enter your text submission"
                    variant="outlined"
                    sx={{
                      input: { color: 'white' },
                      textarea: { color: 'white' }
                    }}
                  />
                </SoftBox>
              )}
              {openPreview.template.submissionType === "Email Submission" && (
                <SoftBox display="flex" flexDirection="column" gap="0.75rem" justifyContent="center">
                  <SoftInput
                    type="url"
                    value={submissionUrl}
                    onChange={(e) => setSubmissionUrl(e.target.value)}
                    placeholder="Enter your link submission"
                    variant="outlined"
                    sx={{
                      input: { color: 'white' },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'white' },
                      }
                    }}
                  />
                </SoftBox>
              )}
              <SoftBox display="flex" justifyContent="center" mt={2}>
                <SoftButton
                  variant="gradient"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={
                    isSubmitting || 
                    (openPreview.template.submissionType === "Image Submission" && !imageSrc) ||
                    (openPreview.template.submissionType === "Text Submission" && !submissionText) ||
                    (openPreview.template.submissionType === "Email Submission" && !submissionUrl)
                  }
                  sx={{
                    background: "#8A69FF",
                    color: "#FFF",
                    "&:hover": {
                      background: "#7559DB",
                    }
                  }}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </SoftButton>
              </SoftBox>
            </SoftBox>
          )}
        </div>
      </Modal>
      <Modal
        open={confirmModal}
        onClose={() => setConfirmModal(false)}
        aria-labelledby="confirm-modal"
      >
        <div style={{
          ...modalStyle,
          height: 'auto',
          width: 400,
          padding: '20px',
        }}>
          <SoftTypography
            component="p"
            sx={{
              color: "#FFF",
              fontSize: "14px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Type "confirm" to remove this badge
          </SoftTypography>
          <SoftInput
            fullWidth
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            variant="outlined"
            sx={{
              input: { color: 'white' },
              '& .MuiOutlinedInput-root': {
                '& fieldset': { borderColor: 'white' },
              },
            }}
          />
          <SoftBox display="flex" justifyContent="flex-end" mt={3}>
            <SoftButton
              onClick={() => setConfirmModal(false)}
              variant="gradient"
              sx={{
                color: "#CED4DA",
                background: "rgba(255, 255, 255, 0.1)",
                marginRight: "10px",
              }}
            >
              Cancel
            </SoftButton>
            <SoftButton
              onClick={handleConfirmRemove}
              variant="gradient"
              sx={{
                color: "#344054",
                backgroundColor: "#F9FAFB",
              }}
              disabled={confirmText.toLowerCase() !== 'confirm'}
            >
              Confirm Remove
            </SoftButton>
          </SoftBox>
        </div>
      </Modal>
    </>
  );
};

export default BadgeRequirementPreview;
