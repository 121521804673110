import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { Grid, Modal, Backdrop, useMediaQuery } from "@mui/material";
import { Grid, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import {
  GET_PRICING,
  POST_TRIAL_SUBSCRIPTION,
  POST_CCLESS_TRIAL_SUBSCRIPTION,
} from "../../api/payment";

import {
  DELETE_TEMPUSER
} from "../../api/user";

import TrialSignupForm from "../../components/Trial/SignUpForm";
// import PaymentForm from "../../components/Stripe/PaymentForm";
import onboarding from "../../constants/onboarding";
import SoftBox from "../../components/SoftBox";
// import SoftTypography from "../../components/SoftTypography";

import GMGLogo from "../../img/GMB_Logo.svg";
// import GMGLogoWhite from "../../img/GMB_LogoWhite.svg";
import shieldIcon from "../../img/circle-check.png";
// import stepBg from "../../img/step_bg_spot.png";
import stepCard from "../../img/step-card-2.png";
// import stepCard1 from "../../img/step-card-1.png";
// import stepDashboard from "../../img/step-dashboard.png";
// import stepAvatar from "../../img/step-avatar.png";
// import stepQouteImg from "../../img/step_quote.png";

const useStyles = makeStyles((theme) => ({
  leftPane: {
    height: "100vh",
  },
  registerGridContainer: {
    height: "100vh !important",
  },
  rightPane: {
    // backgroundColor: "#04081A !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    // backgroundImage: `url(${stepBg}) !important`,
    backgroundSize: "cover !important",
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
  },
  logoBox: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "20px",
    left: "50px",
  },
  stepContent: {
    padding: "40px",
    width: "70%",
    border: "1px solid #f9fafb47",
    background: "linear-gradient(273.63deg, rgba(121, 40, 202, 0.1) 0%, rgba(255, 0, 128, 0.1) 100%)!important",
    // boxShadow: "0 0 50px rgba(0, 0, 0, 0.5)",
    position: "relative",
    minHeight: "60%",
  },
  lastContent: {
    width: "70%",
    border: "1px solid #f9fafb47",
    backgroundColor: "#0000007d",
    boxShadow: "0 0 50px rgba(0, 0, 0, 0.5)",
    borderRadius: "8px",
    position: "relative",
    minHeight: "60%",
    overflow: "hidden",
  },
  stepTitle: {
    color: "#000000",
    textAlign: "center",
    fontSize: "32px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
    marginBottom: "15px",
  },
  priceTitle: {
    color: "#475467",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
    marginBottom: ".5rem"
  },
  price: {
    background: "linear-gradient(273.63deg, #7928CA 0%, #FF0080 100%)",
    webkitBackgroundClip: "text",
    webkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
    fontSize: "48px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
  },
  perSet: {
    color: "#1D2939",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
    textAlign: "right"
  },
  perSetOld: {
    color: "#667085",
    fontSize: "13px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
    textDecoration: "line-through", 
    textDecorationColor: "#667085",
    textAlign: "right"
  },
  oldPrice: {
    color: "#1D2939",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
    textDecoration: "line-through", 
    textDecorationColor: "#667085",
    marginLeft: ".5rem",
    marginTop: "1rem"
  },
  priceSub: {
    color: "#667085",
    fontSize: "15px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
  },
  listBox:{
    border: "1px dashed #344054",
    borderRadius: "6.77372px",
    padding: "2rem",
    marginTop: "2rem"
  },
  listTitle2: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
    marginBottom: "1rem",
  },
  listTitle: {
    color: "#000000",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
    marginBottom: "1rem",
  },
  listSecondTitle: {
    color: "#000000",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
    marginTop: "4rem",
    marginBottom: ".5rem"
  },
  priceBox: {
    border: "1px solid #000000",
    borderRadius: "8px",
    marginTop: "1rem",
    marginBottom: "1rem",
    padding: "1rem"
  },
  priceBox2: {
    marginTop: "1rem",
    marginBottom: "1rem",
    alignitems: "center",
    textAlign: "center",
  },
  priceContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignitems: "center",
  },
  priceContainer2: {
    width: "50%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignitems: "center",
  },
  listText: {
    color: "#344054",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: 'DM Sans',
  },
  listText2: {
    color: "#344054",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: 'DM Sans',
  },
  stepCardTitle: {
    color: "#000000",
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 600,
    fontFamily: 'DM Sans',
    marginBottom: "1rem",
  },
  stepCardBox: {
    textAlign: "center",
    marginTop: "2rem"
  },
  stepCard: {
    margin: "0 auto",
  },
  stepText: {
    color: "#344054",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: 'DM Sans',
  },
  stepListItem: {
    color: "#000000",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "15px",
    fontFamily: 'DM Sans',
    display: 'flex',
    alignItems: 'center',
  },
  shieldIcon: {
    marginRight: "10px",
  },
  stepCard1: {
    position: "absolute",
    zIndex: 1,
    bottom: "-40px",
  },
  planCard: {
    backgroundColor: "#483F4F",
    border: "1px solid #f2f4f77a",
    padding: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
  },
  planTitle: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: 500,
  },
  planNote: {
    color: "#D0D5DD",
    fontSize: "12px",
    fontWeight: 500,
    display: "block",
    marginTop: "15px",
  },
  planPrice: {
    color: "#fff",
    fontSize: "20px",
    fontWeight: 500,
  },

  stepDescription: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "20px",
    textAlign: "center",
  },
  stepQoute: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
    marginBottom: "20px",
  },
  stepQouteImg: {
    margin: "0 auto 10px",
  },
  stepAvatar: {
    margin: "0 auto 10px",
  },
  author: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "10px",
    textAlign: "center",
  },
  authorDesignation: {
    color: "#fff",
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: "20px",
    textAlign: "center",
  },
  stepDashboard: {
    boxShadow: "0px 0px 0px 10px #f2f4f738",
    marginInline: "-20px",
  },
}));

const REQIURE_CC_ON_SIGNUP = true;

const FreeTrial = () => {
  const [stripe, setStripe] = useState(null);
  const [order, setOrder] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [trialDays, setTrialDays] = useState(0);
  const [coupon, setCoupon] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const tier = params.get("tier");
  const [showInputs, setShowInputs] = useState(true);
  const [step, setStep] = useState(0);
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  const [userEmail, setUserEmail] = useState(null);

  const classes = useStyles();
  const mdBp = useMediaQuery((theme) => theme.breakpoints.up("1200"));

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (userEmail) {
        const body = {
          email: userEmail,
        };
        DELETE_TEMPUSER(body);
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [userEmail]);

  const reasonsToJoin = [
    "Early access to everything Game My Biz has to offer, for half the price",
    "Top-tier support from our team, including check-in calls and chat/email options",
    "Help shape Game My Biz and the future of gamification",  
  ];
  const reasonsToJoinStepTwo = [
    "Industry-specific metrics and details",
    "Seamless integrations with the tools/CRMs you already use",
    "Branding, including your company logo and colors",
  ];
  const foundersPlan = [
    "Lifetime 50% Off Starter Pricing",
    "Premium Support and Technical Help",
    "Priority access to new features and products",
  ];

  useEffect(() => {
    GET_PRICING()
      .then(({ data }) => {
        const currentTier = tier ?? "1";
        const matchingTier = data.find(
          (z) => z.interval === "month" && z.level === currentTier,
        );
        setOrder(matchingTier);
      })
      .catch((err) => console.log("Stripe Get Prices Error", err));
  }, [tier]);

  useEffect(() => {
    loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY).then((stripeRes) => {
      setStripe(stripeRes);
    });
  }, []);

  const handleResult = (message, type) => {
    if (type === "error") {
      setError(message);
      setClientSecret(null);
    } else {
      navigate("/thank-you");
    }
  };

  const register = (formData) => {
    setProcessing(true);
    const { code, ...userData } = formData;
    const user = { ...userData, onboardingStatus: onboarding.TRIAL_SIGNUP };
    setUserEmail(formData.email);

    if (REQIURE_CC_ON_SIGNUP) {
      POST_TRIAL_SUBSCRIPTION({ priceId: order.id, userData: user, code })
        .then(({ data }) => {
          setClientSecret(data.clientSecret);
          setTrialDays(data.trialDays ?? 0);
          setCoupon(data.coupon);
          setStep(2);
        })
        .catch((err) => {
          setProcessing(false);
          setError(
            err.response?.data?.message ?? err.message ?? JSON.stringify(err),
          );
        });
    } else {
      console.log("=== Else");
      POST_CCLESS_TRIAL_SUBSCRIPTION(user)
        .then(({ data }) => {
          setClientSecret(data.clientSecret);
          setTrialDays(data.trialDays ?? 0);
          setCoupon(data.coupon);
          setStep(2);
          handleResult({ type: "success" });
        })
        .catch((err) => {
          setProcessing(false);
          setError(
            err.response?.data?.message ?? err.message ?? JSON.stringify(err),
          );
        });
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const modalstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Grid
      container
      className={`w-full flex justify-center ${classes.registerGridContainer}`}
    >
      <Grid
        item
        md={12}
        lg={6}
        className="flex justify-center items-center p-3 h-lvh relative"
        style={{ flexDirection: "column" }}
      >
        <SoftBox className={classes.logoBox}>
          <img src={GMGLogo} alt="Game My Biz" />
          <span
            style={{
              color: "#051923",
              fontSize: "20px",
              fontWeight: "500",
              marginLeft: "10px",
            }}
          >
            {" "}
            Game My Biz
          </span>
        </SoftBox>
        <TrialSignupForm
          toggleInputs={() => setStep(1)}
          showInputs={showInputs}
          submitUser={register}
          processing={processing}
          error={error}
          stripe={stripe}
          clientSecret={clientSecret}
          order={order}
          handleResult={handleResult}
          trialDays={trialDays}
          coupon={coupon}
          step={step}
          setStep={setStep}
        />
      </Grid>
      {mdBp && (
        <Grid item md={12} lg={6}>
          <SoftBox>
            {step === 0 && (
              <SoftBox className={classes.rightPane}>
                <SoftBox className={classes.stepContent}>
                  <h3 className={classes.stepTitle}>Welcome to the <br/>Game My Biz Founders Program</h3>
                  <p className={classes.stepText}>As a beta user, you receive our Members Only pricing,<br/> and get all the perks of a founder for just</p>
                  <SoftBox className={classes.priceBox2}>
                    <SoftBox className={classes.priceContainer2}>
                      <SoftBox>
                        <h4 className={classes.price}>$80/<span className={classes.priceSub}>Month</span></h4>
                      </SoftBox>
                      <SoftBox>
                        <h4 className={classes.oldPrice}>$150/Month</h4>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox className={classes.listBox}>
                    <h4 className={classes.listTitle}>Founding Member Perks</h4>
                    <ul>
                      {reasonsToJoin.map((reason) => (
                        <li className={classes.stepListItem} key={reason}>
                          <img
                            className={classes.shieldIcon}
                            src={shieldIcon}
                            alt="shieldIcon"
                          />
                          {reason}
                        </li>
                      ))}
                    </ul>
                  </SoftBox>
                  <SoftBox className={classes.stepCardBox}>
                    <h4 className={classes.stepCardTitle}>Track Players Performances </h4>
                    <img
                      className={classes.stepCard}
                      src={stepCard}
                      alt="step card"
                    />
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            )}
            {step === 1 && (
              <SoftBox className={classes.rightPane}>
                <SoftBox className={classes.stepContent}>
                  <h3 className={classes.stepTitle}>Creating Your Custom Company Dashboard</h3>
                  <p className={classes.stepText}>Help us tailor your beta experience by providing your company details.</p>
                  <SoftBox className={classes.listBox}>
                    <h4 className={classes.listTitle}>What’s included in my dashboard?</h4>
                    <ul>
                      {reasonsToJoinStepTwo.map((reason) => (
                        <li className={classes.stepListItem} key={reason}>
                          <img
                            className={classes.shieldIcon}
                            src={shieldIcon}
                            alt="shieldIcon"
                          />
                          {reason}
                        </li>
                      ))}
                    </ul>
                    <h4 className={classes.listSecondTitle}>Will my dashboard be easily customizable?</h4>
                    <p className={classes.listText}>Yes, You have all the flexibility of customizing your dashboard layouts to fit your companies desired branding.</p>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            )}
            {step === 2 && (
              <SoftBox className={classes.rightPane}>
                <SoftBox className={classes.stepContent}>
                  <h3 className={classes.stepTitle}>
                    Secure Your Members-Only Pricing 
                  </h3>
                  <p className={classes.stepText}>As a beta tester and founder, you’ll have a lifetime 50% discount on our Game My Biz starter plan. This pricing is only available for founders, so don’t miss your opportunity to save big for life!</p>
                  <SoftBox className={classes.priceBox}>
                    <h3 className={classes.priceTitle}>Special Founders Plan</h3>
                    <SoftBox className={classes.priceContainer}>
                      <SoftBox className={classes.priceContainer}>
                        <h4 className={classes.price}>$80/<span className={classes.priceSub}>Month</span></h4>
                        <h4 className={classes.oldPrice}>$150/Month</h4>
                      </SoftBox>
                      <SoftBox>
                        <h4 className={classes.perSet}>$5/player</h4>
                        <h5 className={classes.perSetOld}> $10/player</h5>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox sx={{marginTop: "2rem"}}>
                    <h4 className={classes.listTitle2}>Your Founders Plan includes:</h4>
                    <ul>
                      {foundersPlan.map((reason) => (
                        <li className={classes.stepListItem} key={reason}>
                          <img
                            className={classes.shieldIcon}
                            src={shieldIcon}
                            alt="shieldIcon"
                          />
                          {reason}
                        </li>
                      ))}
                    </ul>
                  </SoftBox>
                  {/* <SoftBox className={classes.listBox}>
                    <h4 className={classes.listTitle2}>Will I See this charge right away?</h4>
                    <p className={classes.listText2}>No, you wont be charged until after your free trial ends on July 28th, 2024. after your free trial you will charged $80 until you change or cancel subscription.</p>
                  </SoftBox> */}
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
      )}
    </Grid>
  );
};

export default FreeTrial;

/*
return (
    <Grid
      container
      spacing={2}
      className="w-full flex justify-center mt-5 pt-5"
    >
      {showInputs ? (
        <Grid
          item
          xs={12}
          sm={12}
          className="flex justify-center items-center mb-5"
        >
          <img src={GMGLogo} alt="Game My Biz" />
          <span
            style={{
              color: "#051923",
              fontSize: "20px",
              fontWeight: "500",
              marginLeft: "10px",
            }}
          >
            {" "}
            Game My Biz
          </span>
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          className="flex items-center pb-3 mb-5"
          style={{
            background: "linear-gradient(90deg, #F20487 0%, #9021BE 100%)",
            width: "100%",
          }}
        >
          <img
            src={GMGLogoWhite}
            alt="Game My Biz White"
            style={{ paddingLeft: "15%" }}
          />
          <span
            style={{
              color: "#FFFFFF",
              fontSize: "20px",
              fontWeight: "500",
              marginLeft: "10px",
            }}
          >
            {" "}
            Game My Biz
          </span>
        </Grid>
      )}
      <Grid item xs={12} sm={4}>
        {!clientSecret ? (
          <TrialSignupForm
            toggleInputs={() => setShowInputs(!showInputs)}
            showInputs={showInputs}
            submitUser={register}
            processing={processing}
            error={error}
          />
        ) : (
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              BackdropComponent={Backdrop}
              BackdropProps={{
                onClick: handleBackdropClick,
              }}
            >
              <SoftBox sx={modalstyle}>
                <Elements stripe={stripe} options={{ clientSecret }}>
                  <PaymentForm
                    newUser={true}
                    subscription={order}
                    handleResult={handleResult}
                    trialDays={trialDays}
                    coupon={coupon}
                  />
                </Elements>
              </SoftBox>
            </Modal>
          </div>
        )}
      </Grid>
      {!clientSecret && !showInputs && (
        <Grid
          item
          xs={12}
          sm={5}
          style={{
            paddingLeft: "2.5rem",
            paddingRight: "2.5rem",
            paddingBottom: "1.5rem",
          }}
        >
          <h1
            className="mb-4"
            style={{ color: "#6C757D", fontSize: "24px", fontWeight: "500" }}
          >
            Ready to conquer your biggest goals?
          </h1>
          <h2
            className="mb-5"
            style={{ color: "#000000", fontSize: "32px", fontWeight: "500" }}
          >
            Create a Game out of your metrics to ignite new business
            opportunities!
          </h2>
          <Grid container spacing={2} className="w-full">
            {order?.features.map((value) => (
              <Grid item xs={6} className="flex mb-5">
                <img src={shield} alt="shield" style={{ width: "24px" }} />
                <span
                  style={{
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: "500",
                    marginLeft: "10px",
                  }}
                >
                  {" "}
                  {value}
                </span>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
*/
